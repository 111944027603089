import React, { useEffect, useState } from 'react';
import { Cell, Pie, PieChart } from 'recharts';
import styled from 'styled-components';
import query from '../../../hooks/RQuery/utils';

const StatisticsStyle = {
  Wrap: styled.div`
    position: absolute;
    left: 0;
    top: calc(100% + 6px);
    z-index: 10;
    display: flex;
    background: #fff;
    border-radius: 4px;
    overflow: hidden;
    box-shadow: 0 4px 10px 0 rgba(0, 0, 0, 0.25);
    .recharts-wrapper {
      margin: 0 auto;
    }
  `,
  Item: styled.div`
    position: relative;
    width: 240px;
    height: 198px;
    &:not(:last-child) {
      border-right: 1px solid #e9e7e7;
    }
    &:first-child {
      border-radius: 4px 0 0 4px;
    }
    &:last-child {
      border-radius: 0 4px 4px 0;
    }
    p {
      display: block;
      margin: 20px auto 2px;
      text-align: center;
      font-size: 14px;
      color: #001a21;
      font-weight: 600;
      span {
        color: #7e8888;
        font-size: 12px;
      }
    }
    strong {
      position: absolute;
      left: 50%;
      top: 95px;
      transform: translateX(-50%);
      color: #001a21;
      font-size: 26px;
      font-weight: 700;
    }
  `,
  DataWrap: styled.div`
    display: flex;
    padding: 0 20px;
    margin-top: 12px;
  `,
  DataCont: styled.div`
    flex: 1;
    display: flex;
    &:first-child {
      margin-right: 16px;
    }
    p {
      margin: 0;
      text-align: left;
      line-height: 15px;
      font-weight: 400;
      font-size: 12px;
      color: #7e8888;
      b {
        display: block;
        color: #001a21;
        font-size: 14px;
        font-weight: 600;
        margin-top: 4px;
      }
    }
    &:before {
      content: '';
      width: 24px;
      height: 4px;
      border-radius: 2px;
      margin-right: 12px;
      background: #e9ecf1;
    }
    &.fill {
      &:before {
        background: #218ee6;
      }
    }
    &.remain {
      &:before {
        background: #7151f2;
      }
    }
    &.inspect {
      &:before {
        background: #ff7600;
      }
    }
  `,
};

const Statistics = ({ round }) => {
  const thisRound = round.filter(({ active }) => active)[0]!;
  const chartData = query.survey.useGetChart(thisRound.surveyId);

  const [fillCount, setFillCount] = useState([
    { name: 'fill', count: 0, percent: 0 },
    { name: 'total', count: 0, percent: 0 },
  ]);
  const [remainCount, setRemainCount] = useState([
    { name: 'remain', count: 0, percent: 0 },
    { name: 'total', count: 0, percent: 0 },
  ]);
  const [inspectCount, setInspectCount] = useState([
    { name: 'inspect', count: 0, percent: 0 },
    { name: 'total', count: 0, percent: 0 },
  ]);

  useEffect(() => {
    if (chartData.data) {
      setFillCount([
        {
          name: 'fill',
          count: chartData.data.fillRateCount.count,
          percent: chartData.data.fillRateCount.percent,
        },
        {
          name: 'total',
          count: chartData.data.totalCount - chartData.data.fillRateCount.count,
          percent: 0,
        },
      ]);
      setRemainCount([
        {
          name: 'remain',
          count: chartData.data.remainRateCount.count,
          percent: chartData.data.remainRateCount.percent,
        },
        {
          name: 'total',
          count: chartData.data.totalCount - chartData.data.remainRateCount.count,
          percent: 0,
        },
      ]);
      setInspectCount([
        {
          name: 'inspect',
          count: chartData.data.inspectCount.count,
          percent: chartData.data.inspectCount.percent,
        },
        {
          name: 'total',
          count: chartData.data.totalCount - chartData.data.inspectCount.count,
          percent: 0,
        },
      ]);

      console.log(chartData.data);
    }
  }, [chartData.data]);

  return (
    <StatisticsStyle.Wrap>
      <StatisticsStyle.Item>
        <p>
          점유율 <span>(%)</span>
        </p>
        <strong>{fillCount[0].percent}</strong>
        <PieChart width={160} height={80}>
          <defs>
            <linearGradient id="gradient1" x1="0" y1="0" x2="1" y2="0">
              <stop offset="0%" stopColor="#218EE6" stopOpacity={1} />
              <stop offset="100%" stopColor="#6DB2EA" stopOpacity={0.5} />
            </linearGradient>
          </defs>
          <Pie data={fillCount} cx={75} cy={75} innerRadius={40} outerRadius={70} startAngle={180} endAngle={0} fill="#8884d8" dataKey="count">
            {fillCount.map((entry, index) => (
              <Cell key={`cell-${index}`} fill={index === 0 ? `url(#gradient1)` : '#eee'} />
            ))}
          </Pie>
        </PieChart>
        <StatisticsStyle.DataWrap>
          <StatisticsStyle.DataCont className={'fill'}>
            <p>
              점유율입력
              <br />
              필지수
              <b>{fillCount[0].count}</b>
            </p>
          </StatisticsStyle.DataCont>
          <StatisticsStyle.DataCont>
            <p>
              전체
              <br />
              필지수
              <b>{chartData.isSuccess && chartData.data?.totalCount}</b>
            </p>
          </StatisticsStyle.DataCont>
        </StatisticsStyle.DataWrap>
      </StatisticsStyle.Item>
      <StatisticsStyle.Item>
        <p>
          잔여량 <span>(%)</span>
        </p>
        <strong>{remainCount[0].percent}</strong>
        <PieChart width={160} height={80}>
          <defs>
            <linearGradient id="gradient2" x1="0" y1="0" x2="1" y2="0">
              <stop offset="0%" stopColor="#7151F2" stopOpacity={1} />
              <stop offset="100%" stopColor="#A291E4" stopOpacity={0.5} />
            </linearGradient>
          </defs>
          <Pie data={remainCount} cx={75} cy={75} innerRadius={40} outerRadius={70} startAngle={180} endAngle={0} fill="#8884d8" dataKey="count">
            {remainCount.map((entry, index) => (
              <Cell key={`cell-${index}`} fill={index === 0 ? `url(#gradient2)` : '#eee'} />
            ))}
          </Pie>
        </PieChart>

        <StatisticsStyle.DataWrap>
          <StatisticsStyle.DataCont className={'remain'}>
            <p>
              잔여량입력
              <br />
              필지수
              <b>{remainCount[0].count}</b>
            </p>
          </StatisticsStyle.DataCont>
          <StatisticsStyle.DataCont>
            <p>
              전체
              <br />
              필지수
              <b>{chartData.isSuccess && chartData.data?.totalCount}</b>
            </p>
          </StatisticsStyle.DataCont>
        </StatisticsStyle.DataWrap>
      </StatisticsStyle.Item>
      <StatisticsStyle.Item>
        <p>
          검수완료 <span>(%)</span>
        </p>
        <strong>{inspectCount[0].percent}</strong>
        <PieChart width={160} height={80}>
          <defs>
            <linearGradient id="gradient3" x1="0" y1="0" x2="1" y2="0">
              <stop offset="0%" stopColor="#FF7600" stopOpacity={1} />
              <stop offset="100%" stopColor="#FFC491" stopOpacity={0.5} />
            </linearGradient>
          </defs>
          <Pie data={inspectCount} cx={75} cy={75} innerRadius={40} outerRadius={70} startAngle={180} endAngle={0} fill="#8884d8" dataKey="count">
            {inspectCount.map((entry, index) => (
              <Cell key={`cell-${index}`} fill={index === 0 ? `url(#gradient3)` : '#eee'} />
            ))}
          </Pie>
        </PieChart>

        <StatisticsStyle.DataWrap>
          <StatisticsStyle.DataCont className={'inspect'}>
            <p>
              검수완료
              <br />
              필지수
              <b>{inspectCount[0].count}</b>
            </p>
          </StatisticsStyle.DataCont>
          <StatisticsStyle.DataCont>
            <p>
              전체
              <br />
              필지수
              <b>{chartData.isSuccess && chartData.data?.totalCount}</b>
            </p>
          </StatisticsStyle.DataCont>
        </StatisticsStyle.DataWrap>
      </StatisticsStyle.Item>
    </StatisticsStyle.Wrap>
  );
};
export default Statistics;
