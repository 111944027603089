import { useState, useEffect } from 'react';
import imageDefault from 'assets/images/image-default.png';
import imageDefault1200 from 'assets/images/image-default-1200.png';
import Button from 'components/atoms/Button';
import IcoReset from 'assets/images/cropInfoBox/ico-reset-white.svg';

/* 
1. 일단 <img src={src} /> 그리기 
2. 에러 발생시 retry 버튼 보이기
3. 버튼 클릭시 src를 비웠다가 다시 <img src={src} /> 요청 (캐시)
4. 또 에러 발생시 retry 버튼 보임
*/
const ImageWithRetry = ({ src, alt, noText = false }) => {
  const loadingImage = noText ? imageDefault : imageDefault1200; // noText: 사진 썸네일인 경우
  const [imageSrc, setImageSrc] = useState(loadingImage); // 현재 이미지 URL
  const [showRetry, setShowRetry] = useState(false); // 버튼 표시 여부

  // github 저장소 - 이미지 테스트 (Private: 404 에러 발생 / Public: 정상)
  const filePath = `501102562100001_제주특별자치도 제주시 구좌읍 동복리 _20221110124827_밭.JPG`;
  const githubSrc = `https://raw.githubusercontent.com/sonak22/image-box/main/${filePath}`;
  const imgurSrc = `https://i.imgur.com/MFBzNhy.jpeg`;

  // img 태그 주소 셋팅
  useEffect(() => {
    setImageSrc(src);
  }, [src]);

  // 이미지 로드 실패 시 호출되는 함수
  const handleError = async (evt) => {
    //    evt.currentTarget.src = loadingImage;
    try {
      const response = await fetch(src, { method: 'HEAD' }); // 이미지 status 체크
      console.log('handleError response:', response);

      // 이미지 로드 실패했는데 -> fetch는 정상 인 경우가 있나? 200으로 내려온다면..?
      setShowRetry(true);
      setImageSrc(loadingImage);

      // 서버와 약속) url이 존재하고, 403일때는 이미지가 아직 생성중인 것
      if (response.status === 403 || response.status === 404) {
        setShowRetry(true); // 새로고침 버튼 표시
        setImageSrc(loadingImage); // 로딩중 이미지
      }
    } catch (error) {
      // 그 외 이미지 에러 발생시 (cors 등 ?)
      console.error('Error fetching image:', error);
      setShowRetry(true); // 새로고침 버튼 표시
      setImageSrc(loadingImage); // 로딩중 이미지 > TODO: 다른 대체 이미지?
    }
  };

  // 버튼 클릭 시 이미지 다시 로드
  const handleRetry = (e) => {
    e.stopPropagation(); // 버블링 막기(onClickCard 이벤트 막기)
    setShowRetry(false); // 버튼 숨기기

    // 이미지 태그 캐시 없애기
    const imgQueryString = true ? '' : `?v=${new Date().getTime()}`;

    setImageSrc(loadingImage); // 로딩중 이미지로 만들었다가,
    setTimeout(() => setImageSrc(src + imgQueryString), 0); // 캐시없는 이미지 URL 재설정 -> 또 onError발생시 다시 handleError 호출됨
  };

  return (
    <>
      {/* 이미지 태그 */}
      <img src={imageSrc} alt={alt} onError={handleError} />
      {/* 버튼 */}
      {showRetry && (
        <div style={{ position: 'absolute', top: '50%', left: '50%', transform: 'translate(-50%, -50%)', zIndex: '9' }}>
          {noText ? (
            /* 새로고침 버튼만 */
            <Button $buttonType="large" icon={IcoReset} $iconSpacing={0} $icoPosition="left" $iconSize={20} radius={24} text="" onClick={(e) => handleRetry(e)} />
          ) : (
            /* 텍스트 포함 */
            <Button $buttonType="large" icon={IcoReset} $iconSpacing={6} $icoPosition="left" $iconSize={20} radius={24} text="데이터 새로고침" onClick={(e) => handleRetry(e)} />
          )}
        </div>
      )}
    </>
  );
};

export default ImageWithRetry;
