import React, { useState, useEffect, useRef } from 'react';
import PopupTemplate from './PopupTemplate';
import styled from 'styled-components';
import Button from '../components/atoms/Button';
import Select from '../components/atoms/Select';
import NoData from '../components/atoms/NoData';
import IcoLot from '../assets/images/noData/ico-lot.svg';
import HelpMessage from '../components/atoms/HelpMessage';
import IcoHelp from '../assets/images/etc/ico-help-message.svg';
import ScrollTable from '../components/atoms/ScrollTable';
import Checkbox from '../components/atoms/Checkbox';
import IcoFileUpload from '../assets/images/button/ico-file-upload.svg';
import IcoFileUploadDisabled from '../assets/images/button/ico-file-upload-disabled.svg';
import { postBjdCd } from 'service/farm';
import NoneScrollTable from 'components/atoms/NoneScrollTable';
import { useRecoilState, useSetRecoilState } from 'recoil';
import { formState } from 'store/Atoms';

const Content = {
  Inner: styled.div`
    padding: 24px 0;
  `,
  Row: styled.div`
    display: flex;
    align-items: center;
    > * {
      &:not(:last-child) {
        margin-right: 6px;
      }
    }
  `,
};

// 필지 업로드 팝업
const PopupAddLot = ({ onClose }) => {
  const shpFileInputRef: any = useRef(null);
  const [form, setForm] = useRecoilState(formState);
  //필지정보가 이미 올라와있는지 체크
  //   const [submitType, setSubmitType] = useState<null | 'address' | 'shp'>(form?.submitType);
  //라디오 옵션
  const [option, setOption] = useState([
    { type: 'address', checked: true },
    { type: 'shp', checked: false },
  ]);

  // 필지 정보 유무 체크
  const [addrForm, setAddrForm] = useState({ SIDO: { text: '', cd: '' }, SIGNGU: { text: '', cd: '' }, EMD: { text: '', cd: '' } }); // select 선택된 bjdcd
  const [shpFile, setShpFile] = useState<any[]>([]); // shp 파일 유무

  // 팜맵 API로 법정동 주소 리스트 저장
  const [sidoOption, setSidoOption] = useState<any[]>([]); // 시도 리스트
  const [signguOption, setSignguOption] = useState<any[]>([]); // 시군구 리스트
  const [emdOption, setEmdOption] = useState<any[]>([]); // 읍면동 리스트
  const [liOption, setLiOption] = useState<any[]>([]); // 리 리스트 (없을 수 있음)

  useEffect(() => {
    // 이전 선택값: shp 타입일때 option값 체크 변경
    if (form?.submitType === 'shp') {
      setOption((prev) => [...prev].map((ele) => ({ ...ele, checked: ele.type === 'shp' })));
    }
  }, [form?.submitType]);

  // 팜맵 API로 법정동 주소 조회하기
  // 시도 리스트 조회 (최초 한번)
  useEffect(() => {
    const fetchBjdCode = async () => {
      const sidoList = await postBjdCd('SIDO');
      if (sidoList?.length > 0) {
        setSidoOption(sidoList.map((ele) => ({ text: ele.name, value: ele.bjcd, active: false })));
      }
    };
    fetchBjdCode();
  }, []);

  // 시도 선택되면 시군구 리스트 조회
  useEffect(() => {
    if (addrForm.SIDO) {
      const fetchBjdCode = async () => {
        const signguList = await postBjdCd('SIGNGU', addrForm.SIDO.cd);
        if (signguList?.length > 0) {
          setSignguOption(signguList.map((ele) => ({ text: ele.name, value: ele.bjcd, active: false })));
        }
      };
      fetchBjdCode();
    }
  }, [addrForm.SIDO]);

  // 시군구 선택되면 읍면동 리스트 조회
  useEffect(() => {
    if (addrForm.SIGNGU) {
      const fetchBjdCode = async () => {
        const emdList = await postBjdCd('EMD', addrForm.SIGNGU.cd);
        if (emdList?.length > 0) {
          setEmdOption(emdList.map((ele) => ({ text: ele.name, value: ele.bjcd, active: false })));
        }
      };
      fetchBjdCode();
    }
  }, [addrForm.SIGNGU]);

  // 읍면동 선택되면 '리' 리스트 조회
  useEffect(() => {
    if (addrForm.EMD) {
      const fetchBjdCode = async () => {
        const liList = await postBjdCd('LI', addrForm.EMD.cd);
        if (liList?.length > 0) {
          setLiOption([{ text: '전체', value: 'allSelect', active: false }, ...liList.map((ele) => ({ text: ele.name, value: ele.bjcd, active: false }))]);
        } else {
          setLiOption([]);
        }
      };
      fetchBjdCode();
    }
  }, [addrForm.EMD]);

  //주소 select onChange
  const onChangeAddr = (e, name) => {
    const { ariaValueText } = e.target;
    const labelText = e.target.getAttribute('aria-label');

    setAddrForm((prev) => ({ ...prev, [name]: { cd: ariaValueText, text: labelText?.trim() } })); // SIDO: '', SIGNGU: '', EMD: ''

    // active 값
    if (name === 'SIDO') {
      setSidoOption((prev) => prev.map((ele) => ({ ...ele, active: ele.value === ariaValueText })));
    }
    if (name === 'SIGNGU') {
      setSignguOption((prev) => prev.map((ele) => ({ ...ele, active: ele.value === ariaValueText })));
    }
    if (name === 'EMD') {
      setEmdOption((prev) => prev.map((ele) => ({ ...ele, active: ele.value === ariaValueText })));
    }
    // '리'가 있는 경우
    if (name === 'LI') {
      setLiOption((prev) => prev.map((ele) => ({ ...ele, active: ele.value === ariaValueText })));
    }
  };

  // shp 파일 받는 경우
  const onChangeFileInput = (e: any) => {
    const files: File[] = Array.from(e.target.files);

    let fileObj = {};
    // 기존 파일 데이터있으면 fileObj에 추가
    if (form?.shpFile?.length > 0) {
      form?.shpFile?.map((ele) => (fileObj[ele.name] = ele));
    }

    files.map((file: File) => {
      let isValid = false;
      const splitArr = file.name?.split('.');
      const name = splitArr?.[0];
      const type = splitArr?.[1];
      // 없는 항목 추가
      if (!fileObj[name]) {
        fileObj[name] = { name, types: [type], files: [file], isValid };
      } else {
        // 기존 항목에 추가
        if (!fileObj[name].types.includes(type)) {
          console.log('해당 type 없음');
          fileObj[name] = { ...fileObj[name], types: [...fileObj[name].types, type], files: [...fileObj[name].files, file] };
        } else {
          console.log('type 이미 존재');
          // 1.기존 데이터에서 해당 type 파일을 필터링
          let preserve = fileObj[name].files.filter((file) => file.name?.split('.')[1] !== type);
          // 2.해당 type 파일을 새로 추가
          fileObj[name] = { ...fileObj[name], files: [...preserve, file] };
        }
      }
      // 필수 파일 확장자 체크 (isValid)
      let types = fileObj[name]?.types;
      if (types.includes('dbf') && types.includes('prj') && types.includes('shp') && types.includes('shx')) {
        fileObj[name] = { ...fileObj[name], isValid: true };
      }
    });

    let newArr = Object.keys(fileObj)?.map((key) => fileObj[key]);

    setShpFile(newArr);
  };

  //라디오 선택 (주소 or shp)
  const onChange = (e) => {
    const value = e.target.id;
    const newArr = [...option];
    newArr.map((i) => {
      i.checked = i.type === value;
    });
    setOption(newArr);
    //  setSubmitType(value); // 임시로 값 저장
    setForm((prev) => ({ ...prev, bjdCdList: [], address: [] })); // 라디오 버튼 바뀌면 리셋
  };

  // 필지 주소정보 처리
  function getLotData() {
    let fullAddr: string[] = [];
    let bjdCd: string[] = [];
    // 법정코드, 주소 만들기 ('리' 유무 조건)
    if (addrForm?.['LI']?.text) {
      if (addrForm?.['LI']?.text === '전체') {
        const liOptionAll = liOption?.filter(({ text }) => text !== '전체');
        liOptionAll.map((li) => {
          fullAddr.push(`${addrForm['SIDO'].text} ${addrForm['SIGNGU'].text} ${addrForm['EMD'].text} ${li.text}`);
          bjdCd.push(li.value);
        });
      } else {
        fullAddr.push(`${addrForm['SIDO'].text} ${addrForm['SIGNGU'].text} ${addrForm['EMD'].text} ${addrForm['LI'].text}`);
        bjdCd.push(addrForm['LI'].cd);
      }
    } else {
      fullAddr.push(`${addrForm['SIDO'].text} ${addrForm['SIGNGU'].text} ${addrForm['EMD'].text}`);
      bjdCd.push(addrForm['EMD'].cd + '00');
    }
    return { address: fullAddr, bjdCd };
  }

  // footer 등록하기 버튼
  // addrForm?.['LI'].text === 전체 , addrForm?.['LI'].cd === allSelect
  const onClickAdd = () => {
    const isAddress = (liOption?.length > 0 && addrForm?.['LI']?.cd) || (emdOption?.length > 0 && addrForm?.['EMD'].cd);
    const isShp = shpFile?.length > 0;
    const submitType = isAddress ? 'address' : isShp ? 'shp' : null;
    if (submitType) {
      if (submitType === 'address') {
        const { bjdCd, address } = getLotData();
        //중복체크
        const isDuplicate = bjdCd?.filter((cd) => form?.bjdCdList.includes(cd));
        if (isDuplicate?.length > 0) {
          alert('이미 등록된 주소가 포함되어 있습니다.');
          return;
        } else {
          setForm((prev) => ({ ...prev, submitType, bjdCdList: [...prev.bjdCdList, ...bjdCd], address: [...prev.address, ...address] })); // 부모전달
        }
      }
      if (submitType === 'shp') {
        setForm((prev) => ({ ...prev, submitType, shpFile: [...shpFile] })); // 부모전달
      }
    }
    onClose(); // 팝업 닫기
  };

  // 등록하기 버튼 활성화 유무 (주소 또는 shp 파일 유무 체크)
  const btnActive = liOption?.length > 0 ? addrForm?.['LI']?.cd : emdOption?.length > 0 ? addrForm?.['EMD']?.cd : shpFile?.length > 0;

  return (
    <>
      <input ref={shpFileInputRef} style={{ display: 'none' }} type="file" multiple accept=".shp, .dbf, .shx, .prj" onChange={onChangeFileInput} />
      <PopupTemplate
        wrapStyle={{ overflow: 'visible' }}
        title={'필지 업로드'}
        width={form?.submitType === 'shp' ? 756 : liOption?.length > 0 ? 936 : 756}
        isDimmed={true}
        onClose={onClose}
        footer={<Button width={'full'} text={'등록하기'} onClick={onClickAdd} disabled={!btnActive} />}
        content={
          <Content.Inner>
            {!!form?.submitType ? (
              <HelpMessage message={'필지 주소 업로드는 <b> 최초에 선택한 방식</b>으로만 필지 추가가 가능합니다.'} icon={IcoHelp} style={{ marginBottom: 20 }} />
            ) : (
              <HelpMessage message={'<b>[팜맵 주소]</b> 또는 <b>[필지 SHP 파일]</b> 업로드 방식으로 추가할지 여부를 선택하세요.<br/>이후에는 최초에 선택한 방식으로만 필지 추가가 가능합니다.'} icon={IcoHelp} style={{ marginBottom: 20 }} />
            )}

            {!!form?.submitType ? (
              <NoneScrollTable
                $hasBorder={true}
                caption={'필지 추가'}
                colgroup={[140, 'auto']}
                thead={<></>}
                tbody={
                  <>
                    <tr className={'noHover'}>
                      <th scope={'row'} style={{ borderBottom: '1px solid #e7eaee', paddingLeft: 24, color: '#7E8888', textAlign: 'left', fontSize: 16, fontWeight: 500 }}>
                        {form?.submitType === 'address' ? '주소' : '필지'}
                      </th>
                      <td style={{ borderLeft: '1px solid #e7eaee', paddingLeft: 16 }}>
                        {form?.submitType === 'address' ? (
                          <Content.Row>
                            <Select option={sidoOption} $borderColor={'#E5E5EC'} placeHolder={'시/도'} width={172} height={40} name="SIDO" onChange={onChangeAddr} />
                            <Select option={signguOption} $borderColor={'#E5E5EC'} placeHolder={'시/구/군'} width={172} height={40} name="SIGNGU" onChange={onChangeAddr} />
                            <Select option={emdOption} $borderColor={'#E5E5EC'} placeHolder={'동/면'} width={172} height={40} name="EMD" onChange={onChangeAddr} />
                            {/* 리 옵션 */}
                            {liOption?.length > 0 && <Select option={liOption} $borderColor={'#E5E5EC'} placeHolder={'리'} width={172} height={40} name="LI" onChange={onChangeAddr} />}
                          </Content.Row>
                        ) : (
                          <div style={{ display: 'flex', alignItems: 'center' }}>
                            <Button $buttonType={'medium'} text={'파일 선택'} icon={IcoFileUpload} $iconDisabled={IcoFileUploadDisabled} $iconSize={20} $iconSpacing={6} disabled={!option[1].checked} onClick={() => shpFileInputRef.current?.click()} />
                            {shpFile?.length > 0 ? (
                              <div style={{ marginLeft: '10px', color: '#00B074' }}>
                                {shpFile?.[0]?.name} {shpFile?.length > 1 ? `외 ${shpFile?.length - 1}` : ''}{' '}
                              </div>
                            ) : (
                              <div style={{ marginLeft: '10px', color: '#898989', fontSize: '14px' }}>필수 파일 확장자 (dbf, .shp, .shx, .prj )</div>
                            )}
                          </div>
                        )}
                      </td>
                    </tr>
                  </>
                }
              />
            ) : (
              <NoneScrollTable
                $hasBorder={true}
                caption={'필지 타입 선택'}
                colgroup={[140, 'auto']}
                thead={<></>}
                tbody={
                  <>
                    <tr className={'noHover'}>
                      <th scope={'row'} style={{ borderBottom: '1px solid #e7eaee', paddingLeft: 24 }}>
                        <Checkbox type={'radio'} text={'주소'} name={'selType'} checked={option[0].checked} id={'address'} onChange={onChange} />
                      </th>
                      <td style={{ borderLeft: '1px solid #e7eaee', paddingLeft: 16 }}>
                        <Content.Row>
                          <Select option={sidoOption} $borderColor={'#E5E5EC'} placeHolder={'시/도'} disabled={!option[0].checked} width={172} height={40} name="SIDO" onChange={onChangeAddr} />
                          <Select option={signguOption} $borderColor={'#E5E5EC'} placeHolder={'시/구/군'} disabled={!option[0].checked} width={172} height={40} name="SIGNGU" onChange={onChangeAddr} />
                          <Select option={emdOption} $borderColor={'#E5E5EC'} placeHolder={'동/면'} disabled={!option[0].checked} width={172} height={40} name="EMD" onChange={onChangeAddr} />
                          {/* 리 옵션 */}
                          {liOption?.length > 0 && <Select option={liOption} $borderColor={'#E5E5EC'} placeHolder={'리'} disabled={!option[0].checked} width={172} height={40} name="LI" onChange={onChangeAddr} />}
                        </Content.Row>
                      </td>
                    </tr>
                    <tr className={'noHover'}>
                      <th scope={'row'} style={{ paddingLeft: 24 }}>
                        <Checkbox type={'radio'} text={'필지SHP'} name={'selType'} checked={option[1].checked} id={'shp'} onChange={onChange} />
                      </th>
                      <td style={{ borderLeft: '1px solid #e7eaee', paddingLeft: 16, display: 'flex', alignItems: 'center' }}>
                        <Button $buttonType={'medium'} text={'파일 선택'} icon={IcoFileUpload} $iconDisabled={IcoFileUploadDisabled} $iconSize={20} $iconSpacing={6} disabled={!option[1].checked} onClick={() => shpFileInputRef.current?.click()} />
                        {shpFile?.length > 0 ? (
                          <div style={{ marginLeft: '10px', color: '#00B074' }}>
                            {shpFile?.[0]?.name} {shpFile?.length > 1 ? `외 ${shpFile?.length - 1}` : ''}{' '}
                          </div>
                        ) : (
                          <div style={{ marginLeft: '10px', color: '#898989', fontSize: '14px' }}>필수 파일 확장자 (dbf, .shp, .shx, .prj )</div>
                        )}
                      </td>
                    </tr>
                  </>
                }
              />
            )}
          </Content.Inner>
        }
      />
    </>
  );
};

export default PopupAddLot;
