import React from 'react';
import styled, { CSSProperties } from 'styled-components';

const LegendStyle = {
  Wrap: styled.div`
    position: absolute;
    bottom: 52px;
    left: 16px;
    z-index: 8;
    padding: 10px;
    background: rgba(0, 0, 0, 0.5);
    border-radius: 4px;
    display: flex;
    flex-direction: column;
  `,
  Item: styled.p`
    display: flex;
    align-items: center;
    font-size: 12px;
    color: #fff;
    font-weight: 500;
    &:not(:last-child) {
      margin-bottom: 8px;
    }
    &.step0 {
      &:before {
        background: #fdd835;
      }
    }
    &.step1 {
      &:before {
        background: #09d585;
      }
    }
    &.step2 {
      &:before {
        background: #33aaff;
      }
    }
    &.step3 {
      &:before {
        background: #7151f2;
      }
    }
    &.step4 {
      &:before {
        background: #ff8c54;
      }
    }
    &:before {
      content: '';
      margin-right: 8px;
      width: 6px;
      height: 6px;
      border-radius: 50%;
    }
  `,
};

const Legend = ({ style }: { style?: CSSProperties }) => {
  return (
    <LegendStyle.Wrap style={style}>
      <LegendStyle.Item className={'step0'}>사진 업로드 미완료</LegendStyle.Item>
      <LegendStyle.Item className={'step1'}>사진 업로드 완료</LegendStyle.Item>
      <LegendStyle.Item className={'step2'}>점유율 입력</LegendStyle.Item>
      {/*<LegendStyle.Item className={'step3'}>잔여량 입력</LegendStyle.Item>*/}
      <LegendStyle.Item className={'step4'}>검수완료</LegendStyle.Item>
    </LegendStyle.Wrap>
  );
};

export default Legend;
