import React, { useState, useCallback } from 'react';

// 툴팁 hook 함수
// 툴팁의 정보, show & hide 함수
export const useTooltip = () => {
  const [tooltip, setTooltip] = useState({
    text: '',
    visible: false,
    x: 0,
    y: 0,
  });

  // 마우스가 대상 위에 있을 때 Tooltip 표시
  const showTooltip = useCallback((text: string, x: number, y: number) => {
    setTooltip({ text, visible: true, x, y });
  }, []);

  // 마우스가 대상에서 벗어날 때 Tooltip 숨기기
  const hideTooltip = useCallback(() => {
    setTooltip((prev) => ({ ...prev, visible: false }));
  }, []);

  return { tooltip, showTooltip, hideTooltip };
};

// 툴팁 컴포넌트
function Tooltip({ text, x, y, visible }) {
  //   console.log({ text, x, y, visible });
  if (!visible) return null;

  return (
    <div
      style={{
        position: 'fixed',
        top: y + 10, // 커서 아래로 약간의 거리
        left: x + 10,
        backgroundColor: '#0000009b',
        color: 'white',
        padding: '5px 10px',
        borderRadius: '4px',
        fontSize: '11px',
        pointerEvents: 'none',
        zIndex: 1000,
        whiteSpace: 'nowrap',
      }}
    >
      {text}
    </div>
  );
}

export default Tooltip;
