import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import ImgNotice from '../../assets/images/notice/img-notice.png';
import ImgEmergency from '../../assets/images/notice/img-emergency.png';
import ImgMaintenance from '../../assets/images/notice/img-maintenance.png';
import Checkbox from '../atoms/Checkbox';
import { Swiper, SwiperSlide } from 'swiper/react';

// Import Swiper styles
import 'swiper/css';
import { Pagination } from 'swiper/modules';
import axios from 'axios';

export const NoticeStyle = {
  Dimmed: styled.div`
    width: 100%;
    height: 100%;
    position: fixed;
    left: 0;
    top: 0;
    z-index: 99;
    background: rgba(0, 0, 0, 0.5);
  `,
  Wrap: styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    position: fixed;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    z-index: 100;
    width: 460px;
    border-radius: 8px;
    border: 2px solid #0d0d0d;
    box-sizing: border-box;
  `,
  Cont: styled.div`
    position: relative;
    z-index: 2;
    width: 100%;
    display: flex;
    flex-direction: column;
    border-radius: 8px 8px 0 0;
    align-items: center;
    background: #fff;
    .swiper {
      width: 100%;
      text-align: center;
    }
  `,
  Close: styled.button`
    width: 40px;
    height: 40px;
    position: absolute;
    right: -1px;
    top: -1px;
    z-index: 10;
    background: #0d0d0d;
    border-radius: 0 8px 0 8px;
    &:before,
    &:after {
      position: absolute;
      left: 50%;
      top: 50%;
      content: '';
      background: #fff;
      transform: translate(-50%, -50%) rotate(45deg);
    }
    &:before {
      width: 2px;
      height: 16px;
    }
    &:after {
      width: 16px;
      height: 2px;
    }
  `,
  Inner: styled.div`
    padding: 0 30px 24px;
    text-align: center;
  `,
  Title: styled.strong<{ order: number }>`
    display: inline-flex;
    align-items: center;
    justify-content: center;
    height: 56px;
    padding: 0 32px;
    color: #fff;
    font-size: 20px;
    font-weight: 600;
    background: ${(p) => (p.order === 2 ? '#EB5846' : '#00b074')};
    border-radius: 0 0 8px 8px;
    margin-bottom: 27px;
  `,
  NoticeTypeImg: styled.div<{ order: number }>`
    margin: 0 auto;
    width: 200px;
    height: 172px;
    margin-bottom: 18px;
    background: url(${(p) => (p.order === 0 || p.order === 1 ? ImgMaintenance : p.order === 2 ? ImgEmergency : ImgNotice)}) no-repeat center;
  `,
  DescBox: styled.div<{ order: number }>`
    width: 100%;
    border-top: 1px solid ${(p) => (p.order === 2 ? '#EB5846' : '#00b074')};
    border-bottom: 1px solid ${(p) => (p.order === 2 ? '#EB5846' : '#00b074')};
    height: 102px;
    color: #001a21;
    margin-bottom: 14px;
  `,
  Desc: styled.p`
    padding: 14px 10px;
    font-size: 16px;
    font-weight: 500;
    text-align: left;
    line-height: 24px;
    word-break: keep-all;
  `,
  ListBox: styled.ul`
    background: #fff;
    border-radius: 8px;
  `,
  Item: styled.li<{ order: number }>`
    display: flex;
    align-items: center;
    strong {
      margin-right: 16px;
      text-align: center;
      line-height: 30px;
      min-width: 114px;
      height: 30px;
      border: 1px solid ${(p) => (p.order === 2 ? '#EB5846' : '#00b074')};
      border-radius: 50px;
      color: ${(p) => (p.order === 2 ? '#EB5846' : '#00b074')};
      font-size: 14px;
      font-weight: 600;
      box-sizing: border-box;
    }
    p {
      color: #3f3f3f;
      font-size: 16px;
      font-weight: 500;
    }
    &:not(:last-child) {
      margin-bottom: 6px;
    }
  `,
  Support: styled.p`
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 48px;
    background: #0d0d0d;
    color: #fff;
    font-size: 14px;
    font-weight: 500;
    span {
      margin-left: 8px;
      color: #00b074;
    }
  `,
  NoRepeat: styled.div`
    display: flex;
    width: calc(100% - 48px);
    height: 52px;
    padding: 0 24px;
    background: #f0f5f3;
    .swiperPage {
      display: flex;
      align-items: center;
      width: auto;
      margin-left: auto;

      .swiper-pagination-bullet {
        position: relative;
        background: none;
        width: auto;
        height: auto;
        padding: 0 6px;
        margin: 0 1px;
        color: #767676;
        opacity: 1;
        font-size: 14px;
        font-weight: 400;
        &.swiper-pagination-bullet-active {
          color: #00b074;
          &:after {
            position: absolute;
            left: 50%;
            bottom: -4px;
            transform: translateX(-50%);
            content: '';
            width: 8px;
            height: 2px;
            background: #00b074;
          }
        }
      }
    }
  `,
};

/**
 *
 * 공지사항 팝업
 *
 */
const Notice = ({ isMaintenanceMode, onClose, notice, noRepeat, setNoRepeat }) => {
  const pagination = {
    el: '.swiperPage',
    clickable: true,
    renderBullet: function (index, className) {
      return '<span class="' + className + '">' + (index + 1) + '</span>';
    },
  };

  return (
    <>
      <NoticeStyle.Dimmed></NoticeStyle.Dimmed>
      <NoticeStyle.Wrap>
        <NoticeStyle.Cont>
          {!isMaintenanceMode && (
            <NoticeStyle.Close type={'button'} onClick={onClose}>
              <span className={'hidden'}>닫기</span>
            </NoticeStyle.Close>
          )}

          <Swiper slidesPerView={1} modules={[Pagination]} pagination={pagination}>
            {notice
              .filter((i) => i.show)
              .map((i) => {
                return (
                  <SwiperSlide>
                    <NoticeStyle.Title order={i.order}>{i.order === 0 ? '서버 점검중' : i.order === 1 ? '서버 점검 안내' : i.order === 2 ? '긴급 공지 사항' : '일반 공지 사항'}</NoticeStyle.Title>
                    <NoticeStyle.Inner>
                      <NoticeStyle.NoticeTypeImg order={i.order}></NoticeStyle.NoticeTypeImg>
                      <NoticeStyle.DescBox order={i.order}>
                        <NoticeStyle.Desc>{i.content}</NoticeStyle.Desc>
                      </NoticeStyle.DescBox>
                      <NoticeStyle.ListBox>
                        {i.date ? (
                          <NoticeStyle.Item order={i.order}>
                            <strong>점검시간</strong>
                            <p>{i.date}</p>
                          </NoticeStyle.Item>
                        ) : null}
                        {i.title ? (
                          <NoticeStyle.Item order={i.order}>
                            <strong>작업내용</strong>
                            <p>{i.title}</p>
                          </NoticeStyle.Item>
                        ) : null}
                        {i.time ? (
                          <NoticeStyle.Item order={i.order}>
                            <strong>예상 소요 시간</strong>
                            <p>{i.time}</p>
                          </NoticeStyle.Item>
                        ) : null}
                      </NoticeStyle.ListBox>
                    </NoticeStyle.Inner>
                  </SwiperSlide>
                );
              })}
          </Swiper>
          {/* <SwiperStyle.Navi>
          <button type={'button'} id={'btnSwiperPrev'} className={'btnSwiperPrev'}></button>
          <button type={'button'} id={'btnSwiperNext'} className={'btnSwiperNext'}></button>
        </SwiperStyle.Navi> */}
          {/* <SwiperStyle.Footer>
          <div id={'swiper-pagination'}></div>
        </SwiperStyle.Footer> */}

          <NoticeStyle.Support>
            문의하기
            <span>support@movements.kr</span>
          </NoticeStyle.Support>
          <NoticeStyle.NoRepeat>
            {!isMaintenanceMode && (
              <Checkbox
                id={'noRepeat'}
                text={'다시 보지 않기'}
                onChange={(e) => {
                  setNoRepeat(e.target.checked);
                }}
                checked={noRepeat}
              />
            )}
            <div className={'swiperPage'}></div>
          </NoticeStyle.NoRepeat>
        </NoticeStyle.Cont>
      </NoticeStyle.Wrap>
    </>
  );
};

export default Notice;
