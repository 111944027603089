import React, { Fragment, useEffect, useRef, useState } from 'react';
import { ActiveAreaSt } from '../_style';
import { useRecoilState, useRecoilValue, useResetRecoilState } from 'recoil';
import { confirmState, popupLargeImgState, toastPopState, userInfoState } from 'store/Atoms';
import query from 'hooks/RQuery/utils';
// components
import Button from 'components/atoms/Button';
import RangeSlider from 'components/templates/Map/RangeSlider';
// ico
import ImageNo1200 from 'assets/images/image-no-1200.png';
import ImageNo from 'assets/images/image-no.png';
import IcoPic from 'assets/images/button/ico-pic.svg';
import IcoPicCompletion from 'assets/images/button/ico-pic-completion.svg';
// ol
import OlCore from 'ol/OlCore2';
import ObjectFarmMap from 'ol/Object/ObjectFarmMap2';
import Legend from '../../Map/Legend';
import ImageWithCheckUrl from './ImageWithCheckUrl';
import imageDefault from 'assets/images/image-default.png';
import ImageWithRetry from './ImageWithRetry';
import Tooltip, { useTooltip } from 'components/atoms/Tooltip';

// 선택된 항목 있을때 상세 이미지 & 지도 영역
function ActiveArea({ hide, cardData, round, refetchSurveyLots, lastCheckedId, onChangeFeatureStatus, updateTime }) {
  const surveyId = round.find(({ active }) => active)?.surveyId;
  const [confirm, setConfirm] = useRecoilState(confirmState);

  const wRef = useRef<any>();
  const zRef = useRef<any>();
  const { tooltip, showTooltip, hideTooltip } = useTooltip(); // 사진 타이틀 툴팁 보여주기 hook
  const [popLarge, setPopLarge] = useRecoilState(popupLargeImgState); // recoil 사진크게보기 팝업
  const [toastPop, setToastPop] = useRecoilState(toastPopState); //recoil 토스트 팝업

  const [selectedW, setSelectedW] = useState(null); // 중복사진 중 선택된 항목(원경)
  const [selectedZ, setSelectedZ] = useState(null); // 중복사진 중 선택된 항목(근경)

  const [wzImageArr, setWZImageArr] = useState<any[]>([]); // 여러 필지의 원경,근경 이미지 배열
  const [wImageArr, setWImageArr] = useState<any[]>([]); //원경 (필지1개)
  const [zImageArr, setZImageArr] = useState<any[]>([]); // 근경 (필지1개)

  const [mapInstance, setMapInstance] = useState<OlCore>();
  const [isObjectReady, setIsObjectReady] = useState(false);
  const [objectFarmMap, setObjectFarmMap] = useState<ObjectFarmMap>();

  // 회차별 중복 이미지 선택 등록(선택 외 나머지 삭제)
  const { mutate: deleteDuplicatedImage } = query.survey.useDeleteDuplicateImage(surveyId);

  // TODO: 지도 올리기 (OlCore2)
  useEffect(() => {
    const parantElement = document.getElementById('mapContainer2');
    if (!parantElement?.querySelector('.ol-viewport')) {
      setTimeout(() => {
        const core = new OlCore(); // 중요) OlCore2
        const objectFarmMap = new ObjectFarmMap(core);

        setMapInstance(core);
        setObjectFarmMap(objectFarmMap);
        setIsObjectReady(true);
      }, 700);
    }
  }, []);

  //체크된 cardList
  useEffect(() => {
    if (isObjectReady) {
      const newArr: any = [];
      cardData
        .filter(({ active }) => active)
        .map((i) => {
          newArr.push(i);
        });
      objectFarmMap?.draw(newArr);
    }
  }, [isObjectReady, cardData]);

  useEffect(() => {
    if (isObjectReady) {
      if (lastCheckedId) {
        objectFarmMap?.moveTo(lastCheckedId);
      }
    }
  }, [isObjectReady, lastCheckedId]);

  useEffect(() => {
    const handleWheel = (e) => {
      e.preventDefault();
      if (zRef.current) {
        zRef.current.scrollLeft += e.deltaY;
      }
    };

    const scrollContainer = zRef.current;
    if (scrollContainer) {
      scrollContainer.addEventListener('wheel', handleWheel, { passive: false });
    }

    return () => {
      if (scrollContainer) {
        scrollContainer.removeEventListener('wheel', handleWheel);
      }
    };
  });
  useEffect(() => {
    const handleWheel = (e) => {
      e.preventDefault();
      if (wRef.current) {
        wRef.current.scrollLeft += e.deltaY;
      }
    };

    const scrollContainer = wRef.current;
    if (scrollContainer) {
      scrollContainer.addEventListener('wheel', handleWheel, { passive: false });
    }

    return () => {
      if (scrollContainer) {
        scrollContainer.removeEventListener('wheel', handleWheel);
      }
    };
  });

  // 회차별 중복 이미지 선택 등록
  const mutateDuplicateImage = async () => {
    console.log(surveyId);
    const surveyFarmMapId = cardData?.filter(({ active }) => active)?.[0]?.surveyFarmMapId;
    await deleteDuplicatedImage(
      {
        surveyId,
        surveyFarmMapId,
        wideImageId: selectedW,
        zoomImageId: selectedZ,
      },
      {
        onSuccess(data, variables, context) {
          console.log(data);
          setToastPop({ ...toastPop, show: false });
          onChangeFeatureStatus(
            cardData.filter(({ active }) => active),
            true,
          );
          setConfirm({
            ...confirm,
            show: true,
            rightText: '확인',
            rightFunc: () => {
              setConfirm({ ...confirm, show: false });
              refetchSurveyLots(); // 필지 목록 재요청
              setSelectedW(null); // 선택 원경 초기화
              setSelectedZ(null); // 선택 근경 초기화
            },
            message: '사진 최종 선택 완료.',
          });
        },
        onError(error: any, variables, context) {
          console.log(error);
          const errMsg = error?.response?.data?.message;
          setConfirm({
            ...confirm,
            show: true,
            rightText: '확인',
            rightFunc: () => {
              setConfirm({ ...confirm, show: false });
              refetchSurveyLots().then(() => {
                setConfirm({ ...confirm, message: '최신 정보를 불러왔습니다.', show: true, leftText: undefined, rightText: '확인', rightFunc: () => setConfirm({ ...confirm, show: false }) });
              }); // 필지 목록 재요청
              setSelectedW(null); // 선택 원경 초기화
              setSelectedZ(null); // 선택 근경 초기화
            },
            message: errMsg || '시스템 관리자에게 문의하세요.',
            isError: true,
          });
        },
      },
    );
  };

  // 중복 사진 선택 항목
  useEffect(() => {
    if (selectedW && selectedZ) {
      setToastPop({
        ...toastPop,
        title: '사진 최종 선택',
        message: '다른 복수 사진은 삭제됩니다. 최종 선택 하시겠습니까?',
        leftText: '취소',
        rightText: '확인',
        leftFunc: () => {
          setToastPop({ ...toastPop, show: false });
          setSelectedW(null);
          setSelectedZ(null);
          setWImageArr((prev) => [...prev]?.map((ele) => ({ ...ele, completion: false })));
          setZImageArr((prev) => [...prev]?.map((ele) => ({ ...ele, completion: false })));
        },
        rightFunc: async () => {
          // 중복 사진 선택 후 나머지 삭제 API
          await mutateDuplicateImage();
        },
        show: true,
      });
    } else {
      setToastPop({
        ...toastPop,
        show: false,
      });
    }
  }, [selectedW, selectedZ]);

  //🔑 cardData - 선택된 카드 데이터 -> 보여줄 이미지 리스트 데이터 생성
  useEffect(() => {
    console.log('update');
    let noImgObj = { fileName: '', imageId: '', origin: ImageNo1200, thumbnail: ImageNo };
    const activeCard = [...cardData].filter(({ active }) => active); // 선택된 카드들만 필터

    // 필지 없을때, 초기화
    if (activeCard?.length === 0) {
      setWImageArr([noImgObj]);
      setZImageArr([noImgObj]);
    }

    // 필지1개 : 원경, 근경 따로 저장
    if (activeCard?.length === 1) {
      let newArrW: any[] = [];
      let newArrZ: any[] = [];

      const wideImageCount = activeCard[0].imageList?.wideImageCount;
      const zoomImageCount = activeCard[0].imageList?.zoomImageCount;
      const wideImageList = activeCard[0].imageList?.wideImageList; // 중복이미지일수있음
      const zoomImageList = activeCard[0].imageList?.zoomImageList; // 중복이미지일수있음

      newArrW.push(...wideImageList);
      newArrZ.push(...zoomImageList);

      if (wideImageList?.length === 0) {
        newArrW.push(noImgObj);
      }

      if (zoomImageList?.length === 0) {
        newArrZ.push(noImgObj);
      }
      setWImageArr(newArrW);
      setZImageArr(newArrZ);
    }

    // 필지 여러개 : 원경,근경 묶음으로 저장
    if (activeCard?.length > 1) {
      let imgArr: any[] = [];
      activeCard?.map((card, idx) => {
        let obj = { wImage: {}, zImage: {} };
        const wideImageCount = card.imageList?.wideImageCount;
        const zoomImageCount = card.imageList?.zoomImageCount;
        const wideImageList = card.imageList?.wideImageList; // length > 1이면, 중복이미지기에 여러개 선택 불가능
        const zoomImageList = card.imageList?.zoomImageList;
        obj['wImage'] = wideImageList?.length === 0 ? noImgObj : wideImageList[0];
        obj['zImage'] = zoomImageList?.length === 0 ? noImgObj : zoomImageList[0];

        imgArr.push(obj);
      });
      setWZImageArr(imgArr);
    } else {
      setWZImageArr([]);
    }
  }, [cardData, updateTime]);

  //사진 선택 버튼 클릭
  const selectPic = (imageId, type) => {
    if (type === '원경') {
      setSelectedW((prev) => (prev === imageId ? '' : imageId));
      setWImageArr((prev) => [...prev]?.map((ele) => ({ ...ele, completion: selectedW !== imageId && ele.imageId === imageId })));
    }
    if (type === '근경') {
      setSelectedZ((prev) => (prev === imageId ? '' : imageId));
      setZImageArr((prev) => [...prev]?.map((ele) => ({ ...ele, completion: selectedZ !== imageId && ele.imageId === imageId })));
    }
  };

  // 이미지 클릭 -> 크게보기 팝업 오픈
  const onClickImage = (type, idx, startIndex = 0) => {
    const activeCard = [...cardData].filter(({ active }) => active); // 선택된 카드들만 필터
    const isMultiPic = activeCard?.length > 1; //선택된 필지 여러개 (= 단일세트가 여러개)
    const mapId = activeCard?.[idx]?.mapId;

    // 원경,근경 한세트
    if (isMultiPic) {
      // setPopLarge({ open: true, mapId, imgArr: [wzImageArr[idx].zImage, wzImageArr[idx].wImage], startIndex });
      if (type === '복수필지_근경') {
        setPopLarge({ open: true, mapId, imgArr: [wzImageArr[idx].zImage] });
      }
      if (type === '복수필지_원경') {
        setPopLarge({ open: true, mapId, imgArr: [wzImageArr[idx].wImage] });
      }
    } else {
      // setPopLarge({ open: true, mapId, imgArr: [zImageArr[0]] });
      if (type === '단일세트_근경') {
        setPopLarge({ open: true, mapId, imgArr: [zImageArr[0]] });
      }
      if (type === '단일세트_원경') {
        setPopLarge({ open: true, mapId, imgArr: [wImageArr[0]] });
      }
      // 슬라이드(startIndex)
      if (type === '원경') {
        setPopLarge({ open: true, mapId, imgArr: wImageArr, startIndex });
      }
      // 슬라이드(startIndex)
      if (type === '근경') {
        setPopLarge({ open: true, mapId, imgArr: zImageArr, startIndex });
      }
    }
  };

  // 필지 여러개 선택 여부
  const isMultiPic = wzImageArr?.length > 0;

  // 이미지 태그 캐시 없애기
  const imgQueryString = `?v=${new Date().getTime()}`;

  return (
    <>
      {/* 툴팁 컴포넌트 : 사진 타이틀 hover  */}
      <Tooltip text={tooltip.text} x={tooltip.x} y={tooltip.y} visible={tooltip.visible} />

      <ActiveAreaSt.Wrap className={hide ? 'hide' : undefined}>
        <ActiveAreaSt.PicArea $isMultiPic={isMultiPic}>
          {/* 여러개 필지 선택 */}
          {isMultiPic && (
            <ActiveAreaSt.PicWrap className="cScroll" $isMultiPic={isMultiPic}>
              {wzImageArr?.map((ele, idx) => (
                <Fragment key={`picMulti_${idx}`}>
                  <ActiveAreaSt.PicCard $width={456} $isMultiPic={isMultiPic}>
                    <ActiveAreaSt.PicCardInner>
                      <ActiveAreaSt.PicBox onClick={() => onClickImage('복수필지_근경', idx, 0)}>
                        <ActiveAreaSt.PicTitle onMouseMove={(e) => showTooltip(ele.zImage?.fileName, e.clientX, e.clientY)} onMouseLeave={hideTooltip}>
                          {ele.zImage?.fileName}
                        </ActiveAreaSt.PicTitle>
                        {/* <img src={ele.zImage?.origin} alt={ele.zImage?.fileName + '_근경'} onError={onErrImage} /> */}
                        {/* <ImageWithCheckUrl updateTime={updateTime} src={ele.zImage?.origin} alt={ele.zImage?.fileName + '_근경'} /> */}
                        <ImageWithRetry src={ele.zImage?.thumbnail} alt={ele.zImage?.fileName + '_근경'} />
                      </ActiveAreaSt.PicBox>
                      <ActiveAreaSt.PicBox onClick={() => onClickImage('복수필지_원경', idx, 1)}>
                        <ActiveAreaSt.PicTitle onMouseMove={(e) => showTooltip(ele.wImage?.fileName, e.clientX, e.clientY)} onMouseLeave={hideTooltip}>
                          {ele.wImage?.fileName}
                        </ActiveAreaSt.PicTitle>
                        {/* <img src={ele.wImage?.origin} alt={ele.wImage?.fileName + '_원경'} onError={onErrImage} /> */}
                        {/* <ImageWithCheckUrl updateTime={updateTime} src={ele.wImage?.origin} alt={ele.wImage?.fileName + '_원경'} /> */}
                        <ImageWithRetry src={ele.wImage?.thumbnail} alt={ele.wImage?.fileName + '_원경'} />
                      </ActiveAreaSt.PicBox>
                    </ActiveAreaSt.PicCardInner>
                  </ActiveAreaSt.PicCard>
                </Fragment>
              ))}
            </ActiveAreaSt.PicWrap>
          )}

          {/* 단일 필지 선택 */}
          {!isMultiPic ? (
            /* 근경1 : 원경1 */
            wImageArr?.length === 1 && zImageArr?.length === 1 ? (
              <div className="picWrap cScroll">
                <ActiveAreaSt.PicCard className={'single'}>
                  <ActiveAreaSt.PicCardInner>
                    <ActiveAreaSt.PicBox onClick={() => onClickImage('단일세트_근경', 0, 0)}>
                      {zImageArr[0].fileName && (
                        <ActiveAreaSt.PicTitle onMouseMove={(e) => showTooltip(zImageArr[0].fileName, e.clientX, e.clientY)} onMouseLeave={hideTooltip}>
                          {zImageArr[0].fileName}
                        </ActiveAreaSt.PicTitle>
                      )}
                      {/* <img src={zImageArr[0].origin} alt={zImageArr[0].fileName + '_근경'} onError={onErrImage} /> */}
                      {/* <ImageWithCheckUrl updateTime={updateTime} src={zImageArr[0].origin} alt={zImageArr[0].fileName + '_근경'} /> */}
                      <ImageWithRetry src={zImageArr[0].thumbnail} alt={zImageArr[0].fileName + '_근경'} />
                    </ActiveAreaSt.PicBox>
                    <ActiveAreaSt.PicBox onClick={() => onClickImage('단일세트_원경', 0, 1)}>
                      {wImageArr[0].fileName && (
                        <ActiveAreaSt.PicTitle onMouseMove={(e) => showTooltip(wImageArr[0].fileName, e.clientX, e.clientY)} onMouseLeave={hideTooltip}>
                          {wImageArr[0].fileName}
                        </ActiveAreaSt.PicTitle>
                      )}
                      {/* <img src={wImageArr[0].origin} alt={wImageArr[0].fileName + '_원경'} onError={onErrImage} /> */}
                      {/* <ImageWithCheckUrl updateTime={updateTime} src={wImageArr[0].origin} alt={wImageArr[0].fileName + '_원경'} /> */}
                      <ImageWithRetry src={wImageArr[0].thumbnail} alt={wImageArr[0].fileName + '_원경'} />
                    </ActiveAreaSt.PicBox>
                  </ActiveAreaSt.PicCardInner>
                </ActiveAreaSt.PicCard>
              </div>
            ) : (
              <>
                {/* 원경 여러개 또는 근경 여러개 */}
                <div className="picBoxWrap" style={{ borderBottom: '1px solid #e9e7e7' }}>
                  <ActiveAreaSt.PicType>{'근경'}</ActiveAreaSt.PicType>
                  <div className="picWrap cScroll cScrollX" ref={zRef} style={{ display: 'flex', flexDirection: zImageArr?.length > 1 ? 'row' : 'column' }}>
                    {zImageArr?.map((ele, idx) => (
                      <ActiveAreaSt.PicCard key={`zImage_${idx}`} className={`${selectedZ ? (selectedZ === ele.imageId ? 'active' : 'disabled') : ''}`} onClick={() => onClickImage('근경', 0, idx)}>
                        <ActiveAreaSt.PicCardInner>
                          <ActiveAreaSt.PicBox>
                            {/* <div className="TitleWrap">
                              <p className="title"> {ele.fileName}</p>
                              <p className="tooltip"> {ele.fileName}</p>
                            </div> */}
                            <ActiveAreaSt.PicTitle onMouseMove={(e) => showTooltip(ele.fileName, e.clientX, e.clientY)} onMouseLeave={hideTooltip}>
                              {ele.fileName}
                            </ActiveAreaSt.PicTitle>
                            {/* <img src={ele.origin} alt={ele.titleW + '_근경'} onError={onErrImage} /> */}
                            {/* <ImageWithCheckUrl updateTime={updateTime} src={ele.origin} alt={ele.fileName + '_근경'} /> */}
                            <ImageWithRetry src={ele.thumbnail} alt={ele.titleW + '_근경'} />
                            {/* 단일 필지 선택시, 복수 사진일때 버튼 */}
                            {sessionStorage.getItem('auth') === 'ROLE_WORKER' && ele?.imageId && (
                              <Button
                                className={'selectBtn'}
                                $buttonType={'medium'}
                                text={'사진 선택'}
                                $iconSize={24}
                                icon={IcoPic}
                                $iconSpacing={6}
                                $isCompletion={ele.completion}
                                $iconCompletion={IcoPicCompletion}
                                onClick={(evt) => {
                                  evt.stopPropagation();
                                  selectPic(ele?.imageId, '근경');
                                }}
                              />
                            )}
                          </ActiveAreaSt.PicBox>
                        </ActiveAreaSt.PicCardInner>
                      </ActiveAreaSt.PicCard>
                    ))}
                  </div>
                </div>
                <div className="picBoxWrap">
                  <ActiveAreaSt.PicType>{'원경'}</ActiveAreaSt.PicType>
                  <div className="picWrap cScroll cScrollX" ref={wRef} style={{ display: 'flex', flexDirection: wImageArr?.length > 1 ? 'row' : 'column' }}>
                    {wImageArr?.map((ele, idx) => (
                      <ActiveAreaSt.PicCard key={`wImage_${idx}`} className={`${selectedW ? (selectedW === ele.imageId ? 'active' : 'disabled') : ''}`} onClick={() => onClickImage('원경', 0, idx)}>
                        <ActiveAreaSt.PicCardInner>
                          <ActiveAreaSt.PicBox>
                            <ActiveAreaSt.PicTitle onMouseMove={(e) => showTooltip(ele.fileName, e.clientX, e.clientY)} onMouseLeave={hideTooltip}>
                              {ele.fileName}
                            </ActiveAreaSt.PicTitle>
                            {/* <img src={ele.origin} alt={ele.fileName + '_원경'} onError={onErrImage} /> */}
                            {/* <ImageWithCheckUrl updateTime={updateTime} src={ele.origin} alt={ele.fileName + '_원경'} /> */}
                            <ImageWithRetry src={ele.thumbnail} alt={ele.fileName + '_원경'} />
                            {/* 단일 필지 선택시, 복수 사진일때 버튼 */}
                            {sessionStorage.getItem('auth') === 'ROLE_WORKER' && ele?.imageId && (
                              <Button
                                className={'selectBtn'}
                                $buttonType={'medium'}
                                text={'사진 선택'}
                                $iconSize={24}
                                icon={IcoPic}
                                $iconSpacing={6}
                                $isCompletion={ele.completion}
                                $iconCompletion={IcoPicCompletion}
                                onClick={(evt) => {
                                  evt.stopPropagation();
                                  selectPic(ele?.imageId, '원경');
                                }}
                              />
                            )}
                          </ActiveAreaSt.PicBox>
                        </ActiveAreaSt.PicCardInner>
                      </ActiveAreaSt.PicCard>
                    ))}
                  </div>
                </div>
              </>
            )
          ) : null}
        </ActiveAreaSt.PicArea>
        <ActiveAreaSt.MapArea>
          <div id={'mapContainer2'} className="mapContainer" style={{ position: 'relative' }}>
            {!isObjectReady && <div className="loadingPage">Map Loading...</div>}
            <RangeSlider mapInstance={mapInstance} position="left" />

            {/* 범례 */}
            <Legend style={{ right: 16, bottom: 16, left: 'auto' }} />
          </div>
        </ActiveAreaSt.MapArea>
      </ActiveAreaSt.Wrap>

      {/* 사진 크게보기 팝업 -> <Container/> 컴포넌트 */}
      {/* {popLarge && <PopupLargeImage close={() => setPopLarge(false)} />} */}
    </>
  );
}

export default ActiveArea;
