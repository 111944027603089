import React, { ChangeEvent, useState } from 'react';
import styled from 'styled-components';
import IcoPassword from '../../assets/images/input/ico-password.svg';
import IcoPasswordHide from '../../assets/images/input/ico-password-hide.svg';
import { maxLengthCheck } from 'util/global';

export interface InputProps {
  /** id(input의 id)*/
  id?: string;
  /** name(input의 name)*/
  name?: string;
  /** type(input의 type)*/
  type?: string;
  /** value(input의 value)*/
  value?: string | number;
  /** 초기값이 있을 경우 value 대신 입력*/
  defaultValue?: string | number;
  /** 너비(string은 입력)*/
  width?: string | number;
  /** 높이*/
  height?: number;
  /** 입력값이 에러인지 구분하는 boolean값*/
  $error?: boolean;
  /** 입력하는 폰트 크기*/
  fontSize?: number;
  /** 별도 스타일 지정시 css 입력 */
  style?: React.CSSProperties;
  /** 비활성화 여부 */
  disabled?: boolean;
  /** 값이 없을 때 보여줄 예시 텍스트*/
  placeholder?: string;
  /** 단위. 입력시 입력한 값을 input 끝에 보여줌*/
  unit?: string;
  /** 입력하는 영역 왼쪽에 아이콘 추가시 아이콘 경로 입력*/
  $leftIco?: any;
  /** 입력할 수 있는 최대 문자 수*/
  maxLength?: number;
  /** 입력값이 변경될 때 이벤트*/
  onChange?: (e: React.ChangeEvent<HTMLInputElement>) => void;
  /** 키보드 입력시 이벤트*/
  onKeyDown?: any;
  /** 읽기 전용 여부*/
  readOnly?: boolean;
  /** 특정 상황(유효성 성공/실패)에 따른 메세지 문자열*/
  message?: string;
  /** type='number' 일 때 최소값*/
  min?: number | string;
  /** type='number' 일 때 최대값*/
  max?: number | string;
}
export const InputStyle = {
  Wrap: styled.div<InputProps>`
    position: relative;
    display: flex;
    align-items: center;
    width: ${(p) => (p.width ? (typeof p.width === 'number' ? p.width + 'px' : p.width) : '16px')};
    height: ${(p) => (p.height ? p.height + 'px' : '16px')};
    box-sizing: border-box;
    border-radius: 4px;
    border: 1px solid ${(p) => (p.$error ? '#F4323D' : '#e5e5ec')};
    //overflow: hidden;
    &:focus-within {
      border-color: #00b074;
    }
    .unit {
      position: absolute;
      right: 10px;
      top: 50%;
      transform: translateY(-50%);
      color: gray;
      font-size: 14px;
      pointer-events: none; /* 클릭이 input으로 전달되도록 설정 */
    }
    .leftIco {
      width: 24px;
      height: 24px;
      display: block;
      background: ${(p) => `url(${p.$leftIco}) no-repeat center`};
      position: absolute;
      left: 20px;
      top: 50%;
      transform: translateY(-50%); /* 아이콘을 세로 중앙 정렬 */
    }
  `,
  InputContain: styled.div<InputProps>`
    width: 100%;
  `,
  Input: styled.input<InputProps>`
    /* flex: 1; */
    width: 100%;
    height: 100%;
    border: none;
    outline: none;
    color: #025143;
    border-radius: 4px;

    padding: ${(p) => (p.unit ? '0 30px 0 16px' : p.$leftIco ? '0 16px 0 54px' : '0 16px')};
    box-sizing: border-box;
    margin: 0;
    font-size: ${(p) => (p.fontSize ? p.fontSize + 'px' : '14px')};
    font-weight: 500;
    &::placeholder {
      color: #a5a5a5;
      font-weight: 400;
      font-size: ${(p) => (p.fontSize ? p.fontSize + 'px' : '14px')};
    }
    &:disabled {
      background: #f1f1f5;
    }
    &:hover {
      background: #e5f8e8;
      &:disabled {
        background: #f1f1f5;
      }
    }
    &::-webkit-inner-spin-button {
      appearance: none;
      -moz-appearance: none;
      -webkit-appearance: none;
    }
  `,
  ToggleType: styled.button<{ $isPassword: boolean }>`
    position: absolute;
    right: 16px;
    top: 50%;
    transform: translateY(-50%);
    width: 20px;
    height: 20px;
    background: url(${(p) => (p.$isPassword ? IcoPasswordHide : IcoPassword)}) no-repeat center;
  `,
  message: styled.p<{ $error?: boolean; left?: number }>`
    //position: absolute;
    margin-left: ${(p) => (p.left ? p.left + 5 + 'px' : 0)};
    margin-top: 6px;
    font-size: 12px;
    //top: calc(100% + 2px);
    color: ${(p) => (p.$error ? '#DC5700' : '#00B074')};
  `,
};

export const RangeInput = styled.div`
  display: flex;
  align-items: center;
  span {
    display: flex;
    align-items: center;
    color: #a5a5a5;
    font-size: 16px;
    font-weight: 400;
    padding: 0 8px;
    height: 40px;
    box-sizing: border-box;
    background: #fff;
    border-top: 1px solid #e5e5ec;
    border-bottom: 1px solid #e5e5ec;
  }
  ${InputStyle.Wrap} {
    &:first-child {
      border-radius: 4px 0 0 4px;
    }
    &:last-child {
      border-radius: 0 4px 4px 0;
    }
  }
`;
const Input = ({ type = 'text', id, name, value, width, height, fontSize, $error, disabled, style, onChange, placeholder, unit, $leftIco, maxLength, onKeyDown, defaultValue, readOnly, min, max }: InputProps) => {
  const [$isPassword, setIsPassword] = useState(true);

  /**
   * 비밀번호 보이기/숨기기
   * @param $isPassword true일때 입력값 마스킹, false일때 입력값 그대로 표출
   * */
  const onToggle = () => {
    setIsPassword(!$isPassword);
  };

  /**
   * 입력값과 최대값 비교하는 함수
   * */
  const checkMax = (e) => {
    if (Number(e.target.value) > Number(e.target.max)) {
      // e.target.value = e.target.max;
      console.log('update');
    }
  };

  const onChangeWithType = (e) => {
    //  if (type === 'number') {
    //    const inputValue = e.target.value;
    //    // 숫자만 허용
    //    if (/^\d*$/.test(inputValue)) {
    //    } else {
    //      e.target.value = e.target.value.replace(/[^0-9]/g, ''); // 숫자만 허용
    //      return;
    //    }
    //  }
    if (onChange) {
      onChange(e);
    }
  };

  return (
    <InputStyle.Wrap width={width} height={height} $error={$error} style={style} $leftIco={$leftIco}>
      {$leftIco && <span className="leftIco"></span>}
      <InputStyle.Input
        type={type === 'password' ? ($isPassword ? 'password' : 'text') : type}
        $leftIco={$leftIco}
        id={id}
        name={name}
        onChange={onChangeWithType}
        fontSize={fontSize}
        disabled={disabled}
        readOnly={readOnly}
        placeholder={placeholder}
        defaultValue={defaultValue}
        value={value}
        min={min}
        max={max}
        maxLength={maxLength}
        onKeyUp={maxLength ? maxLengthCheck : max ? checkMax : undefined}
        onKeyDown={(e) => {
          if (e.key === 'Enter') {
            onKeyDown && onKeyDown();
          }
        }}
      />
      {unit && <span className="unit">{unit}</span>}
      {type === 'password' ? (
        <InputStyle.ToggleType type={'button'} onClick={onToggle} $isPassword={$isPassword} tabIndex={-1}>
          <span className={'hidden'}>보이기/숨기기</span>
        </InputStyle.ToggleType>
      ) : null}
    </InputStyle.Wrap>
  );
};

export default Input;
