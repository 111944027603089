import React, { useState, useEffect, useCallback } from 'react';
import OlCore from 'ol/OlCore';
import ObjectPolygon from 'ol/Object/ObjectPolygon';
import styled from 'styled-components';
import RangeSlider from '../components/templates/Map/RangeSlider';
import { getJusoFromCoord, getCoordFromJuso } from 'service/geoCoder';
import SelectAddress from 'components/templates/Map/SelectAddress';

import { CropInfo } from 'components/templates/Map/CropInfo';
import Header from '../components/templates/Map/Header';
import TopBtnGroup from 'components/templates/Map/TopBtnGroup';
import { useRecoilState } from 'recoil';
import { confirmState, loadingState, stdgCdState } from '../store/Atoms';
import ObjectFarmMap from '../ol/Object/ObjectFarmMap';
import LotOverlay from '../components/templates/LotOverlay';
import Legend from '../components/templates/Map/Legend';
import ReloadMapData from '../components/templates/Map/ReloadMapData';
import query from '../hooks/RQuery/utils';
import { postFarmMapListExtent } from 'service/farm';
import SelectAddressSTDG from 'components/templates/Map/SelectAddressSTDG';

function Map(props) {
  const [confirmStatus, setConfirmStatus] = useRecoilState(confirmState);
  const [stdgCdInfo, setStdgCdInfo] = useRecoilState(stdgCdState);
  const [loading, setLoading] = useRecoilState(loadingState);
  const [mapInstance, setMapInstance] = useState<OlCore>();
  const [objectPolygon, setObjectPolygon] = useState<ObjectPolygon>();
  const [objectFarmMap, setObjectFarmMap] = useState<ObjectFarmMap>();
  const [measureStatus, setMeasureStatus] = useState(false);
  const [measureType, setMeasureType] = useState<'Polygon' | 'LineString'>('Polygon');
  // const [mapScaleNum, setMapScaleNum] = useState('0 m');
  const [overlayInfo, setOverlayInfo] = useState<any>({});
  const [isSingleLot, setIsSingleLot] = useState(false);
  const [singleFarmMapId, setSingleFarmMapId] = useState('');
  const [round, setRound] = useState<any>([]);
  const [centerLevels, setCenterLevels] = useState(''); // 현재 지도 center의 주소 객체 (한글주소, 코드 등 포함)
  const [isReloadShow, setIsReloadShow] = useState(false);
  let isObjectReady = !!objectPolygon;

  const [popState, setPopState] = useState({
    lotInfo: false,
  });

  const { data: farmMapData, refetch: refetchFarmMapData } = query.survey.useMapData({
    surveyId: round.filter(({ active }) => active)[0]?.value,
    stdgCd: stdgCdInfo?.code,
    //  lastPnu: param,
    //  limit: 1000,
  });
  const [showCropInfo, setShowCropInfo] = useState(false); // 작물 정보 팝업 오픈

  // 지도 이동시, center기준으로 주소 가져와서 표기
  useEffect(() => {
    if (isObjectReady) {
      // 지도 이동 완료 이벤트
      mapInstance?.onMoveEnd(async () => {
        // extent 좌표, center 좌표, proj, zoom
        console.log(mapInstance?.getMapInstance().getView().getViewStateAndExtent());
        const proj = mapInstance?.getMapInstance().getView().getProjection()?.getCode(); // 현재 좌표계
        const centerCoord = mapInstance?.getMapInstance().getView().getCenter(); // 현재 중심좌표
        const zoom = mapInstance?.getMapInstance().getView().getZoom() || 0; // 현재 줌레벨

        try {
          const data = await getJusoFromCoord({ centerCoord, proj }); // 브이월드 API

          const result = data.result?.[0];
          const addrText = result.text; // 전체 주소
          const level0 = result.structure.level0;
          const level1 = result.structure.level1; // 도
          const level2 = result.structure.level2; // 시/군/구
          const level3 = result.structure.level3;
          const level4L = result.structure.level4L; // 읍/면/동
          const level4AC = result.structure.level4AC; // 코드
          const level4LC = result.structure.level4LC; // 코드
          console.log('level4 AC', result);
          console.log('level4 AC', level4AC);
          console.log('level4 LC', level4LC);

          setCenterLevels({ ...result, zoom }); // 중심이 변경되면 주소 저장 -> SelectAddress로 전달용
        } catch (e) {
          console.log(e);
        }
      });
    }
  }, [isObjectReady, mapInstance]);

  //Openlayers 초기화
  useEffect(() => {
    if (!document.querySelector('.ol-viewport')) {
      const core = new OlCore();
      const objectPolygon = new ObjectPolygon(core);
      const objectFarmMap = new ObjectFarmMap(core);

      setMapInstance(core);
      setObjectPolygon(objectPolygon);
      setObjectFarmMap(objectFarmMap);
    }
    // bjdCd 초기화
    setStdgCdInfo({ code: '', address: '' });
  }, []);

  // wfs 구역 경계
  useEffect(() => {
    if (isObjectReady) {
      objectPolygon?.drawSido(); //시도
      objectPolygon?.drawSigungu(); // 시군구
      objectPolygon?.drawEmd(); // 읍면동

      // mapInstance?.onMoveEnd(() => {
      //   setMapScaleNum(document.getElementsByClassName('ol-scale-line-inner')[0].innerHTML || '0 m');
      //   console.log(mapInstance?.getMapInstance().getView().getViewStateAndExtent());
      // });
    }
  }, [isObjectReady]);
  useEffect(() => {
    if (farmMapData && isObjectReady && stdgCdInfo?.code) {
      mapInstance?.getMapInstance().on('moveend', () => {
        setIsReloadShow(true);
      });
    } else {
      mapInstance?.getMapInstance().on('moveend', () => {
        setIsReloadShow(false);
      });
    }
  }, [farmMapData, isObjectReady, stdgCdInfo?.code]);

  // 위치 이동
  const clickMoveTo = async (juso) => {
    const proj = mapInstance?.getMapInstance().getView().getProjection()?.getCode();
    const data = await getCoordFromJuso({ juso, proj }); // 브이월드 API 요청 (주소로 좌표 구하기)
    console.log('getCoordFromJuso', data);
    //  const point = data.result.point;
    //  const coord = [point.x || 0, point.y || 0];
    //  const geometry = [point.x, point.y, point.x, point.y];
    //  mapInstance?.moveToCoord(coord);
    setIsReloadShow(false);
  };

  const clickMoveTo_code = async (code) => {
    try {
      const data = await postFarmMapListExtent({ code }); // 404에러
      console.log(data);
      // mapInstance?.moveToCoord(coord);
    } catch (e) {
      console.log(e);
    }
  };

  //필지 상태 변경
  const onChangeFeatureStatus = (checkCardList, isCount) => {
    objectFarmMap?.changeStatus(checkCardList, isCount);
  };

  const onReload = () => {
    setLoading({ show: true, isGif: true });
    refetchFarmMapData();
  };

  return (
    <>
      <div style={{ display: 'flex', width: '100%', height: '100%' }}>
        {/* <Navigation /> */}
        {/*<WorkPlaceList />*/}
        {/* <ClientAdd /> */}
        {/* <div style={{ width: '800px', height: ' 800px', background: '#dadada', position: 'absolute', top: '100px', left: '300px', zIndex: 10 }}>
          <Table />
        </div> */}
        <div style={{ width: '100%', height: '100%', position: 'relative' }} id={'mapContainer'}>
          {/*  /!* 현장 선택 & 회차 선택 *!/*/}
          <Header isObjectReady={isObjectReady} objectFarmMap={objectFarmMap} setOverlayInfo={setOverlayInfo} round={round} setRound={setRound} setShowCropInfo={setShowCropInfo} setIsReloadShow={setIsReloadShow} />
          {/*  /!* 주소 선택 *!/*/}
          <SelectAddress centerLevels={centerLevels} clickMoveTo={clickMoveTo} clickMoveTo_code={clickMoveTo_code} />
          {/* <SelectAddressSTDG round={round} centerLevels={centerLevels} clickMoveTo={clickMoveTo} clickMoveTo_code={clickMoveTo_code} /> */}

          {/* /!* 상단 버튼 모음 (사진중복확인 등) *!/ */}
          <TopBtnGroup round={round} onChangeFeatureStatus={onChangeFeatureStatus} />

          <RangeSlider mapInstance={mapInstance} />

          {/* /!*<MapScale mapScaleNum={mapScaleNum} id={'scaleBar'} />*!/ */}
          <ZoomLevel />

          {/* 범례 */}
          <Legend />

          {/* 리로드 버튼 */}
          <ReloadMapData onClick={onReload} isReloadShow={isReloadShow} />

          {/* /!* 작물 정보 버튼 & 팝업 *!/ */}
          <CropInfo round={round} isSingleLot={isSingleLot} setIsSingleLot={setIsSingleLot} singleFarmMapId={singleFarmMapId} showCropInfo={showCropInfo} setShowCropInfo={setShowCropInfo} onChangeFeatureStatus={onChangeFeatureStatus} />
        </div>
        <LotOverlay objectFarmMap={objectFarmMap} overlayInfo={overlayInfo} setIsSingleLot={setIsSingleLot} setSingleFarmMapId={setSingleFarmMapId} setShowCropInfo={setShowCropInfo} />
      </div>
    </>
  );
}

export default Map;

export function ZoomLevel() {
  const ZoomLevelSt = {
    Wrap: styled.div`
      position: fixed;
      top: 16px;
      left: 0;
      z-index: 9;
      background-color: #fff;
      display: none;
    `,
  };
  return <ZoomLevelSt.Wrap id="zoomLevel"></ZoomLevelSt.Wrap>;
}
