import React, { ReactNode } from 'react';
import styled from 'styled-components';

export const TableStyle = {
  Wrap: styled.div<{ height?: number }>`
    width: 100%;
    border-radius: 4px;
    //overflow: hidden;
    max-height: ${(p) => (p.height ? p.height + 'px' : '360px')};
    min-height: ${(p) => (p.height ? p.height + 'px' : '360px')};
    border: 1px solid #e9e7e7;
  `,
  Table: styled.table`
    width: 100%;
    height: 100%;
  `,
  Thead: styled.thead`
    tr {
      position: sticky;
      top: 0;
      z-index: 2;
      th {
        position: relative;
        background: #deedc9;
        height: 40px;
        color: #024751;
        font-size: 14px;
        font-weight: 500;
        text-indent: 20px;
        text-align: left;

        &:after {
          display: block;
          position: absolute;
          right: 0;
          top: 50%;
          transform: translateY(-50%);
          content: '';
          width: 1px;
          height: 20px;
          background: #b1bf9c;
        }
        &:first-child {
          border-radius: 4px 0 0 0;
        }
        &:last-child {
          border-radius: 0 4px 0 0;

          &:after {
            display: none;
          }
        }
        &.noBorder {
          &:after {
            display: none;
          }
        }
      }
    }
  `,
  Tbody: styled.tbody`
    tr {
      td {
        color: #3f3f3f;
        font-size: 14px;
        font-weight: 500;
        height: 40px;
        box-sizing: border-box;
        text-indent: 20px;
      }
      &:not(:last-child) {
        td {
          border-bottom: 1px solid #e9e7e7;
        }
      }
    }
  `,
};

export interface TableProps {
  caption?: string;
  colgroup?: (string | number)[];
  thead?: ReactNode;
  tbody?: ReactNode;
  height?: number;
}

const Table = ({ caption, colgroup, thead, tbody, height }: TableProps) => {
  return (
    <TableStyle.Wrap className={'cScroll'} height={height}>
      <TableStyle.Table>
        <caption>{caption}</caption>
        <colgroup>
          {colgroup?.map((i, idx) => {
            return <col key={'col' + idx} style={{ width: i }} />;
          })}
        </colgroup>
        <TableStyle.Thead>{thead}</TableStyle.Thead>
        <TableStyle.Tbody>{tbody}</TableStyle.Tbody>
      </TableStyle.Table>
    </TableStyle.Wrap>
  );
};

export default Table;
