import React, { useEffect, useState } from 'react';
import PopupTemplate from './PopupTemplate';
import styled from 'styled-components';
import Button from '../components/atoms/Button';
import IcoInfo from '../assets/images/button/ico-info.svg';
import IcoInfoDisabled from '../assets/images/button/ico-info-disabled.svg';
import Pagination from '../components/atoms/Pagination';
import IcoPic from '../assets/images/button/ico-pic.svg';
import IcoPicCompletion from '../assets/images/button/ico-pic-completion.svg';

import ImageW from '../assets/images/image-w.png';
import ImageZ from '../assets/images/image-z.png';
import IcoPrev from '../assets/images/button/ico-duplicated-prev.svg';
import IcoPrevDisabled from '../assets/images/button/ico-duplicated-prev-disabled.svg';
import IcoNext from '../assets/images/button/ico-duplicated-next.svg';
import IcoNextDisabled from '../assets/images/button/ico-duplicated-next-disabled.svg';
import IcoSubmit from '../assets/images/button/ico-submit.svg';
import IcoSubmitDisabled from '../assets/images/button/ico-submit-disabled.svg';
import IcoNoData from '../assets/images/noData/ico-no-image.svg';
import NoData from '../components/atoms/NoData';
import query from '../hooks/RQuery/utils';
import { ActiveAreaSt } from 'components/templates/InfoBox/_style';
import { useRecoilState } from 'recoil';
import { confirmState } from '../store/Atoms';
import { formatNumber } from 'util/global';
import Select from '../components/atoms/Select';

const Content = {
  Wrap: styled.div`
    display: flex;
    height: 744px;
    &.fetching {
      width: 100%;
      position: relative;
      &:after {
        content: '';
        display: block;
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background: rgba(0, 0, 0, 0.2);
        z-index: 9;
      }
    }
  `,
  LeftCol: styled.div`
    width: 304px;
    display: flex;
    flex-direction: column;
    box-sizing: border-box;
    border-right: 1px solid #d9d9d9;
    background: #f0f0f0;
  `,
  RightCol: styled.div`
    flex: 1;
    display: flex;
    flex-direction: column;
    background: #e4e3e3;
  `,
  Head: styled.div`
    display: flex;
    align-items: center;
    height: 40px;
    background: #f0f0f0;
    box-sizing: border-box;
    border-bottom: 1px solid #d9d9d9;
  `,
  Cont: styled.div`
    height: calc(100% - 40px);
  `,
  BjdArea: styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
    background: #fff;
    height: 56px;
    padding: 0 16px;
    box-sizing: border-box;
    border-bottom: 1px solid #d9d9d9;
    color: #024751;
    font-size: 16px;
    font-weight: 500;
    box-shadow: 0 3px 4px 0 rgba(0, 0, 0, 0.08);
  `,
  ListCont: styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    height: calc(100% - 96px);
    box-sizing: border-box;
  `,
  Foot: styled.div`
    display: flex;
    align-items: center;
    height: 64px;
    box-sizing: border-box;
    border-top: 1px solid #d9d9d9;
  `,
  ButtonArea: styled.div`
    flex: 1;
    display: flex;
    justify-content: flex-end;
    padding: 0 16px;
  `,
  PicType: styled.div`
    width: 328px;
    height: 48px;
    display: flex;
    align-items: center;
    justify-content: center;
    background: #fff;
    border: 1px solid #d9d9d9;
    box-sizing: border-box;
    color: #00b074;
    font-size: 14px;
    border-radius: 8px;
    font-weight: 500;
  `,
  PicBox: styled.div`
    flex: 1;
    height: 100%;
    padding: 8px 12px;
    box-sizing: border-box;
    &:first-child {
      border-right: 1px solid #d9d9d9;
    }
  `,
  Scroll: styled.div`
    margin-top: 10px;
    height: calc(100% - 58px);
  `,
  Tip: styled.p`
    padding-left: 16px;
    color: #00b074;
    font-size: 14px;
    font-weight: 500;
  `,
  Count: styled.div`
    display: flex;
    align-items: center;
    color: #00b074;
    font-size: 14px;
    font-weight: 500;
    span {
      margin-left: 4px;
      color: #024751;
      font-weight: 600;
    }
  `,
  List: styled.ul`
    height: calc(100% - 16px);
    padding: 8px 12px;
  `,
  Item: styled.li`
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    height: 44px;
    box-sizing: border-box;
    border-radius: 4px;
    border: 1px solid #e9e7e7;
    padding: 0 16px;
    background: #fff;
    &:not(:last-child) {
      margin-bottom: 6px;
    }
    &.completion {
      background: #ceefd4;
      border-color: #b0dfb9;
      span {
        background: #00b074;
      }
    }
    &.active {
      border: 2px solid #00b074;
      padding: 0 15px;
    }
    p {
      display: flex;
      justify-content: space-between;
      align-items: center;
      color: #024751;
      font-size: 14px;
      font-weight: 500;
      width: 100%;
      word-break: keep-all;
      > b {
        margin-left: 4px;
        background: #024751;
        color: #fff;
        font-size: 12px;
        font-weight: 600;
        padding: 0 6px;
        height: 20px;
        line-height: 20px;
        border-radius: 50px;
      }
    }
    span {
      display: flex;
      align-items: center;
      justify-content: center;
      border-radius: 50%;
      width: 20px;
      height: 20px;
      text-align: center;
      color: #fff;
      font-size: 12px;
      font-weight: 600;
      margin-left: 4px;
      background: #024751;
      &:nth-child(2) {
        margin-left: auto;
      }
    }
  `,
};

const PopupDuplicationPic = ({ onClose, round, onChangeFeatureStatus }) => {
  const surveyId = round.filter(({ active }) => active)[0]?.value;
  const [surveyFarmMapId, setSurveyFarmMapId] = useState('');
  const duplicatedList = query.survey.useDuplicatedImages(surveyId);
  const duplicatedDetail = query.survey.useDuplicatedImagesDetail(surveyFarmMapId);
  const submitDuplicate = query.survey.useDeleteDuplicateImage(surveyId);
  const [totalCount, setTotalCount] = useState(0);
  const [list, setList] = useState<any>([]);
  const [imageList, setImageList] = useState<any>({
    wideImages: [],
    zoomImages: [],
  });
  const [nowStdg, setNowStdg] = useState(null);
  const [confirm, setConfirm] = useRecoilState(confirmState);
  const [option, setOption] = useState([]);
  //선택된 필지의 index
  const selectedMapIndex = list?.find(({ active }) => active)?.mapIds?.findIndex((i) => i.surveyFarmMapId === surveyFarmMapId);

  // 중복 이미지가 있는 법정동 리스트 저장
  useEffect(() => {
    if (duplicatedList.data) {
      let count = 0;
      const newArr: any = [];
      const newOption: any = [];
      duplicatedList.data.map((i, index) => {
        count = count + i.duplicateCount;
        if (nowStdg) {
          newArr.push({ ...i, active: i.stdgCd === nowStdg });
        } else {
          newArr.push({ ...i, active: index === 0 });
        }
      });
      if (nowStdg) {
        newArr
          .find((i) => i.stdgCd === nowStdg)
          .mapIds?.map((j, idx) => {
            newOption.push({ text: j.mapId, value: j.surveyFarmMapId, active: idx === 0 });
          });
      } else {
        newArr[0]?.mapIds?.map((j, idx) => {
          newOption.push({ text: j.mapId, value: j.surveyFarmMapId, active: idx === 0 });
        });
      }
      setOption(newOption);
      setTotalCount(count);
      setList(newArr);
    }
  }, [duplicatedList.data]);

  // 법정동이 선택되면 선택한 법정동의 첫번째 중복필지의 중복이미지를 보여줌
  useEffect(() => {
    if (list.filter(({ active }) => active).length) {
      const newOption: any = [];
      setSurveyFarmMapId(list.find(({ active }) => active).mapIds[0].surveyFarmMapId);
      list
        ?.find(({ active }) => active)
        ?.mapIds?.map((i, index) => {
          newOption.push({ text: i.mapId, value: i.surveyFarmMapId, active: index === 0 });
        });
      setOption(newOption);
    }
  }, [list.filter(({ active }) => active)[0]]);

  // 중복필지의 상세 이미지를 저장
  useEffect(() => {
    if (duplicatedDetail.data) {
      const wideList: any = [];
      const zoomList: any = [];
      duplicatedDetail.data.wideImages.map((i) => {
        wideList.push({ ...i, selected: false });
      });
      duplicatedDetail.data.zoomImages.map((i) => {
        zoomList.push({ ...i, selected: false });
      });
      setImageList({
        wideImages: wideList,
        zoomImages: zoomList,
      });
    }
  }, [duplicatedDetail.data]);

  //사진선택
  const onPickImage = (type: 'W' | 'Z', num) => {
    const newObj = { ...imageList };
    newObj[type === 'W' ? 'wideImages' : 'zoomImages'].map((i, index) => {
      if (i.selected) {
        i.selected = false;
      } else {
        i.selected = index === num;
      }
    });
    setImageList(newObj);
  };

  //다음사진
  const onNext = () => {
    //현재 선택된 필지 index
    const nowNum = list?.find(({ active }) => active)?.mapIds?.findIndex((i) => i.surveyFarmMapId === surveyFarmMapId);
    setSurveyFarmMapId(list.find(({ active }) => active).mapIds[nowNum + 1].surveyFarmMapId);

    const newOption: any = [...option];
    newOption.map((i) => {
      i.active = list.find(({ active }) => active).mapIds[nowNum + 1].surveyFarmMapId === i.value;
    });
    setOption(newOption);
  };

  //이전사진
  const onPrev = () => {
    //현재 선택된 필지 index
    const nowNum = list?.find(({ active }) => active)?.mapIds?.findIndex((i) => i.surveyFarmMapId === surveyFarmMapId);
    setSurveyFarmMapId(list?.find(({ active }) => active)?.mapIds[nowNum - 1]?.surveyFarmMapId);

    const newOption: any = [...option];
    newOption.map((i) => {
      i.active = list?.find(({ active }) => active)?.mapIds[nowNum - 1]?.surveyFarmMapId === i.value;
    });
    setOption(newOption);
  };

  //셀렉트박스로 필지선택
  const onChangeSelect = (e) => {
    const newOption: any = [...option];
    setSurveyFarmMapId(e.target.ariaValueText);
    newOption.map((i) => {
      i.active = e.target.ariaValueText === i.value;
    });
    setOption(newOption);
  };

  //사진 최종 선택(저장)
  const onSubmit = () => {
    const wideImageId = imageList.wideImages.filter(({ selected }) => selected)[0].imageId;
    const zoomImageId = imageList.zoomImages.filter(({ selected }) => selected)[0].imageId;
    setConfirm({
      ...confirm,
      show: true,
      message: '사진을 선택하시겠습니까?<br>(선택하지 않은 사진은 삭제됩니다)',
      leftText: '취소',
      leftFunc: () => setConfirm({ ...confirm, show: false }),
      rightText: '확인',
      rightFunc: () => {
        //저장
        submitDuplicate.mutate(
          { surveyId, surveyFarmMapId, wideImageId, zoomImageId },
          {
            onSuccess: (res: any) => {
              if (list.find(({ active }) => active).mapIds.length === 1) {
                setNowStdg(null);
              }
              onChangeFeatureStatus(list, true);
              duplicatedList.refetch();
              setConfirm({
                ...confirm,
                show: true,
                message: res?.message || '사진 최종선택 완료',
                leftText: undefined,
                rightText: '확인',
                rightFunc: () => setConfirm({ ...confirm, show: false }),
              });
            },
            onError: (err: any) => {
              setConfirm({
                ...confirm,
                show: true,
                message: err?.response?.data?.message || '시스템 관리자에게 문의하세요.',
                leftText: undefined,
                rightText: '확인',
                isError: true,
                rightFunc: () => {
                  setConfirm({ ...confirm, show: false });
                  if (err?.response?.data?.status >= 400 && err?.response?.data?.status < 500) {
                    duplicatedList.refetch().then(() => {
                      setConfirm({
                        ...confirm,
                        message: '최신 정보를 불러왔습니다.',
                        show: true,
                        leftText: undefined,
                        rightText: '확인',
                        rightFunc: () => {
                          if (list.find(({ active }) => active).mapIds.length === 1) {
                            setNowStdg(null);
                          }
                          setConfirm({ ...confirm, show: false });
                        },
                      });
                    });
                  }
                },
              });
            },
          },
        );
      },
    });
  };

  //사진 최종 선택(저장) 버튼 disabled여부
  const isDisabled = () => {
    const wideImageId = imageList.wideImages.filter(({ selected }) => selected)[0]?.imageId;
    const zoomImageId = imageList.zoomImages.filter(({ selected }) => selected)[0]?.imageId;
    // const zoomImageId = list?.filter(({ active }) => active)[0]?.zoomImages.filter(({ active }) => active)[0]?.imageId;
    return !(!!wideImageId && !!zoomImageId);
  };

  //법정동 선택
  const onClickStdg = (stdg) => {
    const newArr = [...list];
    newArr.map((i) => {
      i.active = i.stdgCd === stdg;
    });
    setNowStdg(stdg);
    setList(newArr);
  };

  // console.log(duplicatedList?.isFetching);

  return (
    <PopupTemplate
      title={'사진 중복 확인'}
      width={1008}
      $type={2}
      isDimmed={true}
      onClose={onClose}
      isFetching={duplicatedList?.isFetching}
      content={
        <Content.Wrap className={duplicatedList?.isFetching ? 'fetching' : ''}>
          <Content.LeftCol>
            <Content.Head style={{ justifyContent: 'center' }}>
              <Content.Count>
                중복 사진 필지 / <span>{totalCount}건</span>
              </Content.Count>
            </Content.Head>
            <Content.Cont style={{ display: 'block' }}>
              {list.length ? (
                <Content.List className="cScroll">
                  {list.map((item, index) => {
                    return (
                      <Content.Item key={item.stdgCd} className={item.active ? 'active' : ''} onClick={() => onClickStdg(item.stdgCd)}>
                        <p>
                          {item.stdgAddr}
                          <b>{item.duplicateCount}</b>
                        </p>
                      </Content.Item>
                    );
                  })}
                </Content.List>
              ) : null}
            </Content.Cont>
          </Content.LeftCol>
          <Content.RightCol>
            {list.length ? (
              <Content.Head>
                <Content.Tip>{duplicatedList?.isFetching ? '* 새로운 필지 데이터를 불러오는 중입니다.' : '* 필지 하나 당 하나의 사진만 선택해서 등록하세요.'}</Content.Tip>
              </Content.Head>
            ) : null}
            <Content.Cont style={{ height: 'calc(100% - 64px)' }}>
              {list.length ? (
                <>
                  <Content.BjdArea>
                    {list.find(({ active }) => active)?.stdgAddr}
                    <Select option={option} width={220} onChange={onChangeSelect} $borderColor={'#E5E5EC'} />
                  </Content.BjdArea>
                  <Content.ListCont>
                    <Content.PicBox>
                      <Content.PicType>근경</Content.PicType>
                      <Content.Scroll className={'cScroll'}>
                        {imageList.zoomImages.map((item, index) => {
                          return (
                            <ActiveAreaSt.PicCard key={item.imageId} className={item.selected ? 'disabled' : ''} style={{ width: 'auto' }} $height={235}>
                              <ActiveAreaSt.PicCardInner>
                                <ActiveAreaSt.PicBox>
                                  <ActiveAreaSt.PicTitle title={item.fileName}>{item.fileName}</ActiveAreaSt.PicTitle>
                                  <img src={item.thumbnail} alt={item.fileName + '_근경'} />
                                  <Button
                                    className={'selectBtn'}
                                    $buttonType={'medium'}
                                    text={'사진 선택'}
                                    $iconSize={24}
                                    icon={IcoPic}
                                    $iconSpacing={6}
                                    $isCompletion={item.selected}
                                    $iconCompletion={IcoPicCompletion}
                                    onClick={() => onPickImage('Z', index)}
                                  />
                                </ActiveAreaSt.PicBox>
                              </ActiveAreaSt.PicCardInner>
                            </ActiveAreaSt.PicCard>
                          );
                        })}
                      </Content.Scroll>
                    </Content.PicBox>
                    <Content.PicBox>
                      <Content.PicType>원경</Content.PicType>
                      <Content.Scroll className={'cScroll'}>
                        {imageList.wideImages.map((item, index) => {
                          return (
                            <ActiveAreaSt.PicCard key={item.imageId} className={item.selected ? 'disabled' : ''} style={{ width: 'auto' }} $height={235}>
                              <ActiveAreaSt.PicCardInner>
                                <ActiveAreaSt.PicBox>
                                  <ActiveAreaSt.PicTitle title={item.fileName}>{item.fileName}</ActiveAreaSt.PicTitle>
                                  <img src={item.thumbnail} alt={item.fileName + '_원경'} />
                                  <Button
                                    className={'selectBtn'}
                                    $buttonType={'medium'}
                                    text={'사진 선택'}
                                    $iconSize={24}
                                    icon={IcoPic}
                                    $iconSpacing={6}
                                    $isCompletion={item.selected}
                                    $iconCompletion={IcoPicCompletion}
                                    onClick={() => onPickImage('W', index)}
                                  />
                                </ActiveAreaSt.PicBox>
                              </ActiveAreaSt.PicCardInner>
                            </ActiveAreaSt.PicCard>
                          );
                        })}
                      </Content.Scroll>
                    </Content.PicBox>
                  </Content.ListCont>
                  <Content.Foot>
                    <Content.ButtonArea>
                      <Button
                        disabled={selectedMapIndex === 0}
                        $buttonType={'large'}
                        text={'이전사진'}
                        height={36}
                        icon={IcoPrev}
                        $icoPosition={'left'}
                        $iconSize={24}
                        $iconSpacing={6}
                        padding={12}
                        fontSize={14}
                        $iconDisabled={IcoPrevDisabled}
                        style={{ borderRadius: '4px 0 0 4px' }}
                        onClick={onPrev}
                      />
                      <Button
                        disabled={list.find(({ active }) => active)?.mapIds?.length - 1 === selectedMapIndex}
                        $buttonType={'large'}
                        text={'다음사진'}
                        height={36}
                        icon={IcoNext}
                        $icoPosition={'right'}
                        $iconSize={24}
                        $iconSpacing={6}
                        padding={12}
                        fontSize={14}
                        $iconDisabled={IcoNextDisabled}
                        style={{ marginRight: 20, borderRadius: '0 4px 4px 0' }}
                        onClick={onNext}
                      />
                      <Button text={'최종선택'} height={36} padding={12} fontSize={14} onClick={onSubmit} $icoPosition={'right'} $iconSize={24} $iconSpacing={6} icon={IcoSubmit} disabled={isDisabled()} $iconDisabled={IcoSubmitDisabled} />
                    </Content.ButtonArea>
                  </Content.Foot>
                </>
              ) : (
                <NoData text={duplicatedList?.isFetching ? '필지 데이터를 불러오는 중입니다.' : '중복되는 사진을<br/>모두 삭제 처리하였습니다.'} icon={IcoNoData} $iconSize={48} fontSize={16} padding={[79, 79]} />
              )}
            </Content.Cont>
          </Content.RightCol>
        </Content.Wrap>
      }
    />
  );
};

export default PopupDuplicationPic;
