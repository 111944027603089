import React, { Fragment, useEffect, useRef, useState } from 'react';
import { DataEnterAreaSt } from '../_style';
import { useRecoilState, useRecoilValue, useResetRecoilState } from 'recoil';
import { confirmState, loadingState, popupLargeImgState, toastPopState, userInfoState } from 'store/Atoms';
import query from 'hooks/RQuery/utils';
import { formatDate2 } from 'util/global';
import useOnChange from 'hooks/useOnChange';
import Input from 'components/atoms/Input';
import Checkbox from 'components/atoms/Checkbox';
import RoundBadge from 'components/atoms/RoundBadge';
import Button from 'components/atoms/Button';
// import styled from 'styled-components';
import IcoNextLot from 'assets/images/button/ico-next-lot.svg';
import InvalidCropRadioView from './InvalidCropRadioView';
// import IcoPrevData from 'assets/images/cropInfoBox/ico-prev-data.svg';

// 선택된 항목 있을때 데이터 입력하는 영역
function DataEnterArea({ hide, cardData, round, prevSurveyCrops, surveyCrops, surveyDatas, refetchSurveyLots, refetchSurveyData, selectNextCard, onChangeFeatureStatus, isSingleLot }) {
  const userInfo = useRecoilValue(userInfoState);
  const [confirm, setConfirm] = useRecoilState(confirmState);

  const [loading, setLoading] = useRecoilState(loadingState); // 로딩바

  const [checkboxType, setCheckboxType] = useState('radio');
  const [tabNum, setTabNum] = useState(1); // 점유율 | 잔여량
  const [beforeShow, setBeforeShow] = useState(false);

  const [options, setOptions] = useState<any[]>([]); // 비경지,휴경지,시설,경작지 옵션
  const [cropOptions, setCropOptions] = useState<any[]>([]); // 작물 옵션

  const [prevOptions, setPrevOptions] = useState<any[]>([]); // 비경지,휴경지,시설,경작지 옵션(이전회차)
  const [prevCropOptions, setPrevCropOptions] = useState<any[]>([]); // 작물 옵션(이전회차)

  const [newCropOption, setNewCropOption] = useState<any[]>([]); // 작물 옵션(total)
  const [newPrevCropOptions, setNewPrevCropOptions] = useState<any[]>([]); // 작물 옵션(이전회차(total))

  const [totalSum, setTotalSum] = useState<'-' | number>('-'); // 점유율 총합
  const [totalSum2, setTotalSum2] = useState<'-' | number>('-'); // 잔여량 총합

  //   const [form, setForm, onChange] = useOnChange({}); // [점유율] - 작물input
  const [form2, setForm2, onChange2] = useOnChange({}); // [잔여량] - 작물input

  const [beforeTotalSum, setBeforeTotalSum] = useState(0); // 이전 회차 판독 데이터 TotalSum
  const [beforeSurveyCrops, setBeforeSurvey] = useState<any[]>([]); // 이전 회차 판독 데이터
  const [thisSurveyCrops, setThisSurvey] = useState<any[]>([]); // 당회차 판독 데이터 - 잔여량 옵션

  const [defaultOption, setDefaultOption] = useState<any[]>([]); // 당회차 작물코드 서버에서 받은 데이터 (시설물 등)
  const [defaultCropOption, setDefaultCropOption] = useState<any[]>([]); // 당회차 작물코드 서버에서 받은 데이터 (경작지항목)
  const [defaultNewOption, setDefaultNewOption] = useState<any[]>([]);

  const [fillRateDate, setFillRateDate] = useState(''); // 점유율 최종 변경시간
  const [remainDate, setRemainDate] = useState(''); // 잔여량 최종 변경시간
  const [inspectDate, setInspectDate] = useState(''); // 잔여량 최종 변경시간

  const [isValidCropRadio, setIsValidCropRadio] = useState(true); //점유율 라디오 버튼 활성화 유무
  const [isValidSaveBtn, setIsValidSaveBtn] = useState(false); //점유율 판독 가능 여부(저장버튼)

  const [prevCardIds, setPrevCardIds] = useState<any>([]); // 직전에 선택된 카드id정보 저장(목록 변경여부 확인)

  // 점유율 저장
  const { mutate: addFillRate } = query.survey.useAddFillRate();
  // 점유율 일괄 저장 (2가지 API)
  const { mutate: addFillRateMulti } = query.survey.useAddFillRateMulti(); // 미판독 필지들
  const { mutate: addFillRateMultiPrevData } = query.survey.useAddFillRateMultiPrevData(); // 미판독 아닌 필지들 - 직전 값 그대로 저장
  // 검수 완료
  const { mutate: completeInspect } = query.survey.useCompleteInspect();
  // 잔여량 수정
  const { mutate: updateRemain } = query.survey.useUpdateRemain();

  // 초기 필지 개별 판독 데이터 (당회차 thisSurvey, 이전회차 beforeSurvey)
  useEffect(() => {
    // 마지막 선택 카드의 판독 정보
    if (surveyDatas?.length > 0) {
      const firstCardSurveyData = surveyDatas?.[0];

      // 이전회차 판독 정보
      const beforeSurveyCrops = firstCardSurveyData?.beforeSurvey?.crops;
      if (beforeSurveyCrops?.length > 0) {
        let total = 0;
        beforeSurveyCrops?.map((ele) => (total += ele?.fillRate));
        setBeforeTotalSum(total);
        setBeforeSurvey(beforeSurveyCrops);
      } else {
        setBeforeSurvey([]);
      }

      // 당회차 판독 정보 - 잔여량 옵션
      const thisSurveyCrops = firstCardSurveyData?.thisSurvey?.crops;
      if (thisSurveyCrops?.length > 0) {
        setThisSurvey(thisSurveyCrops);
      } else {
        setThisSurvey([]);
      }
      // 최종 변경 시간
      setFillRateDate(formatDate2(firstCardSurveyData?.fillRateModifiedDate));
      setRemainDate(formatDate2(firstCardSurveyData?.remainRateModifiedDate));
      setInspectDate(formatDate2(firstCardSurveyData?.inspectDate));
    } else {
      // ! 카드 정보가 없을때 모두 초기화
      setBeforeSurvey([]);
      setThisSurvey([]);
      setFillRateDate('');
      setRemainDate('');
      setInspectDate('');
    }
  }, [surveyDatas]);

  // setDynamicOption
  useEffect(() => {
    // 당회차 판독 데이터 있을때, 자동으로 체크 및 퍼센트값 입력 함수
    function setDynamicOption(thisSurveyCrops, surveyCrops) {
      let newArr = surveyCrops?.map((ele) => {
        // 이번 회차 판독 데이터에 같은 작물id가 있는지 필터링
        let matching = thisSurveyCrops?.filter((thisCrop) => thisCrop?.surveyCropId === ele?.surveyCropId);
        let isMatching = matching?.length > 0;
        let fillRate = isMatching ? matching[0]?.fillRate : 0; // 점유율
        let percent = isMatching ? matching[0]?.fillRate : null; // input value
        return {
          ...ele,
          text: ele.displayName,
          value: ele.surveyCropId,
          fillRate,
          remainRate: 0,
          percent,
          checked: isMatching,
        };
      });

      setNewCropOption(newArr); // 당회차의 작물정보 저장
    }

    if (cardData?.filter(({ active }) => active)?.length > 1) {
      // 중복 카드일떄 - 작업 X
    } else if (cardData?.filter(({ active }) => active)?.length === 1) {
      // 단일 카드 선택 - 직전 판독값 자동 완성
      if (surveyCrops?.length > 0 && thisSurveyCrops?.length > 0) {
        setDynamicOption(thisSurveyCrops, surveyCrops);
      }
    } else {
      //선택된 카드 없음
    }
  }, [thisSurveyCrops, surveyCrops, cardData]);

  // 작물 정보(cropData) -> option 형태로 리턴
  const getCropOption = (cropData) => {
    if (cropData?.length > 0) {
      return cropData?.map((ele) => ({ ...ele, text: ele.displayName, value: ele.surveyCropId, fillRate: 0, remainRate: 0, percent: null, checked: false }));
    }
  };

  // "당회차"의 작물정보 저장 (이전 판독값은 thisSurvey값이 있는 경우에 하단 dom에서 매칭.'당회차':100)
  useEffect(() => {
    let newCropOption = getCropOption(surveyCrops);

    setDefaultNewOption(newCropOption || []); // 초기화 용
    setNewCropOption(newCropOption || []); // 점유율 옵션용
  }, [surveyCrops]);

  // "이전 회차"의 작물정보 저장 - 이전 회차 데이터 화면에서 사용 (2회차 이상부터 보임)
  useEffect(() => {
    let newCropOption = getCropOption(prevSurveyCrops);

    setNewPrevCropOptions(newCropOption || []);
  }, [prevSurveyCrops]);

  // [점유율] 옵션들 초기화
  const resetCropOptions = async () => {
    if (defaultNewOption?.length > 0) {
      await setNewCropOption(defaultNewOption); // 초기화용 데이터로 셋팅
    }
  };

  // 🔥 카드 데이터 변경시 호출
  useEffect(() => {
    const activeCard = [...cardData].filter(({ active }) => active); // 선택된 카드들만 필터
    const activeCardIds: string[] = activeCard?.map(({ surveyFarmMapId }) => surveyFarmMapId);

    // 두 배열이 같은지 비교하는 함수
    const areArraysEqual = (arr1, arr2) => {
      if (arr1.length !== arr2.length) return false; // 길이가 다르면 reset

      const sortedArr1 = [...arr1].sort();
      const sortedArr2 = [...arr2].sort();
      // id 다른게 있으면 false 반환 (reset)
      // (!) sortedArr2 빈배열이면 항상 true
      return sortedArr2.every((value, index) => value === sortedArr1[index]);
    };

    // 🔥 카드 목록 업데이트 체크 : 직전에 선택된 카드 정보가 바뀌었을때
    if (!areArraysEqual(prevCardIds, activeCardIds)) {
      resetCropOptions(); // 점유율 옵션들 객체 값 초기화 (같은 카드 재선택시 초기화되는 현상 막음)
      setPrevCardIds(activeCardIds); // 이전 카드ids 저장

      // 초기화 관련
      setTabNum(1); // 점유율 탭 활성화
      setBeforeShow(false); // 이전 회차 정보탭 닫기
    }

    // 선택된 카드가 1개일때 (입력창 형태)
    if (activeCard?.length === 1) {
      setCheckboxType('checkbox');
    }
    // 선택된 카드가 2개 이상 (라디오 형태) (only 100%)
    if (activeCard?.length > 1) {
      setCheckboxType('radio');
    }

    // 작물 라디오 버튼 비활성화 조건
    let isValidRadio = true;
    // 조건) 선택된 카드가 2개 이상 & (마지막 선택된 카드가) 미판독 아닌 나머지 경우 (판독,검수완료 등)
    if (activeCard?.length > 1 && activeCard[activeCard?.length - 1]?.status !== 'NONE') {
      isValidRadio = false;
    }
    setIsValidCropRadio(isValidRadio);

    // 이미지 [1,1]개씩 있는 것만 선택되어야 점유율 저장버튼 활성화
    let isValidSaveBtn = false;
    activeCard?.map((ele) => {
      if (ele.imageList?.wideImageList?.length === 1 && ele.imageList?.zoomImageList?.length === 1) {
        isValidSaveBtn = true;
      } else {
        isValidSaveBtn = false;
      }
    });
    setIsValidSaveBtn(isValidSaveBtn);
  }, [cardData, prevCardIds]);

  // [점유율] 전체 총합 구하기
  useEffect(() => {
    let checkedOptions = [...newCropOption.filter((ele) => ele.checked)]; // 선택된 경작지 옵션 + 작물 옵션
    let sum = 0;
    checkedOptions.map((ele) => (sum += Number(ele.percent)));
    if (checkedOptions?.length > 0) {
      setTotalSum(sum);
    } else {
      setTotalSum('-');
    }
  }, [options, newCropOption]);

  // [잔여량] 전체 총합 구하기
  useEffect(() => {
    if (Object.keys(form2)) {
      let sum = 0;
      // Object.entries(form2).forEach(([key, value]) => (sum += Number(value)));
      Object.values(form2).forEach((value) => (sum += Number(value)));

      if (Object.entries(form2)?.length > 0 && Object.values(form2).some((value) => value)) {
        setTotalSum2(sum);
      } else {
        setTotalSum2('-');
      }
    }
  }, [form2]);

  // 점유율 탭 -> 경지 종류 선택 [1차분류]
  const onChangeCheck = (e) => {
    const { type, id, name, value, checked, text } = e.target;
    // 라디오 버튼(한개만 checked)
    if (type === 'radio') {
      // [기타 항목들, 경작지] 옵션
      if (name === 'cropList') {
        setNewCropOption((prev) => {
          return [...prev].map((ele) => (ele.value === value ? { ...ele, checked, percent: '100' } : { ...ele, checked: false, percent: null }));
        });
      }
    }
    // 체크박스 버튼
    if (type === 'checkbox') {
      setNewCropOption((prev) => {
        //   return [...prev].map((ele) => (ele.value === value ? { ...ele, checked, percent: !checked ? null : ele.percent } : ele));
        return [...prev].map((ele) => (ele.value === value ? { ...ele, checked, percent: checked ? '0' : null } : ele));
      });
    }
  };

  // 퍼센트 입력창
  const onChangePercent = (e, optionType) => {
    const { name, value } = e.target;

    if (optionType === 'cropList') {
      setNewCropOption((prev) => {
        let newCrop = [...prev].map((ele) => (ele.text === name ? { ...ele, percent: value } : { ...ele }));
        return newCrop;
      });
    }
  };

  // 저장 버튼 이벤트 [점유율, 잔여량]
  const onClickSave = async () => {
    const activeCard = [...cardData].filter(({ active }) => active); // 선택된 카드들만 필터
    const surveyFarmMapId = activeCard?.[0]?.surveyFarmMapId; // 선택된 카드 id
    const filterStatus = [...activeCard][activeCard?.length - 1]?.status; // 미판독, 판독, 검수완료 중 1

    // 점유율 저장
    if (tabNum === 1) {
      let checkedOptions = [...newCropOption.filter((ele) => ele.checked)]; // 선택된 경작지 옵션 + 작물 옵션

      // 유효성 - 업데이트 할 데이터가 없을때 막기
      // if (checkedOptions?.length === 0) {
      //   setConfirm({
      //     ...confirm,
      //     show: true,
      //     message: '선택된 항목이 없습니다.',
      //     leftText: undefined,
      //     rightText: '확인',
      //     rightFunc: () => {
      //       setConfirm({ ...confirm, show: false });
      //     },
      //   });
      //   return;
      // }

      // 1) 항목별 퍼센트 값 (0인것도 전송)
      const saveSurveyCropFillRates = checkedOptions?.map((ele) => ({ surveyCropId: ele.surveyCropId, fillRate: Number(ele.percent) }));

      /* 일괄 점유율 저장 */
      if (activeCard?.length > 1) {
        const surveyFarmMapIds = activeCard?.map((ele) => ele.surveyFarmMapId); // 선택된 카드들
        const workerName = userInfo?.name;
        const surveyCropFillRateRequest = {
          saveSurveyCropFillRates,
          workerName,
        }; // 작물-점유율 데이터

        // 조건1) 복수카드선택 & 미판독
        if (filterStatus === 'NONE') {
          await addFillRateMulti(
            {
              surveyFarmMapIds,
              surveyCropFillRateRequest,
            },
            {
              onSuccess(data: any, variables, context) {
                console.log(data);
                setConfirm({
                  ...confirm,
                  show: true,
                  rightText: '확인',
                  rightFunc: () => {
                    setConfirm({ ...confirm, show: false });
                    onChangeFeatureStatus(
                      cardData.filter(({ active }) => active),
                      false,
                    );
                    refetchSurveyLots(); // 필지 목록들 재요청
                    refetchSurveyData(); // 판독 정보 재요청
                  },
                  message: data?.message || '[점유율] 일괄 저장 완료',
                });
              },
              onError(error: any, variables, context) {
                console.log(error);
                const errMsg = error?.response?.data?.message;
                setConfirm({
                  ...confirm,
                  show: true,
                  rightText: '확인',
                  rightFunc: () => {
                    setConfirm({ ...confirm, show: false });
                  },
                  message: errMsg || '[점유율] 일괄 저장 실패',
                  isError: true,
                });
              },
            },
          );
        }

        // 조건2) 복수카드선택 & 판독,검수완료
        if (filterStatus !== 'NONE') {
          setLoading({ text: '이전 판독값을 일괄 저장중입니다.', show: true });
          //  console.log('직전 판독 데이터 일괄 저장!');
          await addFillRateMultiPrevData(
            {
              surveyFarmMapIds,
              workerName,
            },
            {
              onSuccess(data: any, variables, context) {
                setLoading({ show: false });
                console.log(data);
                setConfirm({
                  ...confirm,
                  show: true,
                  rightText: '확인',
                  rightFunc: () => {
                    setConfirm({ ...confirm, show: false });
                    onChangeFeatureStatus(
                      cardData.filter(({ active }) => active),
                      false,
                    );
                    refetchSurveyLots(); // 필지 목록들 재요청
                    refetchSurveyData(); // 판독 정보 재요청
                  },
                  message: data?.message || '[점유율] 일괄 저장 완료',
                });
              },
              onError(error: any, variables, context) {
                setLoading({ show: false });
                console.log(error);
                const errMsg = error?.response?.data?.message;
                setConfirm({
                  ...confirm,
                  show: true,
                  rightText: '확인',
                  rightFunc: () => {
                    setConfirm({ ...confirm, show: false });
                  },
                  message: errMsg || '[점유율] 일괄 저장 실패',
                  isError: true,
                });
              },
            },
          );
        }
      }

      /* 개별 점유율 저장 */
      if (activeCard?.length === 1) {
        await addFillRate(
          { surveyFarmMapId, saveSurveyCropFillRates, workerName: userInfo?.name },
          {
            onSuccess(data: any, variables, context) {
              console.log(data);
              setConfirm({
                ...confirm,
                show: true,
                rightText: '확인',
                rightFunc: () => {
                  setConfirm({ ...confirm, show: false });
                  onChangeFeatureStatus(
                    cardData.filter(({ active }) => active),
                    false,
                  );
                  refetchSurveyLots(); // 필지 목록들 재요청
                  refetchSurveyData(); // 판독 정보 재요청
                },
                message: data?.message || '[점유율] 저장 완료',
              });
            },
            onError(error: any, variables, context) {
              console.log(error);
              const errMsg = error?.response?.data?.message;
              setConfirm({
                ...confirm,
                show: true,
                rightText: '확인',
                rightFunc: () => {
                  setConfirm({ ...confirm, show: false });
                },
                message: errMsg || '[점유율] 저장 실패',
                isError: true,
              });
            },
          },
        );
      }
    }

    // 잔여량 저장
    if (tabNum === 2) {
      const activeOptions = Object.keys(form2)
        ?.filter((key) => form2[key])
        ?.map((ele) => ({ name: ele, value: form2[ele] }));

      let updateArr = thisSurveyCrops
        ?.map((ele) => {
          let matched = activeOptions?.find((item) => item.name === ele.displayName);
          if (matched) {
            return { ...ele, updateRemainRate: Number(matched.value) };
          } else {
            return null;
          }
        })
        ?.filter((item) => item !== null);

      const updateSurveyCropRemainRates = updateArr?.map((ele) => ({ surveyCropHistoryId: ele.surveyCropHistoryId, remainRate: Number(ele.updateRemainRate) }));

      // 유효성 - 업데이트 할 데이터가 없을때 막기
      // if (updateArr?.length === 0) {
      //   return;
      // }

      /* 잔여량 저장 */
      await updateRemain(
        { surveyFarmMapId, updateSurveyCropRemainRates, workerName: userInfo?.name },
        {
          onSuccess(data: any, variables, context) {
            console.log(data);
            setConfirm({
              ...confirm,
              show: true,
              rightText: '확인',
              rightFunc: () => {
                // 잔여량은 레이어 변경없음
                // onChangeFeatureStatus(
                //   cardData.filter(({ active }) => active),
                //   2,
                //   false,
                // );
                setConfirm({ ...confirm, show: false });
                refetchSurveyLots(); // 필지 목록들 재요청
                refetchSurveyData(); //판독 정보 재요청
                setForm2({}); //입력창 초기화
              },
              message: data?.message || '[잔여량] 저장 완료',
            });
          },
          onError(error: any, variables, context) {
            console.log(error);
            const errMsg = error?.response?.data?.message;
            setConfirm({
              ...confirm,
              show: true,
              rightText: '확인',
              rightFunc: () => {
                setConfirm({ ...confirm, show: false });
              },
              message: errMsg || '[잔여량] 저장 실패',
              isError: true,
            });
          },
        },
      );
    }
  };

  // 이전회차 점유율 열기
  const onToggleBeforeRound = () => {
    setBeforeShow(!beforeShow);
  };

  //확인완료 버튼
  const onCompleteInspect = () => {
    setConfirm({
      ...confirm,
      show: true,
      message: '확인을 완료하시겠습니까?',
      leftText: '취소',
      leftFunc: () => setConfirm({ ...confirm, show: false }),
      rightText: '확인',
      rightFunc: () => {
        setConfirm({ ...confirm, show: false });
        completeInspect(cardData?.filter(({ active }) => active)[0].surveyFarmMapId, {
          onSuccess: (res: any) => {
            // 검수자 확인완료는 레이어 변경 없음
            // onChangeFeatureStatus(
            //   cardData.filter(({ active }) => active),
            //   3,
            //   false,
            // );
            refetchSurveyLots();
            refetchSurveyData(); //판독 정보 재요청
            //검수 완료 성공 얼럿
            setConfirm({
              ...confirm,
              show: true,
              message: res?.message || '저장되었습니다.',
              leftText: undefined,
              rightText: '확인',
              rightFunc: () => {
                setConfirm({ ...confirm, show: false });
              },
            });
          },
          onError: (err: any) => {
            //검수 완료 실패 얼럿
            setConfirm({ ...confirm, show: true, message: err?.response?.data?.message || '실패. 관리자에게 문의하세요', leftText: undefined, rightText: '확인', isError: true, rightFunc: () => setConfirm({ ...confirm, show: false }) });
          },
        });
      },
    });
  };

  // 경작지 항목 체크 유무
  const isCropChecked = options.find((ele) => ele.text === '경작지')?.checked;

  // 잔여량 탭버튼 disabled : 선택된 카드의 status 확인 후
  const isDisable = cardData?.filter(({ active }) => active)?.[0]?.status === 'NONE' || cardData?.filter(({ active }) => active)?.length > 1 || cardData?.filter(({ active }) => active)?.length === 0; // 미판독상태 | 복수선택 | 카드 없음

  // 잔여량 - 저장 버튼 활성화 유무
  //   const isRemainBtnDisabled =

  // 현재 1회차 인지 체크
  const isRound1 = round.filter(({ active }) => active)[0]?.surveyNo === 1;
  // 이전 회차 숫자
  const activeSurveyNo = round[round.findIndex(({ active }) => active) - 1]?.surveyNo;

  // 확인완료 버튼 활성화
  const isInvalidInspect = surveyDatas?.[0]?.status === 'INSPECT';

  // 이전회차 점유율 영역 보여주기
  const isShowBeforeData = !isRound1 && sessionStorage?.getItem('auth') === 'ROLE_WORKER' && cardData?.filter(({ active }) => active)?.length === 1;

  // 선택된 필지 여러개 + 미판독 이외의 status -> 일괄 저장 기능 상태
  console.log('isValidCropRadio', isValidCropRadio);

  return (
    <DataEnterAreaSt.Contain>
      {isShowBeforeData ? <DataEnterAreaSt.BeforeBtn type={'button'} onClick={onToggleBeforeRound} className={beforeShow ? 'active' : undefined}></DataEnterAreaSt.BeforeBtn> : null}
      <DataEnterAreaSt.Wrap className={hide ? 'hide' : undefined}>
        <DataEnterAreaSt.TabBtnWrap>
          <button className={`tabBtn ${tabNum === 1 && 'active'}`} onClick={() => setTabNum(1)}>
            {sessionStorage.getItem('auth') === 'ROLE_WORKER' ? '점유율' : '판독량'}
          </button>
          <button className={`tabBtn ${tabNum === 2 && 'active'}`} onClick={() => setTabNum(2)} disabled={isDisable}>
            잔여량
          </button>
        </DataEnterAreaSt.TabBtnWrap>
        <DataEnterAreaSt.ReadGrdTypeWrap>
          {/* 점유율 탭 */}
          {tabNum === 1 && (
            <>
              {/*판독자*/}
              {sessionStorage.getItem('auth') === 'ROLE_WORKER' ? (
                /* (이전 판독값 일괄저장 뷰) */
                !isValidCropRadio ? (
                  <InvalidCropRadioView>
                    선택한 필지의
                    <br />
                    <span className="strong">이전 판독값</span>을 일괄 저장합니다.
                  </InvalidCropRadioView>
                ) : (
                  <ul className={'cScroll'} style={{ height: 'calc(100% - 139px)' }}>
                    {newCropOption.map((item, idx) => (
                      <li key={`radio-${idx}`} className="radioItem">
                        <>
                          <Checkbox
                            type={checkboxType}
                            id={`cropList-${item.value}`}
                            name="cropList" // 라디오끼리 같은 name
                            text={item.text}
                            value={item.value}
                            style={checkboxType === 'radio' ? { width: '100%', height: '100%' } : undefined}
                            checked={item?.checked}
                            onChange={onChangeCheck}
                            disabled={checkboxType === 'radio' ? !isValidCropRadio : false}
                          />
                          {/* 라디오일때 */}
                          {checkboxType === 'radio' && <p className={`percent ${item.checked && 'active'}`}>{item.percent || 0}%</p>}

                          {/* 입력일때 */}
                          {checkboxType !== 'radio' && (
                            <>
                              {thisSurveyCrops?.find(({ surveyCropId }) => surveyCropId === item?.surveyCropId) && <p className="value">{`당회차: ${thisSurveyCrops?.find(({ surveyCropId }) => surveyCropId === item?.surveyCropId)?.fillRate}`}</p>}

                              <Input type="number" width={102} height={36} unit="%" placeholder="입력" name={item.text} value={item.percent || ''} onChange={(e) => onChangePercent(e, 'cropList')} disabled={!item.checked} />
                            </>
                          )}
                        </>
                      </li>
                    ))}
                  </ul>
                )
              ) : (
                <>
                  {/*  검수자, 관리자 */}
                  <DataEnterAreaSt.FormWrap className="cScroll" style={{ height: '100%' }}>
                    <ul>
                      {thisSurveyCrops?.map((ele, idx) => (
                        <li className="formItem" key={'경작지Item' + idx}>
                          <div className="labelInput">
                            <p className="label">{ele?.displayName}</p>

                            <div style={{ display: 'flex', alignItems: 'center', gap: '12px' }}>
                              {ele?.fillRate && (
                                <span className="value" style={{ color: ' #025143' }}>
                                  {ele?.fillRate} %
                                </span>
                              )}
                            </div>
                          </div>
                        </li>
                      ))}
                    </ul>
                  </DataEnterAreaSt.FormWrap>
                </>
              )}

              {/* 하단 최종 버튼 영역 */}
              {/*판독자*/}
              {sessionStorage.getItem('auth') === 'ROLE_WORKER' ? (
                <DataEnterAreaSt.SaveWrap>
                  <div className="totalWrap">
                    <div className="titleWrap">
                      <span className="ico"></span>
                      <p className="title">{!isValidCropRadio ? '일괄 저장' : '전체 총합'}</p>
                    </div>
                    <div className="valueWrap">
                      {!isValidCropRadio ? (
                        <></>
                      ) : (
                        <>
                          <p className="value">{totalSum}</p>
                          <span className="unit">{'%'}</span>
                        </>
                      )}
                    </div>
                  </div>
                  {isValidCropRadio && fillRateDate && <p className="lastUpdate">{`최종변경 : ${fillRateDate}`}</p>}
                  <Button width={280} text={!isValidSaveBtn ? '판독 대상 이미지 없음' : '저장'} disabled={!isValidSaveBtn} onClick={onClickSave} />
                </DataEnterAreaSt.SaveWrap>
              ) : (
                /* 검수자, 관리자 */
                <DataEnterAreaSt.SaveWrap>
                  {inspectDate ? <p className="lastUpdate">{`확인완료 : ${inspectDate}`}</p> : isInvalidInspect && <p className="lastUpdate">{'이미 확인이 완료된 필지입니다.'}</p>}

                  {/* 관리자일때 다음필지 버튼 고정 */}
                  {sessionStorage.getItem('auth') === 'ROLE_ADMIN' ? (
                    <Button width={280} text={'다음 필지'} icon={IcoNextLot} $icoPosition="right" $iconSize={20} $iconSpacing={8} onClick={selectNextCard} />
                  ) : !isInvalidInspect ? (
                    /* 필지가 확인완료 상태가 아닐때 */
                    <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                      {!isSingleLot && <Button width={136} text={'건너뛰기'} onClick={selectNextCard} $buttonType="large" />}
                      <Button width={isSingleLot ? 280 : 136} text={'확인완료'} onClick={onCompleteInspect} />
                    </div>
                  ) : /* 필지가 확인완료 상태 */
                  isSingleLot ? (
                    <Button width={280} text={'확인완료'} disabled={true} />
                  ) : (
                    <Button width={280} text={'다음 필지'} icon={IcoNextLot} $icoPosition="right" $iconSize={20} $iconSpacing={8} onClick={selectNextCard} />
                  )}
                </DataEnterAreaSt.SaveWrap>
              )}
            </>
          )}
          {/* 잔여량 탭 */}
          {tabNum === 2 && (
            <>
              <ul>
                <li className="radioItem">{'경작지'}</li>
              </ul>
              {/* 판독자 */}
              {sessionStorage.getItem('auth') === 'ROLE_WORKER' ? (
                <DataEnterAreaSt.FormWrap className="cScroll" $height={'calc(100% - 133px - 49px)'}>
                  <ul>
                    {thisSurveyCrops?.map(
                      (ele, idx) =>
                        ele?.remainUsage ? (
                          <li className="formItem" key={'경작지Item' + idx}>
                            <div className="labelInput">
                              <p className="label">{ele?.displayName}</p>

                              <div style={{ display: 'flex', alignItems: 'center', gap: '12px' }}>
                                {ele?.remainRate && <span className="value">{ele?.remainRate}</span>}
                                <Input type="number" width={102} height={36} unit="%" name={ele?.displayName} value={form2?.[ele?.displayName] || ''} onChange={onChange2} max={ele?.fillRate} placeholder={`최대${ele?.fillRate}`} />
                              </div>
                            </div>
                          </li>
                        ) : null,
                      //  <div>{'항목이 없습니다'}</div>
                    )}
                  </ul>
                </DataEnterAreaSt.FormWrap>
              ) : (
                /* 검수자, 관리자 */
                <DataEnterAreaSt.FormWrap className="cScroll" style={{ height: '100%' }}>
                  <ul>
                    {thisSurveyCrops?.map((ele, idx) =>
                      ele?.remainUsage ? (
                        <li className="formItem" key={'경작지Item' + idx}>
                          <div className="labelInput">
                            <p className="label">{ele?.displayName}</p>

                            <div style={{ display: 'flex', alignItems: 'center', gap: '12px' }}>
                              {ele?.remainRate && (
                                <span className="value" style={{ color: ' #025143' }}>
                                  {ele?.remainRate} %
                                </span>
                              )}
                            </div>
                          </div>
                        </li>
                      ) : null,
                    )}
                  </ul>
                </DataEnterAreaSt.FormWrap>
              )}
              {/* 판독자 */}
              {sessionStorage.getItem('auth') === 'ROLE_WORKER' ? (
                <DataEnterAreaSt.SaveWrap>
                  <div className="totalWrap">
                    <div className="titleWrap">
                      <span className="ico"></span>
                      <p className="title">전체 총합</p>
                    </div>
                    <div className="valueWrap">
                      <p className="value">{totalSum2}</p>
                      <span className="unit">{'%'}</span>
                    </div>
                  </div>
                  {remainDate && <p className="lastUpdate">{`최종변경 : ${remainDate}`}</p>}
                  <Button width={280} text="저장" onClick={onClickSave} />
                </DataEnterAreaSt.SaveWrap>
              ) : (
                /* 검수자, 관리자 */
                <DataEnterAreaSt.SaveWrap>
                  {inspectDate ? <p className="lastUpdate">{`확인완료 : ${inspectDate}`}</p> : isInvalidInspect && <p className="lastUpdate">{'이미 확인이 완료된 필지입니다.'}</p>}

                  {/* 관리자일때 다음필지 버튼 고정 */}
                  {sessionStorage.getItem('auth') === 'ROLE_ADMIN' ? (
                    <Button width={280} text={'다음 필지'} icon={IcoNextLot} $icoPosition="right" $iconSize={20} $iconSpacing={8} onClick={selectNextCard} />
                  ) : !isInvalidInspect ? (
                    /* 필지가 확인완료 상태가 아닐때 */
                    <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                      {!isSingleLot && <Button width={136} text={'건너뛰기'} onClick={selectNextCard} $buttonType="large" />}
                      <Button width={isSingleLot ? 280 : 136} text={'확인완료'} onClick={onCompleteInspect} />
                    </div>
                  ) : /* 필지가 확인완료 상태 */
                  isSingleLot ? (
                    <Button width={280} text={'확인완료'} disabled={true} />
                  ) : (
                    <Button width={280} text={'다음 필지'} icon={IcoNextLot} $icoPosition="right" $iconSize={20} $iconSpacing={8} onClick={selectNextCard} />
                  )}
                </DataEnterAreaSt.SaveWrap>
              )}
            </>
          )}
        </DataEnterAreaSt.ReadGrdTypeWrap>

        {/* 이전회차 점유율 영역 (1회차가 아닐때 & 판독자 웹) */}
        {isShowBeforeData ? (
          <DataEnterAreaSt.BeforeRound className={beforeShow ? 'active' : undefined}>
            <DataEnterAreaSt.TabBtnWrap>
              <p className={'tabBtn active'} style={{ width: '100%', display: 'flex', gap: '6px' }}>
                <RoundBadge num={activeSurveyNo} /> <span>점유율</span>
              </p>
            </DataEnterAreaSt.TabBtnWrap>
            <DataEnterAreaSt.ReadGrdTypeWrap>
              <ul className="cScroll">
                {beforeSurveyCrops?.map((item, idx) => (
                  <li key={`radio-${idx}`} className="radioItem">
                    <p className="labelValue">
                      {item.displayName}
                      <span className="percent"> {item?.fillRate || 0} %</span>
                    </p>
                  </li>
                ))}
              </ul>

              <DataEnterAreaSt.SaveWrap style={{ marginBottom: fillRateDate ? 130 : 100 }}>
                <div className="totalWrap" style={{ margin: 0 }}>
                  <div className="titleWrap">
                    <span className="ico"></span>
                    <p className="title">전체 총합</p>
                  </div>
                  <div className="valueWrap">
                    <p className="value">{beforeTotalSum}</p>
                    <span className="unit">{'%'}</span>
                  </div>
                </div>
              </DataEnterAreaSt.SaveWrap>
            </DataEnterAreaSt.ReadGrdTypeWrap>
          </DataEnterAreaSt.BeforeRound>
        ) : null}
      </DataEnterAreaSt.Wrap>
    </DataEnterAreaSt.Contain>
  );
}

export default DataEnterArea;
