import packageJson from '../../package.json';
// utils/version.ts

export const checkVersionUpdate = async (setIsNewVersion) => {
  if (process.env.NODE_ENV !== 'production') {
    // dev, prod 환경이 아닌 경우 버전 체크 미실행
    return;
  }
  const package_version = packageJson.version;

  const response = await fetch(`/version.txt`, { cache: 'no-store' });

  if (response.ok) {
    const version = await response.text();
    if (version && version.trim() !== package_version.trim()) {
      // Alert 발생
      setIsNewVersion(true);
      // alert('새로운 버전이 업로드되었습니다. 새로고침하세요.');
    }
  } else {
    console.error('Failed to fetch version:', response.status);
  }
};
