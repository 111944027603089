import React, { useRef, useState } from 'react';
import styled from 'styled-components';
import IcoUser from 'assets/images/map/ico-user.svg';
import PopupPasswordChange from '../../../popup/PopupPasswordChange';
import usePopupToggle from '../../../hooks/custom/usePopupToggle';

import IcoPassword from 'assets/images/userSetting/ico-pw-change.svg';
import IcoLogout from 'assets/images/userSetting/ico-logout.svg';
import { useOutsideClick } from 'util/useOutsideClick';
import { useRecoilState, useRecoilValue } from 'recoil';
import { confirmState, userInfoState } from 'store/Atoms';
import Package from '../../../../package.json';

const UserSettingSt = {
  Wrap: styled.div<{ $isAdmin?: boolean }>`
    position: ${(p) => (p.$isAdmin ? 'relative' : 'absolute')};
    z-index: 12;
    ${(p) => !p.$isAdmin && `right:16px;top:16px`};
    ${(p) => p.$isAdmin && `margin-top:auto`}
  `,
  Button: styled.div<{ $isAdmin?: boolean }>`
    /* margin-bottom: 6px; */
    width: 48px;
    height: 48px;
    background-color: #ffffff;
    border: 2px solid #ffffff;
    box-sizing: border-box;
    border-radius: 4px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    ${(p) => p.$isAdmin && `border:1px solid #d9d9d9;`}
    &.active {
      border: 2px solid #00b074;
    }
    .icoUser {
      display: inline-block;
      width: 24px;
      height: 24px;
      background: url(${IcoUser}) no-repeat center;
    }
  `,
  MenuWrap: styled.div<{ $isAdmin?: boolean }>`
    position: absolute;
    ${(p) => (p.$isAdmin ? `bottom:0;left:calc(100% + 10px)` : `top:54px; right:0`)};
    width: 196px;
    border-radius: 4px;
    background-color: #fff;
    box-shadow: 0px 4px 8px 0px #0000004a;
    overflow: hidden;
    /* transform: scaleY(0); */
    /* transform-origin: top; */
    opacity: 0;
    height: 0;
    visibility: hidden;
    transition: all 0.3s ease-in-out;
    &.open {
      opacity: 1;
      /* height: max-content; */
      height: 207px;
      visibility: visible;
      /* transform: scaleY(1); */
    }
    .userInfo {
      box-sizing: border-box;
      padding: 16px 20px;
      border-bottom: 1px solid #d9d9d9;
      //pointer-events: none;
      cursor: default;
      p {
        color: #01555e;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
      }
      .name {
        margin-bottom: 7px;
        font-size: 16px;
        font-weight: 600;
        line-height: 19px;
      }
      .email {
        font-weight: 400;
        line-height: 16px;
      }
    }
    ul {
      li {
        min-height: 44px;
        padding-left: 20px;
        box-sizing: border-box;
        display: flex;
        align-items: center;
        /* justify-content: center; */
        /* flex-direction: column; */
        cursor: pointer;
        p {
          color: #3f3f3f;
          font-weight: 500;
        }
        &:hover {
          transition: all 0.3s;
          background-color: #f1f1f1;
        }
        .ico {
          margin-right: 8px;
          display: block;
          width: 20px;
          height: 20px;
        }
        &.changePw {
          .ico {
            background: url(${IcoPassword}) no-repeat center;
          }
        }
        &.logout {
          .ico {
            background: url(${IcoLogout}) no-repeat center;
          }
        }
      }
    }
    .version {
      background: #eafced;
      color: #00b074;
      font-size: 12px;
      font-weight: 500;
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding: 0 20px;
      height: 44px;
      box-sizing: border-box;
      border-top: 1px solid #d9d9d9;
    }
  `,
};

function UserSetting({ $isAdmin }: { $isAdmin?: boolean }) {
  const userInfo = useRecoilValue(userInfoState);
  const [confirmStatus, setConfirmStatus] = useRecoilState(confirmState);
  const menuRef = useRef(null);
  const [showMenu, setShowMenu] = useState(false); // 유저 메뉴 오픈
  const [popupState, setPopupState] = useState({
    changePassword: false,
  });
  const onTogglePopup = usePopupToggle(popupState, setPopupState);

  // 외부영역 클릭 이벤트 - 유저메뉴닫기
  useOutsideClick(() => setShowMenu(false), menuRef);

  // 로그아웃 버튼 클릭
  const onClickLogOut = () => {
    /* 확인창 오픈 */
    setConfirmStatus((prev) => {
      const leftFunc = () => {
        console.log('취소');
        setConfirmStatus((prev) => {
          return { ...prev, show: false };
        });
      };
      const rightFunc = () => {
        console.log('로그아웃');
        fetchLogout();
        setConfirmStatus((prev) => {
          return { ...prev, show: false };
        });
      };
      return {
        ...prev,
        show: true,
        message: '로그아웃 하시겠습니까?',
        leftText: '취소',
        rightText: '예',
        leftFunc: leftFunc,
        rightFunc: rightFunc,
      };
    });
    /* 로그아웃 로직 */
    function fetchLogout() {
      sessionStorage.removeItem('token');
      sessionStorage.removeItem('memberId');
      sessionStorage.removeItem('companyId');
      sessionStorage.removeItem('auth');
      sessionStorage.removeItem('projectId');
      sessionStorage.removeItem('userInfo'); // recoil통해서 저장된것

      // 사업장 선택화면 이동
      window.location.href = '/';
    }
  };
  return (
    <>
      <UserSettingSt.Wrap ref={menuRef} $isAdmin={$isAdmin}>
        <UserSettingSt.Button $isAdmin={$isAdmin} className={`${showMenu && 'active'}`} onClick={() => setShowMenu(!showMenu)}>
          <span className={`icoUser`}></span>
        </UserSettingSt.Button>

        {/* 유저 메뉴 목록 */}
        <UserSettingSt.MenuWrap className={`${showMenu && 'open'} `} $isAdmin={$isAdmin}>
          <div className="userInfo">
            <p className="name" title={userInfo?.name}>
              {userInfo?.name}
            </p>
            <p className="email" title={userInfo?.email}>
              {userInfo?.email}
            </p>
          </div>
          <ul>
            <li className="changePw" onClick={() => onTogglePopup('changePassword')}>
              <span className="ico"></span>
              <p>비밀번호 변경</p>
            </li>
            <li className="logout" onClick={onClickLogOut}>
              <span className="ico"></span>
              <p>로그아웃</p>
            </li>
          </ul>
          <div className={'version'}>
            <span>버전 정보</span>
            <span>V {Package.version}</span>
          </div>
        </UserSettingSt.MenuWrap>
        {/* 비밀번호 변경 팝업 */}
        {popupState.changePassword ? <PopupPasswordChange onClose={() => onTogglePopup('changePassword')} /> : null}
      </UserSettingSt.Wrap>
    </>
  );
}

export default UserSetting;
