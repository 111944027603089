import React, { ReactNode } from 'react';
import styled from 'styled-components';
import Input, { InputProps, InputStyle } from './Input';

export const LabelInputStyle = {
  Wrap: styled.div<LabelInputProps>`
    display: flex;
    align-items: ${(p) => (p.$alignItem === 'flex-start' ? 'flex-start' : 'center')};
    box-sizing: border-box;
    border-bottom: ${(p) => (p.border ? '1px solid rgba(217, 217, 217, 0.62)' : 'none')};
    padding: ${(p) => (p.padding ? p.padding + 'px 0 ' + p.padding + 'px' : 0)};
  `,
  Key: styled.strong<LabelInputProps>`
    display: flex;
    align-items: center;
    min-width: ${(p) => (p.$labelWidth ? p.$labelWidth + 'px' : '150px')};
    line-height: ${(p) => (p.$labelHeight ? p.$labelHeight + 'px' : '23px')};
    font-size: ${(p) => (p.fontSize ? p.fontSize + 'px' : '16px')};
    font-weight: 600;
    color: #001a21;
    margin-right: 5px;
    margin-top: ${(p) => (p.$alignItem === 'flex-start' ? '13px' : '0')};

    &:after {
      ${(p) => p.required && 'content:"*";color:#00B074;font-size:16px;font-weight:400;margin-left:2px;'}
    }
  `,
  SubText: styled.p`
    font-size: 16px;
    font-weight: 400;
    color: #001a21;
    margin-left: 12px;
  `,
};

export interface LabelInputProps {
  /** 라벨 영역의 너비*/
  $labelWidth?: number;
  /** 라벨 영역의 높이*/
  $labelHeight?: number;
  /** 폰트 크기*/
  fontSize?: number;
  /** input의 type*/
  type?: string;
  /** 컴포넌트 하단 여백*/
  marginbottom?: number;
  /** 라벨의 위치(input 영역의 높이가 클 때 라벨을 상단에 맞출경우만 사용)*/
  $alignItem?: 'flex-start';
  /** 라벨 문자열*/
  label?: string;
  /** input의 value*/
  value?: string | number;
  /** 초기값이 있을 경우 value 대신 입력*/
  defaultValue?: string | number;
  /** 상하 여백이 있을 경우 입력*/
  padding?: number;
  /** 컴포넌트 아래 구분선 필요 여부*/
  border?: boolean;
  /** input의 너비*/
  inputWidth?: number;
  /** input의 높이*/
  inputHeight?: number;
  /** 필수값 여부*/
  required?: boolean;
  /** 값이 없을 때 보여줄 예시 텍스트*/
  placeholder?: string;
  //   onChange?: (e) => void;
  /** input우측에 버튼이 필요한 경우 직접 추가*/
  rightButton?: ReactNode;
  /** 입력값이 에러인지 구분하는 boolean값*/
  $error?: boolean;
  /** 특정 상황(유효성 성공/실패)에 따른 메세지 문자열*/
  message?: string;
  /** 비활성화 여부 */
  disabled?: boolean;
}

/** Label+Input 조합의 컴포넌트*/
const LabelInput = ({
  $labelWidth,
  fontSize,
  type,
  marginbottom,
  $alignItem,
  label,
  value,
  $error,
  padding,
  border,
  inputWidth,
  inputHeight,
  required,
  placeholder,
  onChange,
  defaultValue,
  rightButton,
  message,
  disabled,
  name,
}: LabelInputProps & InputProps) => {
  return (
    <>
      <LabelInputStyle.Wrap marginbottom={marginbottom} $alignItem={$alignItem} border={border} padding={padding}>
        <LabelInputStyle.Key $labelWidth={$labelWidth} fontSize={fontSize} required={required} $alignItem={$alignItem}>
          {label}
        </LabelInputStyle.Key>
        <Input type={type} name={name} width={inputWidth} height={inputHeight} value={value} defaultValue={defaultValue} fontSize={fontSize} placeholder={placeholder} onChange={onChange} $error={$error} message={message} disabled={disabled} />
        {rightButton ? rightButton : null}
      </LabelInputStyle.Wrap>
      {message ? (
        <InputStyle.message $error={$error} left={$labelWidth}>
          {message}
        </InputStyle.message>
      ) : null}
    </>
  );
};

export default LabelInput;
