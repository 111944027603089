/** input 글자수 제한 (onInput이나 onKeyUp 이벤트 등에서 사용)*/
// export function maxLengthCheck(e: React.KeyboardEventHandler<HTMLInputElement> ) {
export function maxLengthCheck(e: any) {
  const { value, type, maxLength } = e.target as HTMLInputElement;

  if (value.length > maxLength) {
    e.target.value = value.slice(0, maxLength);
  }
}

/**
 * Date 포맷을 YYYY-MM-DD 형태로 변환하는 함수
 * @return 'YYYY-MM-DD'
 * */
export function formatDate(date: Date) {
  // 년도
  const year = date.getFullYear();
  // 월 (0부터 시작하므로 +1 필요)
  const month = String(date.getMonth() + 1).padStart(2, '0');
  // 일
  const day = String(date.getDate()).padStart(2, '0');

  return `${year}-${month}-${day}`;
}

/**
 * timestamp형태를 YYYY년 MM월 DD일 오전 HH:mm:ss 형태로 변환하는 함수
 * @param dateString timestamp 형식의 string
 * @return 'YYYY년 MM월 DD일 오전 HH:mm:ss'
 * */
export function formatDate2(dateString: string) {
  if (dateString) {
    // Date 객체 생성
    const date = new Date(dateString);
    // 원하는 형식으로 변환
    const formattedDate = date.toLocaleString('ko-KR', {
      year: 'numeric',
      month: 'long',
      day: 'numeric',
      hour: 'numeric',
      minute: 'numeric',
      hour12: true,
    });
    return formattedDate;
  } else {
    return '';
  }
}

/**
 * timestamp형태를 YYYY-MM-DD 형태나 YYYY-MM-DD HH:mm 형태로 변환하는 함수
 * @param dateString timestamp 형식의 string
 * @param type 'YYYY-MM-DD' | 'YYYY-MM-DD HH:mm'
 * @return 'YYYY-MM-DD' or 'YYYY-MM-DD HH:mm' 형태의 시간값
 * */
export function myFormatDate(dateString, type) {
  if (dateString) {
    // Date 객체 생성
    const date = new Date(dateString);
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, '0'); // 월은 0부터 시작하므로 +1
    const day = String(date.getDate()).padStart(2, '0');

    if (type === 'YYYY-MM-DD') {
      return `${year}-${month}-${day}`;
    }

    if (type === 'YYYY-MM-DD HH:mm') {
      const hours = String(date.getHours()).padStart(2, '0');
      const minutes = String(date.getMinutes()).padStart(2, '0');

      return `${year}-${month}-${day} ${hours}:${minutes}`;
    }
  }
}

/** 숫자 3자리마다 , 찍기
 * @return ex) 1000 => 1,000
 * */
export const formatNumber = (num: number) => {
  return Number(num).toLocaleString();
};

/** 소수점 첫번째 자리 리턴
 * @return ex) 4.216 => 2
 * */
export function getFirstDecimal(number: number) {
  if (Math.floor(number) === number) {
    return 0; // 소수점이 없는 경우 0 반환
  }
  return Math.floor((number * 10) % 10);
}

// 빈 항목 체크(string)
export function getEmptyKeyString(obj) {
  return Object.keys(obj)?.filter((key) => obj[key] === '');
}
// 빈 배열 체크(arr)
export function getEmptyKeyArr(obj) {
  return Object.keys(obj)?.filter((key) => Array.isArray(obj[key]) && obj[key]?.length === 0);
}

// findIndex 말고 마지막 index 찾는 함수
export function findLastIndex(array, predicate) {
  for (let i = array.length - 1; i >= 0; i--) {
    if (predicate(array[i], i, array)) {
      return i;
    }
  }
  return -1; // 조건에 맞는 항목이 없을 경우 -1 반환
}
