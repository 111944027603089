import React, { useEffect, useRef, useState } from 'react';
import PopupTemplate from './PopupTemplate';
import styled from 'styled-components';
import IcoMessage from '../assets/images/popup/ico-message.svg';
import IcoO from '../assets/images/popup/ico-o.svg';
import IcoX from '../assets/images/popup/ico-x.svg';
import Table from '../components/atoms/Table';
import query from '../hooks/RQuery/utils';
import PagingTable from '../components/atoms/PagingTable';
import { formatDate, formatDate2, formatNumber, myFormatDate } from 'util/global';
import NoData from '../components/atoms/NoData';
import IcoNoLot from '../assets/images/noData/ico-no-lot.svg';
import { useRecoilState } from 'recoil';
import { confirmState, loadingState, projectDetailState, stdgCdState } from 'store/Atoms';
import Button from 'components/atoms/Button';
import IcoFileUpload from 'assets/images/button/ico-file-upload.svg';
import IcoSortArrow from 'assets/images/table/ico-table-sort-arrow.svg';
import IcoSortArrowH from 'assets/images/table/ico-table-sort-arrow-hover.svg';
import { useOutsideClick } from 'util/useOutsideClick';
import Checkbox from 'components/atoms/Checkbox';

const Content = {
  Wrap: styled.div`
    border-top: 1px solid #dfdfdf;
  `,
  Inner: styled.div`
    padding: 16px 0 18px;
  `,
  Head: styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0 0 0 16px;
    height: 40px;
    border-radius: 4px;
    box-sizing: border-box;
    border: 1px solid #d9d9d9;
    margin-bottom: 6px;
    background: #fff;
    strong {
      font-size: 14px;
      font-weight: 500;
      color: #00b074;
      margin-right: auto;
    }
    span {
      font-size: 14px;
      font-weight: 600;
      color: #024751;
    }
    &:before {
      content: '';
      width: 16px;
      height: 16px;
      margin-right: 6px;
      background: url(${IcoMessage}) no-repeat center;
    }
  `,
  Cont: styled.div``,
  CenterBox: styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 17px 0 18px;
  `,
  QuickBtn: styled.button`
    margin-left: 20px;
    height: 21px;
    padding: 0 6px;
    display: flex;
    align-items: center;
    border: 1px solid #b6e2bf;
    border-radius: 2px;
    color: #047857;
    font-weight: 600;
    font-size: 11px;
    cursor: pointer;
    &:hover {
      transition: all 0.3s;
      background: #b6e2bf;
    }
  `,
  SortBtnWrap: styled.div`
    position: relative;
  `,
  SortBtn: styled.div`
    display: flex;
    align-items: center;
    gap: 2px;
    .icoArrow {
      display: inline-block;
      width: 16px;
      height: 16px;
      background: url(${IcoSortArrow}) no-repeat center;
      &:hover {
        transition: all 0.2s;
        background-image: url(${IcoSortArrowH});
      }
    }
  `,
  SortList: styled.ul`
    position: absolute;
    top: 24px;
    left: 24px;
    width: 96px;
    border: 1px solid #b6e2bf;
    border-radius: 4px;
    overflow: hidden;
    height: 0;
    visibility: hidden;
    transition: all 0.2s ease;
    &.open {
      height: 111px;
      visibility: visible;
    }
    li {
      height: 37px;
      line-height: 37px;
      background: #fff;
      &.active {
        background: #d1f1e6;
      }
      &:hover {
        cursor: pointer;
        background: #d1f1e6;
        font-weight: 600;
      }
    }
  `,
};

const LabelStyle = {
  Text: styled.p<{ count?: number }>`
    margin-left: 20px;
    width: fit-content;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0 9px;
    height: 24px;
    box-sizing: border-box;
    border-radius: 4px;
    //color: #fff;
    text-indent: 0;
    /* background: ${(p) => (p.count === 0 ? '#ececec' : p.count === 1 ? '#EDF7DF' : '#FFF0EA')};
    color: ${(p) => (p.count === 0 ? '#3f3f3f' : p.count === 1 ? '#047857' : '#')}; */
    /* background: #edf7df; */
    color: #a5a5a5;
    &.complete {
      color: #047857;
    }
    &.error {
      color: #e36328;
    }
  `,
};

const PopupResult = ({ onClose, round }) => {
  const sortBtnRef = useRef(null);
  const projectId = sessionStorage.getItem('projectId') as string;
  const surveyId = round.filter(({ active }) => active)[0]?.value; // 현재 선택된 회차 id
  const [stdgCds, setStdgCds] = useState<any>([]); // shp 파일 다운로드 할 필지 법정동 코드

  const [stdgCdInfo, setStdgCdInfo] = useRecoilState(stdgCdState); // 현재 지도 선택된 주소 정보

  const sortSteps = ['origin', 'desc', 'asc']; // '최근업로드날짜' sort 종류
  const [isSorted, setIsSorted] = useState('origin'); // desc , asc
  const [showSort, setShowSort] = useState(false);
  const [originData, setOriginData] = useState<any>([]);
  const [tableData, setTableData] = useState<any>([]);
  const [isAllChecked, setIsAllChecked] = useState(false); // 전체 선택 여부

  const [totalPages, setTotalPages] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const [selProjectId, setSelProjectId] = useState<string>('');
  const [loading, setLoading] = useRecoilState(loadingState);
  const [confirm, setConfirm] = useRecoilState(confirmState);
  const list = query.survey.useUploadImages({
    surveyId,
    //   size: 10,
    //   pageNum: currentPage
  });
  //   const downUrl = query.project.useGetShp(selProjectId);
  const downShpUrl = query.survey.useGetImageShp({ surveyId, stdgCds }); // shp 파일 다운로드 url (법정동 코드 필요)

  // 외부영역 클릭 이벤트 - 주소 필터 닫기
  useOutsideClick(() => setShowSort(false), sortBtnRef);

  useEffect(() => {
    if (list.data) {
      // setTableData(list.data.datas.content);
      // setTotalPages(list.data.datas.totalPages);
      const newArr = list.data.datas?.map((ele) => ({ ...ele, checked: false }));
      setTableData(newArr);
      setOriginData(newArr);
    }
  }, [list.data]);

  const onClickSort = (type) => {
    setIsSorted(type);
    if (type === 'origin') {
      setTableData(originData);
    }
    if (type === 'desc') {
      setTableData(sortByDate('desc'));
    }
    if (type === 'asc') {
      setTableData(sortByDate('asc'));
    }
  };

  function sortByDate(type) {
    // 날짜를 파싱하고 null 값을 처리하는 함수
    function parseDate(item) {
      return item.imageUploadDate ? new Date(item.imageUploadDate) : null;
    }
    // 정렬 함수
    return [...tableData].sort((a, b) => {
      const dateA = parseDate(a);
      const dateB = parseDate(b);

      // dateA 또는 dateB가 null인 경우 처리
      if (dateA === null && dateB === null) return 0;
      if (dateA === null) return 1;
      if (dateB === null) return -1;

      // 날짜가 있는 경우 비교 (내림차순)
      if (type === 'desc') {
        return Number(dateB) - Number(dateA);
      } else {
        // (오름차순)
        return Number(dateA) - Number(dateB);
      }
    });
  }

  const onClickQuickBtn = (item) => {
    setStdgCdInfo({ code: String(item?.stdgCd), address: item?.stdgAddr, dynamic: true });
    onClose();
  };

  // 전체 선택
  const onClickAllCheck = (e) => {
    const { checked } = e.target;
    setIsAllChecked(checked);

    let newArr = [...tableData]?.map((ele) => ({ ...ele, checked }));
    let newCdArr = newArr?.filter(({ checked }) => checked)?.map((ele) => ele.stdgCd);
    setTableData((prev) => [...prev]?.map((ele) => ({ ...ele, checked })));
    setStdgCds(newCdArr);
  };

  // 필지 주소 개별 선택
  const onClickCheck = (e) => {
    const { id, checked } = e.target;
    let newArr = [...tableData]?.map((ele) => (Number(ele.stdgCd) === Number(id) ? { ...ele, checked } : ele));
    let newCdArr = newArr?.filter(({ checked }) => checked)?.map((ele) => ele.stdgCd);
    setTableData(newArr);
    setStdgCds(newCdArr);
  };

  const onClickDownShp = () => {
    // 필수 항목 체크 - 법정동 코드
    if (stdgCds?.length === 0) {
      // 주소를 1개 이상 선택하세요.
      setConfirm({ ...confirm, show: true, message: '주소를 1개 이상 선택하세요.', leftText: undefined, rightText: '확인', rightFunc: () => setConfirm({ ...confirm, show: false }) });
      return;
    }

    setLoading({ show: true, text: '네트워크 상황에 따라 시간이 다소 소요될 수 있습니다.' });
    // 다운로드 url 주소 요청
    downShpUrl
      .refetch()
      .then((res: any) => {
        const url = res.data;
        // url 있을때
        if (url) {
          const down = document.createElement('a');
          down.href = url;
          down.click();
          down.remove();

          setLoading({ show: false }); // 로딩바 제거
        } else {
          setConfirm({ ...confirm, show: true, message: '다운로드 실패. 관리자에게 문의하세요.', leftText: undefined, rightText: '확인', rightFunc: () => setConfirm({ ...confirm, show: false }) });

          setLoading({ show: false }); // 로딩바 제거
        }
      })
      .catch((error) => {
        setConfirm({ ...confirm, show: true, message: '다운로드 실패. 관리자에게 문의하세요.', leftText: undefined, rightText: '확인', rightFunc: () => setConfirm({ ...confirm, show: false }) });

        setLoading({ show: false }); // 로딩바 제거
      });
  };

  // header에 선택된 회차가 현재 진행중 회차인지 확인
  const isProgressSurvey = round.filter(({ active }) => active)[0]?.surveyStatus === 'IN_PROGRESS';

  const sortText = (type) => (type === 'origin' ? '기본데이터' : type === 'desc' ? '내림차순' : '오름차순');

  return (
    <PopupTemplate
      title={'사진 결과 보기'}
      width={942}
      $type={3}
      isDimmed={true}
      onClose={onClose}
      content={
        <Content.Wrap>
          <Content.Inner>
            <Content.Head>
              <strong>전체 필지의 사진 유무 및 업로드 결과는 다음과 같습니다.</strong>
              {isProgressSurvey && <Button text="누락 SHP 파일 다운로드" $buttonType="medium" icon={IcoFileUpload} $icoPosition="left" $iconSize={20} $iconSpacing={6} height={40} onClick={onClickDownShp} />}
            </Content.Head>
            <Content.Cont>
              <Table
                height={439}
                colgroup={[40, 'auto', 115, 115, 115, 150, 120]}
                thead={
                  <tr>
                    <th scope={'col'} className="noBorder">
                      <Checkbox id={'result_all'} checked={isAllChecked} onChange={onClickAllCheck} />
                    </th>
                    <th scope={'col'} style={{ textIndent: '10px' }}>
                      주소
                    </th>
                    <th scope={'col'}>전체 필지 개수</th>
                    <th scope={'col'}>정상 필지 개수</th>
                    <th scope={'col'}>중복 필지 개수</th>
                    <th
                      scope={'col'}
                      //   onClick={onClickSort}
                    >
                      <Content.SortBtnWrap ref={sortBtnRef}>
                        <Content.SortBtn onClick={() => setShowSort(!showSort)}>
                          <p>최근 업로드 날짜</p>
                          <span className="icoArrow" style={{ cursor: 'pointer' }}></span>
                        </Content.SortBtn>

                        <Content.SortList className={showSort ? 'open' : 'hide'}>
                          {sortSteps.map((type) => (
                            <li key={'sort' + type} className={isSorted === type ? 'active' : ''} onClick={() => onClickSort(type)}>
                              {sortText(type)}
                            </li>
                          ))}
                        </Content.SortList>
                      </Content.SortBtnWrap>
                    </th>
                    <th scope={'col'}>필지 바로가기</th>
                  </tr>
                }
                tbody={
                  <>
                    {tableData?.length > 0 ? (
                      tableData?.map((i, idx) => (
                        <tr key={`lot_result_list_${idx}`}>
                          <td>
                            <Checkbox id={`${i.stdgCd}`} checked={i.checked} onChange={onClickCheck} />
                          </td>
                          <td style={{ textIndent: '10px' }}>{i.stdgAddr}</td>
                          {/* <td>전북특별자치도 전주시 완산구 중화산동2가</td> */}
                          <td>
                            <LabelStyle.Text className={i.duplicateCount > 0 ? 'error' : i.completeCount === i.totalCount ? 'complete' : ''}>{formatNumber(i.totalCount)}</LabelStyle.Text>
                          </td>
                          <td>
                            <LabelStyle.Text className={i.duplicateCount > 0 ? 'error' : i.completeCount === i.totalCount ? 'complete' : ''}>{formatNumber(i.completeCount)}</LabelStyle.Text>
                          </td>
                          <td>
                            <LabelStyle.Text className={i.duplicateCount > 0 ? 'error' : i.completeCount === i.totalCount ? 'complete' : ''}>{formatNumber(i.duplicateCount)}</LabelStyle.Text>
                          </td>
                          <td>{myFormatDate(i.imageUploadDate, 'YYYY-MM-DD HH:mm') || '-'}</td>
                          <td>
                            <Content.QuickBtn onClick={() => onClickQuickBtn(i)}>{'지도 바로가기 >'}</Content.QuickBtn>
                          </td>
                        </tr>
                      ))
                    ) : (
                      <tr>
                        <td colSpan={6}>
                          <NoData text={'판독 가능한 필지가 없습니다.'} icon={IcoNoLot} color={'#3F3F3F'} />
                        </td>
                      </tr>
                    )}
                  </>
                }
              />
              {/* <PagingTable
              height="450px"
              type={2}
              currentPage={currentPage}
              setCurrentPage={setCurrentPage}
              caption={'전체 결과'}
              totalPages={totalPages}
              thead={
                <tr>
                  <th scope={'col'}>팜맵 ID</th>
                  <th scope={'col'}>근경 개수</th>
                  <th scope={'col'}>원경 개수</th>
                  
                </tr>
              }
              tbody={
                <>
                 
                  {tableData?.map((i) => {
                    return (
                      <tr key={i.mapId}>
                        <td>{i.mapId}</td>
                        <td>
                          <LabelStyle.Text count={i.zoomImageCount}>{i.zoomImageCount}</LabelStyle.Text>
                        </td>
                        <td>
                          <LabelStyle.Text count={i.wideImageCount}>{i.wideImageCount}</LabelStyle.Text>
                        </td>
                      </tr>
                    );
                  })}
                </>
              }
            /> */}
            </Content.Cont>
          </Content.Inner>
        </Content.Wrap>
      }
    />
  );
};

export default PopupResult;
