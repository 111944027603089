import React, { useEffect, useState } from 'react';
import './App.scss';
import Container from 'components/templates/Container/Container';
import { createBrowserRouter, RouterProvider, Navigate } from 'react-router-dom';
import Login, { LoginForm, SelectSite } from 'pages/Login';
import NotFound from 'pages/NotFound';

import WorkPlaceList from './pages/admin/WorkPlace/WorkPlaceList';
import WorkPlaceAdd from './pages/admin/WorkPlace/WorkPlaceAdd';
import Map from 'pages/Map';
import ClientList from './pages/admin/Client/ClientList';
import ClientAdd from './pages/admin/Client/ClientAdd';
import ClientDetail from './pages/admin/Client/ClientDetail';
import InspectorList from './pages/admin/Inspector/InspectorList';
import InspectorDetail from './pages/admin/Inspector/InspectorDetail';
import ReaderList from './pages/admin/reader/ReaderList';
import ReaderDetail from './pages/admin/reader/ReaderDetail';
import WorkPlaceDetail from './pages/admin/WorkPlace/WorkPlaceDetail';
import Step3 from 'pages/admin/WorkPlace/DetailStep/Step3';
import Step1 from 'pages/admin/WorkPlace/DetailStep/Step1';
import Step2 from 'pages/admin/WorkPlace/DetailStep/Step2';
import Confirm from './components/templates/Confirm';
import { useRecoilState, useRecoilValue } from 'recoil';
import { confirmState } from './store/Atoms';
import UnderMaintenance from './components/templates/UnderMaintenance';
import { MutationCache, QueryCache, QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { ReactQueryDevtools } from '@tanstack/react-query-devtools';
import axios from 'axios';
import Notice from './components/templates/Notice';

function App() {
  const [isDisabledToken, setIsDisabledToken] = useState(false);
  const queryClient = new QueryClient({
    defaultOptions: {
      queries: {
        retry: false,
        refetchOnWindowFocus: false,
        refetchOnMount: true,
        // staleTime: 5000,
        // gcTime: Infinity,
        gcTime: 0,
      },
    },
    queryCache: new QueryCache({
      onError(error: any, query) {
        //   console.log(query.meta);
        let status = error?.response?.status;
        // 권한 없음
        if (status === 401) {
          setIsDisabledToken(true);
        }
      },
    }),
    mutationCache: new MutationCache({}),
  });
  /*  --------------------- */

  // 서버 점검상태 여부
  const [isMaintenanceMode, setIsMaintenanceMode] = useState(false);

  // 공지사항 리스트
  const [notice, setNotice] = useState<any>([]);

  //다시보지않기
  const [noRepeat, setNoRepeat] = useState(false);

  //팝업
  const [popupState, setPopupState] = useState({
    notice: true,
  });

  //공지사항 호출
  useEffect(() => {
    const str = localStorage.getItem('ignore')!;
    const ignoreIds = str?.split(',').filter((i) => i !== '');
    const noticeData = async () => {
      const { data } = await axios.get('https://hhj0tcbqp4.execute-api.ap-northeast-2.amazonaws.com/prod/notice');
      return data;
    };
    noticeData().then(({ datas }) => {
      const newArr: any = [];
      datas.map((i) => {
        newArr.push({ id: i.notice_id, title: i.title, content: i.content, order: i.notice_order, date: i.maintenance_time, time: i.expected_time, show: !ignoreIds?.includes(i.notice_id) });
      });
      setNotice(newArr);
      setIsMaintenanceMode(!!datas.filter(({ notice_order }) => notice_order === 0).length);
    });
  }, []);

  //팝업 닫기
  const onClose = () => {
    //다시보지 않기 체크시
    if (noRepeat) {
      let str = '';
      notice.map((i) => {
        str = i.id + ',' + str;
      });
      localStorage.setItem('ignore', str);
    }
    //팝업 닫기
    setPopupState({ ...popupState, notice: false });
  };

  /*  --------------------- */
  // 1) 토큰 확인 함수
  const isAuthenticated = () => {
    const token = sessionStorage.getItem('token');
    return !!token;
  };

  // 2) 프로젝트 선택 확인 함수
  const isProjectId = () => {
    const projectId = sessionStorage.getItem('projectId');
    return !!projectId;
  };

  // 1단계) 인증정보가 없을때 보호된 컴포넌트
  const Step1ProtectedRoute = ({ element }) => {
    if (!isAuthenticated()) {
      return <Navigate to={`/login`} replace />;
    }
    return element;
  };

  // 2단계) 선택된 프로젝트가 있을때 보호된 컴포넌트
  const Step2ProtectedRoute = ({ element }) => {
    if (!isProjectId()) {
      return <Navigate to={`/login/selectSite`} replace />;
    }
    return element;
  };

  // 인증정보 + 선택된 프로젝트가 있을때 - 볼 수 없는 컴포넌트
  const PublicRoute = ({ element }) => {
    if (isAuthenticated() && isProjectId()) {
      return <Navigate to={`/`} replace />;
    }
    return element;
  };

  // --------------------- //

  // 권한 관리
  const getUserRole = () => {
    return sessionStorage.getItem('auth') || ''; // 예: ROLE_MV, ROLE_ADMIN, ROLE_INSPECTOR, ROLE_WORKER
  };

  const RoleProtectedRoute = ({ allowedRoles, element }) => {
    const role = getUserRole();
    if (!isAuthenticated()) {
      return <Navigate to="/login" replace />;
    }
    if (!allowedRoles?.includes(role)) {
      return <Navigate to="/unauthorized" replace />;
    }
    return element;
  };

  const router = createBrowserRouter([
    {
      path: '/',
      element: <Step1ProtectedRoute element={<Container getUserRole={getUserRole} isDisabledToken={isDisabledToken} />} />, // 2단계 인증 완료전 접근 불가
      children: [
        {
          path: '',
          element: <Map />,
        },
        {
          path: '/map',
          element: <Map />,
        },
        {
          path: '/workPlace',
          element: null,
          children: [
            { path: '', element: <RoleProtectedRoute allowedRoles={['ROLE_ADMIN']} element={<WorkPlaceList />} /> },
            { path: 'add', element: <RoleProtectedRoute allowedRoles={['ROLE_ADMIN']} element={<WorkPlaceAdd />} /> },
            // { path: '', element: <WorkPlaceList /> },
            // { path: 'add', element: <WorkPlaceAdd /> },
            // { path: 'detail', element: <WorkPlaceDetail /> },
            {
              path: 'detail',
              element: <RoleProtectedRoute allowedRoles={['ROLE_ADMIN']} element={<WorkPlaceDetail />} />,
              children: [
                { path: '', element: <RoleProtectedRoute allowedRoles={['ROLE_ADMIN']} element={<Step1 />} /> },
                { path: 'worker', element: <RoleProtectedRoute allowedRoles={['ROLE_ADMIN']} element={<Step2 />} /> },
                { path: 'round', element: <RoleProtectedRoute allowedRoles={['ROLE_ADMIN']} element={<Step3 />} /> },
              ],
            },
            // {
            //   path: 'detail',
            //   element: <WorkPlaceDetail />,
            //   children: [
            //     { path: '', element: <Step1 /> },
            //     { path: 'worker', element: <Step2 /> },
            //     { path: 'round', element: <Step3 /> },
            //   ],
            // },
          ],
        },
        {
          path: '/client',
          element: null,
          children: [
            { path: '', element: <RoleProtectedRoute allowedRoles={['ROLE_ADMIN']} element={<ClientList />} /> },
            {
              path: 'add',
              element: <RoleProtectedRoute allowedRoles={['ROLE_ADMIN']} element={<ClientAdd />} />,
            },
            {
              path: 'detail/:id',
              element: <RoleProtectedRoute allowedRoles={['ROLE_ADMIN']} element={<ClientDetail />} />,
            },
          ],
        },
        {
          path: '/inspector',
          element: null,
          children: [
            { path: '', element: <RoleProtectedRoute allowedRoles={['ROLE_ADMIN']} element={<InspectorList />} /> },
            {
              path: 'detail',
              element: <RoleProtectedRoute allowedRoles={['ROLE_ADMIN']} element={<InspectorDetail />} />,
            },
          ],
        },
        {
          path: '/reader',
          element: null,
          children: [
            { path: '', element: <RoleProtectedRoute allowedRoles={['ROLE_ADMIN']} element={<ReaderList />} /> },
            {
              path: 'detail',
              element: <RoleProtectedRoute allowedRoles={['ROLE_ADMIN']} element={<ReaderDetail />} />,
            },
          ],
        },
      ],
    },
    {
      path: 'login',
      element: <PublicRoute element={<Login isMaintenanceMode={isMaintenanceMode} />} />, // 로그아웃 전에 접근 불가
      children: [
        {
          path: '',
          element: <LoginForm />,
          errorElement: <div>로그인 폼</div>,
        },
        {
          path: 'selectSite',
          element: <Step1ProtectedRoute element={<SelectSite />} />, // 1단계 인증 완료전 접근 불가
          errorElement: <div>현장 선택 영역</div>,
        },
      ],
    },
    {
      path: '*', // 모든 다른 경로는 404 페이지로 처리
      element: <NotFound />,
    },
  ]);
  ////TEST!!!

  return (
    <>
      <div style={{ position: 'fixed', left: 0, top: 0, width: 0, height: 0, overflow: 'hidden' }}>
        <input type={'text'} id={'form_id'} />
        <input type={'password'} id={'form_password'} />
      </div>
      {/*<UnderMaintenance />*/}
      {popupState.notice && !!notice.filter(({ show }) => show).length ? <Notice isMaintenanceMode={isMaintenanceMode} onClose={onClose} notice={notice} noRepeat={noRepeat} setNoRepeat={setNoRepeat} /> : null}
      <QueryClientProvider client={queryClient}>
        <ReactQueryDevtools initialIsOpen={false} />
        <RouterProvider router={router} />
      </QueryClientProvider>
    </>
  );
}

export default App;
