import styled from 'styled-components';
import IcoPrevData from 'assets/images/cropInfoBox/ico-prev-data.svg';

const InvalidViewSt = {
  Wrap: styled.div`
    width: 100%;
    height: 100%;
    background-color: #f6f6f6;
    display: flex;
    justify-content: center;
  `,
  Content: styled.div`
    margin-top: 60px;
    width: fit-content;
    height: fit-content;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 12px;
    > .ico {
      width: 40px;
      height: 40px;
      background: url(${IcoPrevData}) no-repeat center;
    }
    > p {
      text-align: center;
      line-height: 21px;
      font-weight: 500;
      .strong {
        color: #00b074;
      }
    }
  `,
};

/* 
   DataEnterArea 컴포넌트에서 판독정보 입력창 대신 보여줄 문구
*/
function InvalidCropRadioView({ children }) {
  return (
    <InvalidViewSt.Wrap>
      <InvalidViewSt.Content>
        <div className="ico"></div>
        <p>
          {/* 선택한 필지의
           <br />
           <span className="strong">이전 판독값</span>을 일괄 저장합니다. */}
          {children}
        </p>
      </InvalidViewSt.Content>
    </InvalidViewSt.Wrap>
  );
}

export default InvalidCropRadioView;
