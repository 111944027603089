import { useQuery } from '@tanstack/react-query';
import queryKeys from '../keys/queryKeys';
import api from '../service';
import useMutateHook from 'hooks/useMutateHook';
import { useQueryState } from 'hooks/useQueryState';
import { completeInspect, getDuplicatedImages, getDuplicatedImagesDetail, getMapData } from '../service/survey';

//관리자 - 사업회차 작물코드 조회
export function useGetCrops(surveyId) {
  return useQueryState({
    queryKey: [queryKeys.surveyKeys.crops(surveyId)],
    queryFn: () => api.survey.getSurveyCrop(surveyId),
    enabled: !!surveyId,
  });
}
//관리자 - 사업회차 작물코드 추가
export function useAddCrops({ surveyId }) {
  return useMutateHook({
    fetchApi: ({ surveyId, saveSurveyCrops }) => api.survey.addSurveyCrop({ surveyId, saveSurveyCrops }),
    invalidQuery: [[queryKeys.surveyKeys.crops(surveyId)]],
  });
}
//관리자 - 사업회차 작물코드 삭제
export function useDeleteCrops({ surveyId }) {
  return useMutateHook({
    fetchApi: ({ deleteSurveyCropIds }) => api.survey.deleteSurveyCrop({ deleteSurveyCropIds }),
    invalidQuery: [[queryKeys.surveyKeys.crops(surveyId)]],
  });
}

//관리자 - 사업회차 작물코드 명칭 수정
export function useUpdateCrops({ surveyId }) {
  return useMutateHook({
    fetchApi: ({ updateSurveyCrops }) => api.survey.updateSurveyCrop({ updateSurveyCrops }),
    invalidQuery: [[queryKeys.surveyKeys.crops(surveyId)]],
  });
}

//관리자- 사업회차 작물코드 레벨 수정
export function useUpdateCropLevel({ surveyId }) {
  return useMutateHook({
    fetchApi: ({ updateSurveyCrops }) => api.survey.updateCropLevel({ updateSurveyCrops }),
    invalidQuery: [[queryKeys.surveyKeys.crops(surveyId)]],
  });
}

//관리자 - 사업회차 필지 추가조건 목록
export function useGetConditions(surveyId) {
  return useQueryState({
    queryKey: [queryKeys.surveyKeys.getCondition(surveyId)],
    queryFn: () => api.survey.getSurveyConditions(surveyId),
    enabled: typeof surveyId === 'string',
  });
}

//관리자 - 사업회차 필지 추가조건 추가
export function useAddConditions({ surveyId, list }) {
  return useMutateHook({
    fetchApi: () => api.survey.addSurveyConditions({ surveyId, list }),
    invalidQuery: [[queryKeys.surveyKeys.getCondition(surveyId)]],
  });
}

//관리자 - 사업회차 필지 추가조건 삭제
export function useRemoveConditions({ surveyId, list }) {
  return useMutateHook({
    fetchApi: () => api.survey.removeSurveyConditions({ surveyId, list }),
    invalidQuery: [[queryKeys.surveyKeys.getCondition(surveyId)]],
  });
}

//지도 호출
export function useMapData({ surveyId, stdgCd }) {
  return useQueryState({
    queryKey: [queryKeys.surveyKeys.map(surveyId, stdgCd)],
    queryFn: () => getMapData({ surveyId, stdgCd }),
    enabled: !!surveyId && !!stdgCd,
  });
}

//회차별 중복이미지 목록
export function useDuplicatedImages(surveyId) {
  return useQueryState({
    queryKey: [queryKeys.surveyKeys.duplicatedImage(surveyId)],
    queryFn: () => getDuplicatedImages(surveyId),
    enabled: !!surveyId,
  });
}

//회차별 이미지 상세보기
export function useDuplicatedImagesDetail(surveyFarmMapId) {
  return useQueryState({
    queryKey: [queryKeys.surveyKeys.duplicatedImageDetail(surveyFarmMapId)],
    queryFn: () => getDuplicatedImagesDetail(surveyFarmMapId),
    enabled: !!surveyFarmMapId,
  });
}

//관리자 - 사업회차 상태 변경 (예 : READY -> )
export function useUpdateSurveyStatus({ projectId }) {
  return useMutateHook({
    fetchApi: ({ surveyId, surveyStatus }) => api.survey.updateSurveyStatus({ surveyId, surveyStatus }),
    invalidQuery: [[queryKeys.projectKeys.detail(projectId)]],
  });
}

// 회차별 필지 조회 - 목록(paging)
export function useGetLots({ initReady, surveyId, stdgCd, clsfNms, surveyCropIds, status, remainStatus, inspectStatus, pageNum, size, isSingleLot }) {
  return useQueryState({
    queryKey: [queryKeys.surveyKeys.lots({ surveyId, pageNum, stdgCd, surveyCropIds })],
    queryFn: () => api.survey.getLots({ surveyId, stdgCd, clsfNms, surveyCropIds, status, remainStatus, inspectStatus, pageNum, size }),
    enabled: !!initReady && !!surveyId && !isSingleLot,
  });
}

// 회차별 필지 조회 - 단일
export function useGetSingleLot({ surveyId, surveyFarmMapId, isSingleLot }) {
  return useQueryState({
    queryKey: [queryKeys.surveyKeys.singleLot({ surveyId, surveyFarmMapId })],
    queryFn: () => api.survey.getSingleLot({ surveyId, surveyFarmMapId }),
    enabled: !!surveyId && !!surveyFarmMapId && isSingleLot,
  });
}

// 회차별 필지 판독 [점유율] 저장
export function useAddFillRate() {
  return useMutateHook({
    fetchApi: ({ surveyFarmMapId, saveSurveyCropFillRates, workerName }) => api.survey.addFillRate({ surveyFarmMapId, saveSurveyCropFillRates, workerName }),
    //  invalidQuery: [[...queryKeys.surveyKeys.all, 'lots']],
    // 위에 있는 회차별 필지 조회 - 목록(paging) 재요청 ?
  });
}
// 회차별 필지 판독 [점유율] [일괄] 저장 - 미판독 필지들
export function useAddFillRateMulti() {
  return useMutateHook({
    fetchApi: ({ surveyFarmMapIds, surveyCropFillRateRequest }) => api.survey.addFillRateMulti({ surveyFarmMapIds, surveyCropFillRateRequest }),
  });
}
// 회차별 필지 판독 [점유율] [일괄] 저장 - 미판독 아닌 필지들
export function useAddFillRateMultiPrevData() {
  return useMutateHook({
    fetchApi: ({ surveyFarmMapIds, workerName }) => api.survey.addFillRateMulti_PrevData({ surveyFarmMapIds, workerName }),
  });
}

// 회차별 필지 판독 잔여량 수정 (점유율 저장이 최초 저장임, 이후는 수정)
export function useUpdateRemain() {
  return useMutateHook({
    fetchApi: ({ surveyFarmMapId, updateSurveyCropRemainRates, workerName }) => api.survey.updateRemain({ surveyFarmMapId, updateSurveyCropRemainRates, workerName }),
    // invalidQuery: [[queryKeys.projectKeys.detail(projectId)]],
  });
}

// 회차별 필지 판독 정보 조회
export function useGetSurveyData({ surveyFarmMapId }) {
  return useQueryState({
    queryKey: [queryKeys.surveyKeys.surveyData({ surveyFarmMapId })],
    queryFn: () => api.survey.getSurveyData({ surveyFarmMapId }),
    enabled: !!surveyFarmMapId,
  });
}

//회차별 이미지 업로드 현황조회
export function useUploadImages({ surveyId }) {
  return useQueryState({
    queryKey: [queryKeys.surveyKeys.uploadImageList(surveyId)],
    queryFn: () => api.survey.getUploadImages({ surveyId }),
    enabled: !!surveyId,
  });
}

// 회차별 중복 이미지 선택 등록(선택 외 나머지 삭제)
export function useDeleteDuplicateImage(surveyId) {
  return useMutateHook({
    fetchApi: ({ surveyId, surveyFarmMapId, wideImageId, zoomImageId }) => api.survey.deleteDuplicateImage({ surveyId, surveyFarmMapId, wideImageId, zoomImageId }),
    //  invalidQuery: [[queryKeys.surveyKeys.map(surveyId)]],
  });
}

//회차별 필지 검수 완료
export function useCompleteInspect() {
  return useMutateHook({
    fetchApi: (surveyFarmMapId) => api.survey.completeInspect(surveyFarmMapId),
  });
}

//회차별 차트
export function useGetChart(surveyId) {
  return useQueryState({
    queryFn: () => api.survey.getChart(surveyId),
    queryKey: [queryKeys.surveyKeys.chart(surveyId)],
  });
}

// 누락필지 shp 다운 (리 단위 선택)
export function useGetImageShp({ surveyId, stdgCds }) {
  return useQueryState({
    queryKey: [queryKeys.surveyKeys.imageShp(surveyId, stdgCds)],
    queryFn: () => api.survey.getImageShp({ surveyId, stdgCds }),
    //  enabled: !!surveyId && stdgCds?.length > 0,
    enabled: false,
  });
}

//회차별 법정동 주소 리스트
export function useGetStdg({ surveyId }) {
  return useQueryState({
    queryKey: [queryKeys.surveyKeys.getStdg(surveyId)],
    queryFn: () => api.survey.getStdg({ surveyId }),
    enabled: !!surveyId,
  });
}
