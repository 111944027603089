import Map from 'pages/Map';
import PopupLargeImage from 'popup/PopupLargeImage';
import React, { useEffect, useState } from 'react';
import { useRecoilState, useRecoilValue } from 'recoil';
import { popupLargeImgState, toastPopState } from 'store/Atoms';
import Navigation from '../Nav/Navigation';
import { Outlet } from 'react-router-dom';
import styled from 'styled-components';
import { confirmState, loadingState } from 'store/Atoms';
import Confirm from '../Confirm';
import Loading from 'components/atoms/Loading';
import ToastPop from '../ToastPop';
import { checkVersionUpdate } from '../../../util/version';
import IcoNewVersion from '../../../assets/images/popup/ico-new-version.svg';
import Button from '../../atoms/Button';
import Notice from '../Notice';
import axios from 'axios';

const ContainerSt = {
  Container: styled.div`
    width: 100%;
    height: 100vh;
    display: flex;
  `,
  NavWrap: styled.div`
    height: 100%;
  `,
  ContWrap: styled.div`
    flex: 1;
    width: 100%;
    height: 100%;
  `,
  NewVersion: styled.div`
    position: fixed;
    z-index: 12;
    right: 16px;
    top: 16px;
    padding: 16px;
    background: #ceefd4;
    border-radius: 8px;
    box-shadow: 0 2px 8px 0 rgba(0, 0, 0, 0.2);
    strong {
      display: flex;
      align-items: center;
      font-size: 16px;
      font-weight: 600;
      color: #001a21;
      margin-bottom: 6px;
      &:before {
        content: '';
        width: 20px;
        height: 20px;
        background: url(${IcoNewVersion}) no-repeat center;
        margin-right: 6px;
      }
    }
    p {
      display: flex;
      align-items: center;
      color: #001a21;
      font-size: 14px;
      font-weight: 400;
    }
  `,
};

function Container({ getUserRole, isDisabledToken }) {
  const [confirm, setConfirm] = useRecoilState(confirmState);
  const confirmInfo = useRecoilValue(confirmState);
  const loadingStatus = useRecoilValue(loadingState);
  const toastInfo = useRecoilValue(toastPopState);
  const [popLarge, setPopLarge] = useRecoilState(popupLargeImgState);
  const [isNewVersion, setIsNewVersion] = useState(false);

  useEffect(() => {
    if (isDisabledToken) {
      setConfirm({
        ...confirm,
        show: true,
        leftText: undefined,
        message: '토큰이 만료되어 로그아웃됩니다.',
        rightText: '확인',
        rightFunc: () => {
          sessionStorage.clear();
          window.location.href = '/';
        },
      });
    }
  }, [isDisabledToken]);

  useEffect(() => {
    // App 최초 실행 시 버전 체크
    checkVersionUpdate(setIsNewVersion);
    // 페이지가 재활성화 된 경우 버전 체크
    const handleVisibilityChange = () => {
      if (!document.hidden) {
        checkVersionUpdate(setIsNewVersion);
      }
    };
    document.addEventListener('visibilitychange', handleVisibilityChange);
    return () => {
      document.removeEventListener('visibilitychange', handleVisibilityChange);
    };
  }, []);

  console.log('role', getUserRole());

  return (
    <>
      {/*{isNoticeMode && localStorage.getItem('noRepeatNotice') !== 'true' && <Notice />}*/}
      <ContainerSt.Container>
        {/* <Map /> */}

        {/* 권한에 따라 navigation 바가 보여짐 */}
        {sessionStorage.getItem('auth') === 'ROLE_ADMIN' ? (
          <ContainerSt.NavWrap>
            <Navigation />
          </ContainerSt.NavWrap>
        ) : null}

        {/* 라우터 children 부분 */}
        <ContainerSt.ContWrap>
          <Outlet />
        </ContainerSt.ContWrap>

        {/* 로딩바 */}
        {loadingStatus.show ? <Loading /> : null}
        {/* 확인창 */}
        {confirmInfo.show ? <Confirm /> : null}
        {/* 토스트 팝업 - class로 감추기 */}
        {/* {toastInfo.show ? <ToastPop /> : null} */}
        <ToastPop />
        {/* 사진 크게보기 팝업 */}
        {popLarge.open && <PopupLargeImage close={() => setPopLarge({ open: false })} />}

        {/* TODO 신규 버전일때 보여줄 토스트 (강력 새로고침 기능)*/}
        {isNewVersion && (
          <ContainerSt.NewVersion>
            <strong>업데이트 정보</strong>
            <p>
              {/*업데이트를 통해 더 빨라졌습니다! 원활한 이용을 위해 새로고침이 필요합니다*/}
              새로운 버전이 배포되었습니다. 원활한 이용을 위해 새로고침이 필요합니다.
              <Button $buttonType={'small'} text={'새로고침(F5)'} radius={4} style={{ marginLeft: 10 }} onClick={() => window.location.reload()} />
            </p>
          </ContainerSt.NewVersion>
        )}
      </ContainerSt.Container>
    </>
  );
}

export default Container;
