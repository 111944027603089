/*
   새로운 api 파일 추가시, import-export 작업 필요
*/
import * as address from './address';
import * as image from './image';
import * as project from './project';
import * as client from './client';
import * as member from './member';
import * as farmMap from './farmMap';
import * as survey from './survey';

const api = {
  address,
  image,
  project,
  client,
  member,
  farmMap,
  survey,
};
export default api;
