import React from 'react';
import { NoticeStyle } from './Notice';
import styled from 'styled-components';
import Img from '../../assets/images/notice/img-maintenance.png';
import Logo from '../../assets/images/login/header-logo.svg';
import ImgEmergency from '../../assets/images/notice/img-emergency.png';
const UnderMaintenanceSt = {
  ...NoticeStyle,
  ContBox: styled.div`
    position: fixed;
    z-index: 100;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    width: 400px;
    border-radius: 8px;
    background: #fff;
    box-sizing: border-box;
    border: 2px solid #00b074;
  `,
  Inner: styled.div`
    padding: 0 30px 25px;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
  `,
  Title: styled.strong`
    display: block;
    color: #fff;
    font-size: 20px;
    font-weight: 600;
    height: 56px;
    line-height: 56px;
    padding: 0 32px;
    background: #00b074;
    border-radius: 0 0 8px 8px;
    margin-bottom: 33px;
  `,
  Bar: styled.div`
    width: 100%;
    height: 1px;
    margin: 20px 0;
    background: #00b074;
  `,
  Item: styled(NoticeStyle.Item)`
    strong {
      margin-right: 13px;
    }
  `,
  EmergencyContBox: styled.div`
    position: fixed;
    z-index: 100;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    width: 496px;
    border-radius: 8px;
    background: #eb5846;
    box-sizing: border-box;
    border: 2px solid #fff;
  `,
  EmergencyInner: styled.div`
    padding: 39px 24px 40px;
  `,
  Logo: styled.img`
    position: absolute;
    right: 27px;
    top: 22px;
    width: 76px;
  `,
  EmergencyTitle: styled.strong`
    display: block;
    font-size: 28px;
    color: #fff;
    font-weight: 600;
    text-align: center;
    margin-bottom: 20px;
  `,
  EmergencyCont: styled.div`
    background: #fff;
    border: 2px solid #d74331;
    border-radius: 12px;
    padding: 28px 30px 14px;
    margin-bottom: 16px;
    text-align: center;
  `,
  EmergencyDesc: styled.p`
    color: #001a21;
    font-size: 18px;
    font-weight: 500;
    line-height: 30px;
    word-break: keep-all;
    margin-top: 21px;
  `,
  EmergencyFoot: styled.p`
    text-align: center;
    font-size: 16px;
    font-weight: 500;
    line-height: 26px;
    color: #fff;
    span {
      color: #fff101;
    }
  `,
};
const UnderMaintenance = ({ isEmergency }) => {
  return (
    <>
      {/*<UnderMaintenanceSt.Dimmed></UnderMaintenanceSt.Dimmed>*/}

      {/*{isEmergency ? (*/}
      {/*  <UnderMaintenanceSt.EmergencyContBox>*/}
      {/*    <UnderMaintenanceSt.EmergencyInner>*/}
      {/*      <UnderMaintenanceSt.Logo src={Logo} alt={'FARM EZ'} />*/}
      {/*      <UnderMaintenanceSt.EmergencyTitle>긴급 공지사항</UnderMaintenanceSt.EmergencyTitle>*/}
      {/*      <UnderMaintenanceSt.EmergencyCont>*/}
      {/*        <img src={ImgEmergency} alt={'긴급 공지'} />*/}
      {/*        <UnderMaintenanceSt.EmergencyDesc>*/}
      {/*          현재 예상치 못한 장애가 발생하여 서비스 이용에 불편을 드리고 있습니다. 문제 해결을 위해 긴급 점검을 진행 중이며, 신속히 복구할 수 있도록 최선을 다하고 있습니다. 이용에 불편을 드려 죄송합니다.*/}
      {/*        </UnderMaintenanceSt.EmergencyDesc>*/}
      {/*      </UnderMaintenanceSt.EmergencyCont>*/}
      {/*      <UnderMaintenanceSt.EmergencyFoot>*/}
      {/*        긴급한 업무가 있을 경우 <span>[support@movements.kr]</span>로<br />*/}
      {/*        문의해 주시기 바랍니다.*/}
      {/*      </UnderMaintenanceSt.EmergencyFoot>*/}
      {/*    </UnderMaintenanceSt.EmergencyInner>*/}
      {/*  </UnderMaintenanceSt.EmergencyContBox>*/}
      {/*) : (*/}
      {/*  <UnderMaintenanceSt.ContBox>*/}
      {/*    <UnderMaintenanceSt.Inner>*/}
      {/*      <UnderMaintenanceSt.Title>서버 점검 안내</UnderMaintenanceSt.Title>*/}
      {/*      <img src={Img} alt={''} />*/}
      {/*      <UnderMaintenanceSt.Bar></UnderMaintenanceSt.Bar>*/}
      {/*      <UnderMaintenanceSt.Item>*/}
      {/*        <strong>점검시간</strong>*/}
      {/*        <p>2024.10.10(월) 13:00 ~ 15:00</p>*/}
      {/*      </UnderMaintenanceSt.Item>*/}
      {/*    </UnderMaintenanceSt.Inner>*/}
      {/*  </UnderMaintenanceSt.ContBox>*/}
      {/*)}*/}
    </>
  );
};

export default UnderMaintenance;
