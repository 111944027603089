import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import IcoImgDup from 'assets/images/map/ico-img-duplicate.svg';
import IcoImgUpload from 'assets/images/map/ico-img-upload.svg';
import PopupDuplicationPic from 'popup/PopupDuplicationPic';
import Button from 'components/atoms/Button';
import UserSetting from './UserSetting';
import { useUploadImages } from '../../../hooks/RQuery/utils/image';
import query from '../../../hooks/RQuery/utils';
import { useRecoilState, useRecoilValue } from 'recoil';
import { confirmState, loadingState, stdgCdState } from '../../../store/Atoms';
import { useQueryClient } from '@tanstack/react-query';
import PopupFailUpload from '../../../popup/PopupFailUpload';
import Confirm from '../Confirm';
import PopupResult from 'popup/PopupResult';
import getXmpMeta from 'util/getXmpMeta';
import { request, requestFormData } from '../../../service/farmEzCommon';
import axios from 'axios';

const TopBtnGroupStyle = {
  Wrap: styled.div`
    display: flex;
    gap: 8px;
    position: fixed;
    align-items: center;
    top: 16px;
    overflow: hidden;
    right: 72px;
    height: 48px;
    z-index: 12;
  `,
  IcoToggle: styled.button`
    display: block;
    width: 48px;
    margin-right: -8px;
    height: 48px;
    background: url(${IcoImgUpload}) no-repeat center;
    background-color: #00b074;
    border-radius: 4px;
    &.active {
      border-radius: 4px 0 0 4px;
    }
  `,
  ImgBtnWrap: styled.div`
    &.active {
      opacity: 1;
      width: 294px;
      visibility: visible;
      transition: 0.4s;
      button,
      label {
        opacity: 1;
        transition: 0.4s 0.4s;
      }
    }
    opacity: 0;
    visibility: hidden;
    width: 0;
    height: 100%;
    transition: 0.4s 0.4s;
    background-color: #00b074;

    padding: 0 2px 0 2px;
    border-radius: 0 4px 4px 0;
    display: flex;
    align-items: center;
    button,
    label {
      transition: 0.4s;
      opacity: 0;
      cursor: pointer;
      color: #fff;
      font-weight: 500;
      padding: 0 12px;
      &:not(:last-child) {
        border-right: 1px solid rgba(255, 255, 255, 0.5);
      }
      &:hover {
        transition: all 0.4s;
        color: #ffea00;
      }
    }
  `,
};
const UploadFileStyle = {
  Wrap: styled.div`
    position: relative;
    border-right: 1px solid rgba(255, 255, 255, 0.5);
  `,
  Input: styled.input`
    position: absolute;
    left: 0;
    top: 0;
    width: 0;
    height: 0;
    opacity: 0;
    visibility: hidden;
  `,
  Label: styled.label`
    display: flex;
    align-items: center;
    justify-content: center;
    font-weight: 500;
    box-sizing: border-box;
    border: 1px solid transparent;
    color: #fff;
    width: 148px;
    height: 48px;
    padding: 0 10px;
    font-size: 16px;
    border-radius: 4px;
    background: #00b074;
    cursor: pointer;
    box-shadow: 0 0 6px 0 rgba(0, 0, 0, 0.2);
    &:before {
      content: '';
      width: 24px;
      height: 24px;
      background: url(${IcoImgUpload}) no-repeat center;
      margin-right: 6px;
    }
    &:hover {
      background: #008d5d;
    }
    &:active {
      background: #0e7d5f;
    }
  `,
};
function TopBtnGroup({ round, onChangeFeatureStatus }) {
  const [loading, setLoading] = useRecoilState(loadingState);
  const [confirm, setConfirm] = useRecoilState(confirmState);
  const [openDuplicate, setOpenDuplicate] = useState(false); // 중복 확인 팝업
  const [openResult, setOpenResult] = useState(false); // 결과 확인 팝업
  const [fileList, setFileList] = useState<File[]>([]);
  const [filesArray, setFilesArray] = useState<File[][]>([]);
  const [totalCount, setTotalCount] = useState(0);
  const [curIdx, setCurIdx] = useState(0);
  const [errorList, setErrorList] = useState<any>([]);
  const [isOpen, setIsOpen] = useState(false);
  const surveyId = round.find(({ active }) => active)?.surveyId;
  const uploadImgFarm = query.image.useUploadImagesFarmmap(); // surveyId, files
  const stdgCdInfo = useRecoilValue(stdgCdState);

  //팝업
  const [popupState, setPopupState] = useState({
    errorList: false,
  });

  const { data: farmMapData, refetch: refetchFarmMapData } = query.survey.useMapData({
    surveyId: round.filter(({ active }) => active)[0]?.value,
    stdgCd: stdgCdInfo?.code,
    //  lastPnu: param,
    //  limit: 1000,
  });

  //팝업 토글
  const onTogglePopup = (key: string) => {
    const newState = { ...popupState };
    newState[key] = !newState[key];
    setPopupState(newState);
  };

  //사진 업로드(동기)
  const onChange = (e) => {
    setCurIdx(0);
    const files: File[] = [...e.target.files];
    const fileGroup: File[][] = [];
    setTotalCount(files.length);
    setConfirm({
      ...confirm,
      show: true,
      leftText: '취소',
      message: `${files.length}개의 사진을 업로드 하시겠습니까?`,
      leftFunc: () => setConfirm({ ...confirm, show: false }),
      rightText: '확인',
      rightFunc: async () => {
        //토큰리셋
        await request.post('/auth/access-token', { accessToken: sessionStorage.getItem('token') }).then((response) => {
          sessionStorage.setItem('token', response.data.datas);
        });

        requestFormData.interceptors.request.use((config) => {
          config.headers['Authorization'] = sessionStorage.getItem('token');
          return config;
        });
        request.interceptors.request.use((config) => {
          config.headers['Authorization'] = sessionStorage.getItem('token');
          return config;
        });

        setConfirm({ ...confirm, show: false });

        files.map((file, index) => {
          if (index % 10 === 0) {
            fileGroup.push([file]);
          } else {
            fileGroup[fileGroup.length - 1].push(file);
          }
        });
        setFilesArray(fileGroup);
        setFileList(fileGroup[0]);
        e.target.value = null;
      },
    });

    //  setLoading({ show: true });
  };

  //사진 업로드 동기처리
  useEffect(() => {
    const startTime = new Date().getTime();
    if (fileList.length) {
      // getXmpMeta(fileList); // TEST: XmpMeta
      const errList: any = [...errorList];
      const loop = async () => {
        try {
          setLoading({
            show: true,
            text: '정보 양이 많을 경우 지연될수 있습니다.',
            progress: (
              <>
                ({curIdx * 10}/{totalCount})
              </>
            ),
          });
          await uploadImgFarm.mutateAsync(
            { surveyId, files: fileList },
            {
              onSuccess: (res: any) => {
                const term = new Date().getTime() - startTime;
                console.log(term / 1000 + '초');
                setLoading({ show: false });

                //실패한 파일명 에러리스트에 추가
                res.datas.map((i) => {
                  errList.push(i);
                });
                setErrorList(errList); // 실패한 리스트 계속 추가

                //다음파일 호출
                setCurIdx(curIdx + 1);

                if (filesArray.length > curIdx + 1) {
                  setFileList(filesArray[curIdx + 1]);
                } else {
                  setLoading({ show: false });
                  //지도 리로드
                  if (!!stdgCdInfo.code) {
                    refetchFarmMapData();
                  }
                  //실패 항목이 있을때 실패 팝업
                  if (errList.length) {
                    setPopupState({
                      ...popupState,
                      errorList: true,
                    });
                  } else {
                    //실패가 없으면 얼럿창
                    setConfirm({
                      ...confirm,
                      show: true,
                      message: '사진 업로드가 완료되었습니다.',
                      rightFunc: () => setConfirm({ ...confirm, show: false }),
                      rightText: '닫기',
                    });
                    setFilesArray([]);
                    setFileList([]);
                  }
                }
              },
              // onError: (err) => {
              //   //다음파일 호출
              //   setCurIdx(curIdx + 1);
              //   if (filesArray.length > curIdx + 1) {
              //     setFileList(filesArray[curIdx + 1]);
              //   }
              // },
            },
          );
        } catch (error) {
          //다음파일 호출
          // setCurIdx(curIdx + 1);

          const term = new Date().getTime() - startTime;
          console.log(term / 1000 + '초');
          setLoading({ show: false });

          //실패한 파일명 에러리스트에 추가
          fileList.map((i) => {
            errList.push({ fileName: i.name, message: '네트워크 오류', type: 'NETWORK_ERROR' });
          });
          setErrorList(errList); // 실패한 리스트 계속 추가

          //다음파일 호출
          setCurIdx(curIdx + 1);

          if (filesArray.length > curIdx + 1) {
            setFileList(filesArray[curIdx + 1]);
          } else {
            setLoading({ show: false });
            //지도 리로드
            if (!!stdgCdInfo.code) {
              refetchFarmMapData();
            }
            //실패 항목이 있을때 실패 팝업
            if (errList.length) {
              setPopupState({
                ...popupState,
                errorList: true,
              });
            } else {
              //실패가 없으면 얼럿창
              setConfirm({
                ...confirm,
                show: true,
                message: '사진 업로드가 완료되었습니다.',
                rightFunc: () => setConfirm({ ...confirm, show: false }),
                rightText: '닫기',
              });
              setFilesArray([]);
              setFileList([]);
            }
          }

          console.error('Error uploading files:', error);
        }
      };
      loop();
    }
  }, [fileList]);

  return (
    <>
      <TopBtnGroupStyle.Wrap>
        {sessionStorage.getItem('auth') === 'ROLE_WORKER' ? (
          <>
            <TopBtnGroupStyle.IcoToggle
              className={isOpen ? 'active' : undefined}
              type={'button'}
              onClick={() => {
                setIsOpen(!isOpen);
              }}
            >
              <span className={'hidden'}>토글</span>
            </TopBtnGroupStyle.IcoToggle>
            <TopBtnGroupStyle.ImgBtnWrap className={isOpen ? 'active' : undefined}>
              <UploadFileStyle.Wrap>
                <UploadFileStyle.Input id={'fileUpload'} type={'file'} multiple={true} onChange={onChange} accept={'image/jpeg'} />
                <label htmlFor={'fileUpload'}>사진 업로드</label>
              </UploadFileStyle.Wrap>
              <button onClick={() => setOpenDuplicate(true)}>사진 중복확인</button>
              <button onClick={() => setOpenResult(true)}>사진 결과 확인</button>
            </TopBtnGroupStyle.ImgBtnWrap>

            {/* 사진 중복확인 팝업 */}
            {openDuplicate && <PopupDuplicationPic onClose={() => setOpenDuplicate(false)} round={round} onChangeFeatureStatus={onChangeFeatureStatus} />}

            {/* 사진 결과 확인 팝업 */}
            {openResult && <PopupResult onClose={() => setOpenResult(false)} round={round} />}
          </>
        ) : null}
      </TopBtnGroupStyle.Wrap>

      {/* 사진 업로드 에러 리스트 팝업 */}
      {popupState.errorList ? <PopupFailUpload errorList={errorList} onClose={() => onTogglePopup('errorList')} failType={'pic'} setErrorList={setErrorList} /> : null}
      {confirm.show ? <Confirm /> : null}

      {/* 유저정보 버튼 - 로그아웃 등 */}
      {sessionStorage.getItem('auth') !== 'ROLE_ADMIN' ? <UserSetting /> : null}
    </>
  );
}

export default TopBtnGroup;
