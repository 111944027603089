import axios from 'axios';

export const EMapKey = {
  development: {
    key: '0277D65D574BC25B018BD7DF0786421B',
    url: 'http://localhost:3030',
  },
};

export const vworldKey = {
  development: {
    key: 'C5FECDF0-854B-3CC7-A712-BCD273B673DF', // 만료일 4/2
    url: 'http://localhost:3030',
  },
  dev: {
    key: 'A7A1B592-547F-3ED5-AC4E-E3428FEF35E1', // 만료일 4/30
    url: 'http://farmez-dev.move-map.kr', // 개발 서버
  },
  origin: {
    key: 'EF38E598-7319-3217-A349-29045D91546F', // 만료일 4/30
    url: 'http://farmez.move-map.kr', // 운영 서버
  },
};
// export const domainUrl = isDevelop ? 'http://localhost:3030' : 'http://move-map.kr';
// export const myKey = isDevelop ? 'C5FECDF0-854B-3CC7-A712-BCD273B673DF' : 'E1FB3D79-62B7-382C-B4B5-4EB8D940860F'; // ynk, PORT=3030
// export const domainUrl = 'http://m-map.kr';
// export const myKey = 'BF7996E6-41F5-3B51-9A94-3F2C692B2DC7'; // m-map

const deployKey = 'origin'; // dev, origin (FIXME: 배포할때마다 수동 변경!)
const isDevelop = process.env.NODE_ENV === 'development';
export const domainUrl = isDevelop ? vworldKey['development'].url : vworldKey[deployKey].url;
export const myKey = isDevelop ? vworldKey['development'].key : vworldKey[deployKey].key;

// '&' join 형태
export const urlBuilder = (host: string, query = {}) => {
  const param = Object.entries(query)
    .map(([key, value]: any) => (value ? `${key}=${encodeURIComponent(value)}` : ''))
    .join('&');
  return `${host}?${param}`;
};

export const paramsBuilder = (typenames: string[], srs: string, bbox?: any, startindex?: number) => {
  return {
    //  VERSION: '1.1.0',
    VERSION: '2.0.0',
    SERVICE: 'WFS',
    REQUEST: 'GetFeature',
    TYPENAME: typenames?.toString(), // lt_c_uq111,
    BBOX: bbox?.toString(),
    SRSNAME: srs, // EPSG:900913
    OUTPUT: 'application/json',
    EXCEPTIONS: 'application/json',
    KEY: myKey,
    DOMAIN: domainUrl,
    FILTER: '',
    STARTINDEX: startindex,
    //  PROPERTYNAME: 'mnum,sido_cd,sigungu_cd,dyear,dnum,ucode,bon_bun,bu_bun,uname,sido_name,sigg_name,ag_geom',
    //  FORMAT: 'image/png',
    //  MAXFEATURES: 1000,
    //  OUTPUT: 'GML2',
    //  EXCEPTIONS: 'text/xml',
  };
};

/* --------------------------- */
