import { useQuery } from '@tanstack/react-query';
import queryKeys from '../keys/queryKeys';
import api from '../service';
import useMutateHook from 'hooks/useMutateHook';
import { useQueryState } from 'hooks/useQueryState';

// 유저의 사업 리스트 조회
export function useGetProjects(memberId) {
  return useQuery({
    queryKey: [queryKeys.memberKeys.projects(memberId)],
    queryFn: () => api.member.getProjects(memberId),
    meta: {
      errorMessage: '사업 리스트 조회 실패',
    },
  });
}

// 유저의 비밀번호 변경
export function useMutateChangePassword({ memberId, newPassword }) {
  return useMutateHook({ fetchApi: () => api.member.patchChangePassword({ memberId, newPassword }) });
}
//이메일 중복체크
export function useDuplicateEmail(email) {
  return useQuery({
    queryKey: [queryKeys.memberKeys.duplicate],
    queryFn: () => api.member.getDuplicateEmail(email),
    enabled: false,
    staleTime: 0,
  });
}

//판독자/검수자 추가(isWorker:true => 판독자)
export function useAddMember({ email, name, companyId, inspectorCompanyId, password }) {
  return useMutateHook({
    fetchApi: () => api.member.addMember({ email, name, companyId, inspectorCompanyId, password }),
    invalidQuery: [[queryKeys.memberKeys.all]],
  });
}

//판독자/검수자 수정
export function useModifyMember({ email, name, companyId, password, memberId }) {
  return useMutateHook({
    fetchApi: () => api.member.modifyMember({ email, name, companyId, password, memberId }),
    invalidQuery: [[queryKeys.memberKeys.all]],
  });
}

//판독자/검수자 삭제
export function useRemoveMember({ memberIds }) {
  return useMutateHook({
    fetchApi: () => api.member.removeMember({ memberIds }),
    invalidQuery: [[queryKeys.memberKeys.all]],
  });
}

//판독자/검수자 목록
export function useGetMembers({ type, companyId, inspectorCompanyId, condition, pageNum, size }) {
  return useQuery({
    queryKey: [queryKeys.memberKeys.list({ pageNum, size, type })],
    queryFn: () => api.member.getMembers({ type, companyId, inspectorCompanyId, condition, pageNum, size }),
    gcTime: 0,
  });
}
